@import 'variables';

.TroughButtons{
	display: flex;
	flex-direction: column;
	align-items: center;
	.TextButton{
		color: $grey;
		font-weight: 600;
		font-size: 2.2vmin;
	}
	.SecondaryButton{
		color: white;
	}
}
.TroughButtons.fixed-buttons {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 3vh 0;
  height: 18vh;
  left: 0;
  background-color: #272A2A;
  box-sizing: border-box;
  &-cta {
    margin: 0;
    font-weight: 700;
    font-family: 'Gotham Bold', sans-serif;
  }
  .TextButton {
    margin: 0;
    color: #AFAFAF;
    font-family: 'Gotham Book', sans-serif;
    font-weight: 400;
  }
}

@media only screen and (max-width:600px) {
  .TroughButtons.fixed-buttons {
    height: 20vh;
    padding: 3.4vh 5.6vh;
    .TroughButtons-cta {
      width: 100%;
      font-size: 2.2vh;
      margin: 0 0 1.2vh 0;
    }
    .TextButton {
      font-size: 2.4vh;
      padding: 2.2vh;
    }
  }
}