
.DetailCategory {
	margin-bottom: 2vh;
	/*color: #444;*/
}

.DetailCategory .DetailCategory-header{
	margin: 1vh 0;
	width: 100%;
	text-align: center;
	text-transform: uppercase;
	font-weight: 800;
}

.DetailCategory .Detail {
	width: 100%;
	font-size: 2.4vh;
	display: flex;
	justify-content: space-between;
}

.DetailCategory .Detail-name {
	margin: 2vh 4vh;
}

.DetailCategory .Detail-value {
	font-weight: 600;
	margin: 2vh 5vh;
}
