@import 'variables';

.InvestmentItem {
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	padding: 2.6vmin 0;
	font-size: 2vmin;
	border-bottom: 1px solid #eaeaea;

	&.InvestmentItem-goToAsset {
		color: inherit
	}

	&--exited {
		color: inherit;
		position: relative;
		& .InvestmentItem-content {
			position: relative;
	    	align-self: flex-start;
			z-index: 2;
		}
		& .InvestmentItem-amount {
			color: inherit;
			text-transform: uppercase;
		}
		&:after {
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			background-image: linear-gradient(45deg, rgba(#ffffff, .4)
				25%, rgba(#c7c7c7, .4)
				25%, rgba(#c7c7c7, .4)
				50%, rgba(#ffffff, .4)
				50%, rgba(#ffffff, .4)
				75%, rgba(#c7c7c7, .4)
				75%, rgba(#c7c7c7, .4)
				100%
			);
			// background-size: 8.49px 8.49px;
			background-size: 6px 6px;
			content: '';
		}
	}
}

.InvestmentItem-image {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: 0 2.4vmin;
	width: 23vmin;
	box-sizing: border-box;
	flex-shrink: 0;
	img {
		max-width: 100%;
	}
}

.InvestmentItem-content {
	line-height: 1.3;
	div {
		margin-bottom: 4px;
		&:last-child { margin-bottom: 0; }
	}
}

.InvestmentItem-header {
	font-weight: 900;
}

.InvestmentItem-amount {
	color: $money-green;
	font-weight: 900;
	margin-bottom: 1vmin;
}

.InvestmentItem-gain {
	font-weight: 900;
	color: $money-green;
}

.InvestmentItem-loss {
	font-weight: 900;
	color: $rally-red;
}

.InvestmentItem-nogainloss {
	font-weight: 900;
}

.InvestmentItem-trading-window {
	font-weight: 900;
}

@media only screen and (max-width:600px) {
	.InvestmentItem {
		padding: 4.6vmin 0;
		font-size: 3.3vmin;
	}
	.InvestmentItem-content {
		line-height: 1.4;
	}
	.InvestmentItem-image {
		padding: 0 2vmin;
		width: 30vmin;
	}
}
