
@import 'variables';
@import 'mixins';

$label-size: 2.4vw;
$icon-size: 4.8vw;

$transition-duration: 400ms;
$transition-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);

.AssetsMobileFooter {
	position: fixed;
	display: none;
	align-items: center;
	justify-content: center;
	bottom: 0;
	width: 100%;
	height: $asset-mobile-footer-height !important;
	z-index: 11;
	background-color: #fff;

	&.hidden {
		visibility: hidden;
		pointer-events: none;
	}


	&::before {
	  position: absolute;
	  display: block;
	  top: 0;
	  left: 0;
	  right: 0;
	  height: 2px;
	  background-image: linear-gradient(90deg, #a7a6a8 33%, #d8ecfa 33%, #d8ecfa 66%, #e7a88a 66%, #e7a88a 100%);
	  content: '';
	}

	@media only screen and (max-width:600px) {
		display: flex;
	}
}

.AssetsMobileFooter-indicator {
    position: absolute;
    top: 10%;
    left: 6.75px;
    border-radius: 50px;
    background-color: orange;
}

.AssetsMobileFooter-list-toggler {
	display: flex;
	justify-content: center;
	width: 14vw;
	position: relative;
	height: 100%;

	&:after {
		content: '';
		position: absolute;
	    width: 1px;
	    height: 75%;
	    right: 0;
	    top: 12.5%;
		background-color: #ccc;
	}

	.AssetsMobileFooter-nav-btn {
		padding: 0;
		margin: 10px 0;
		height: calc(100% - 20px);
		position: relative;
	}
}

.AssetsMobileFooter-nav-list {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 84vw;
	margin: 0;
	padding: 0 1vw 0 2vw;
	list-style: none;
}

.AssetsMobileFooter-nav-item {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 25%;
	transition: color .2s;
	&.active {
		color: #fff;
	}

	/* Small position and sizing tweaks for specific items:  */
	&.cars .AssetsMobileFooter-nav-icon { width: 6.4vw; }
	&.watches .AssetsMobileFooter-nav-icon { width: 5.1vw; }
}

.AssetsMobileFooter-nav-btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 6px 0;
	color: inherit;
	background-color: transparent;
	border: 0;
	outline: none;

	.Button-modeIcon-carousel,
	.Button-modeIcon-listview {
		position: absolute;
		width: 11vmin;
		height: 11vmin;
		background-image: url('../../../../../images/assets/Carousel_Icon_SVG_Active.svg');
		background-size: cover;
		transition: opacity .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
		opacity: 0;
	}

	.Button-modeIcon-listview {
		background-image: url('../../../../../images/assets/List_Icon_SVG_Active.svg');
		opacity: 1;
		animation-delay: .2s;
	}

	&.listView {
		.Button-modeIcon-carousel {
			opacity: 1;
			animation-delay: .2s;
		}

		.Button-modeIcon-listview {
			opacity: 0;
		}
	}

	&.no-padding {
		padding: 0;
	}
	
	&.notification::before {
		@include dot-indicator($animate-enter: false);
		position: absolute;
		top: 50%;
		left: 50%;
		width: 10px;
		height: 10px;
		margin-top: -12px;
		margin-left: 2px;
		z-index: 1;
	}
}

.AssetsMobileFooter-nav-icon {
	position: relative;
	width: $icon-size;
	max-height: 7.5vw;
	height: auto;
	transition: transform .2s ease-in;
}

.AssetsMobileFooter-nav-label {
	display: inline-block;
	position: absolute;
	opacity: 0;
	width: 50px;
	font-size: $label-size;
	font-weight: 600;
	text-transform: uppercase;
	text-align: left;
	transition: transform .2s ease-in, opacity .2s ease-in;
}

.AssetsMobileFooter-nav-item-highlight {
  position: absolute;
  top: 10%;
  bottom: 10%;
  left: 0;
  right: 0;
  background-color: orange;
  border-radius: 50px;
  z-index: -1;
  transition: all $transition-duration $transition-function;
}
