.Gallery {
  position: relative;
	width: 68%;
	height: 84.2vh;
	display: inline-flex;
	flex-wrap: wrap;
	overflow-y: scroll;

	.Gallery-images, .Gallery-contents {
		width: 100%;
	}

	.Gallery-images.fullWidth {
		.Gallery-image {
			width: 100%;
		}
	}

	.Gallery-image {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		vertical-align: bottom;
		width: 50%;
		height: 34vh;
		cursor: pointer;
	}
	
	.Gallery-image img {
		display: block;
		width: 100%;
		height: 34vh;
		object-fit: cover;
	}

	.Gallery-video {
		display: flex;
		position: relative;
		width: 100%;
		height: 30vh;
		cursor: pointer;
		background: #000;
		align-items: center;
		justify-content: center;
		overflow: hidden;

		.VideoPlayIcon {
			color: white;
			position: absolute;
			right: 0;
			bottom: 0;
			margin: 8%;

			svg {
				width: 10vmin;
				height: 7vmin;
			}
		}
	}
}
.Gallery.special-access {
	height: 100%;
	width: 100%;
}

@media only screen and (max-width:750px) {
	.Gallery { 
		// height: 95vh;
		height: 90vh;
		width: 100%;
	}
}

@media only screen and (max-width:600px) {
	.Gallery {
		height: 95vh;
	}
}
