
@import 'variables';
@import 'placeholders';

$light-grey: #949494;

.SelectBidAmount {
	.SelectBidAmount-name{
		font-family: 'Bebasneue', sans-serif;
	}
	.SelectBidAmount-ticker {
    color: $light-grey;
    font-family: 'Gotham Bold', sans-serif;
    font-size: 4.2vh;
    line-height: 1;
    font-weight: 700;
    letter-spacing: -1px;
	}
	.SelectBidAmount-name {
		font-size: 6vh;
    line-height: 1;
		color: white;
		white-space: nowrap;
    font-weight: 400;
    
	}
	.SelectBidAmount-lastclose{
		text-transform: uppercase;
    font-family: 'Gotham Book', sans-serif;
    font-size: 1.6vh;
		.SelectBidAmount-lastclose-price{
      margin-left: 2vh;
			.SelectBidAmount-lastclose-price-pershare{
				color: $new-green;
			}
			.SelectBidAmount-lastclose-price-total{
				color: white;
			}
		}
	}
  .PlaceBid-fields.pb-select-shares {
    box-sizing: border-box;
    width: 55vmin;
    min-width: 280px;
    justify-content: space-around;
    margin: 0 auto;
    &.error {
      .NumberInput {
        border: 1px solid #D65151;
      }
    }
    .PlaceBid-field {
      height: auto;
      width: 100%;
      .place-title {
        margin-bottom: 2vh;
        font-size: 1.6vh;
        color: #C4C4C4;
      }
      .PlaceBid-field-content {
        width: 20vmin;
        height: 4.8vh;
        margin: 0 auto;
      }
    }
    .SelectBidAmount-cross {
      width: 1.2vh;
      height: 4.8vh;
      align-self: flex-end;
      margin-top: 0;
    }
  }
	.SelectBidAmount-divider{
		@extend %flex-center;
		position: relative;
		height: 12vh;
		width: 100%;
		.SelectBidAmount-divider-line{
			position: absolute;
			background-color: $placebid-lines;
			display: block;
			height: 1px;
			width: 100%;
		}
		.SelectBidAmount-divider-title{
			position: absolute;
			color: $new-green;
			font-weight: 800;
			text-transform: uppercase;
      font-family: 'Gotham Bold', sans-serif;
			font-size: 2.6vh;
			background-color: $placebid-background;
			padding: 0 3vh;
			.SelectBidAmount-red{
				color: $rally-red;
			}
		}
	}
	.PlaceBid-fields.error .PlaceBid-field .PlaceBid-field-content input {
		border-color: $rally-red;
	}
	.SelectBidAmount-cross{
		@extend %flex-center;
		width: 8vmin;
		height: 6vmin;
		margin-top: 6vmin;
		.Cross svg{
			width: 1.6vmin;
		}
	}
	.SelectBidAmount-estimatedcost{
		margin: 3vh 0 1.4vh 0;
		.PlaceBid-field{
			height: auto;
      width: 100%;
			.PlaceBid-field-label{
				color: #AFAFAF;
        font-family: 'Gotham Medium', sans-serif;
        font-weight: 500;
        font-size: 1.5vh;
        margin-bottom: .5vh;
			}
			.PlaceBid-field-content{
        color: $rally-orange;
        font-family: 'Gotham Book', sans-serif;
				font-weight: 400;
			}
		}
	}
	.SelectBidAmount-error {
		margin-bottom: 2vmin;
		font-size: 1.7vmin;
		color: $rally-red;
		a {
			color: white;
			text-decoration: underline;
		}
	}
  .instant-buy {
    margin-bottom: 6.6vh;
    width: 43%;
    max-width: 100%;
    min-width: 280px;
    margin: 1vh auto 5vh;
    &-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &:first-of-type {
        margin-bottom: 5.5vh;
      }
    }
    &-text {
      text-align: center;
    }
    &-lowest {
      color: $new-green;
      font-size: 1.3vh;
      font-family: 'Gotham Book', sans-serif;
    }
    &-text, &-value {
      width: 50%;
      font-family: 'Gotham Bold', sans-serif;
      font-size: 2.6vh;
      padding: 0.06vh 3.4vh;
    }
  }
}

@media only screen and (max-width:600px) {
	.SelectBidAmount {
		.SelectBidAmount-ticker {
			font-size: 4vh;
			line-height: 4.8vh;
      margin-top: 2.2vh;
      letter-spacing: 0;
		}
		.SelectBidAmount-name {
			font-size: 5.4vh;
			line-height: 6.2vh;
      overflow: hidden;
      text-overflow: ellipsis;
		}
		.SelectBidAmount-lastclose{
			margin-top: 1vmin;
      font-size: 1.6vh;
			.SelectBidAmount-lastclose-price{
				padding: 0.5vmin 2vmin;
				border-radius: 3.4vmin;
			}
		}
		.SelectBidAmount-divider{
			height: 11vh;
			.SelectBidAmount-divider-title{
				font-size: 1.7vh;
				padding: 0 1.4vh;
			}
		}
		.SelectBidAmount-cross{
			width: 12vmin;
			height: 10vmin;
			margin-top: 10vmin;
			.Cross svg{
				width: 2.4vmin;
			}
		}
    .instant-buy {
      width: 350px;
      margin: 1vh auto 8vh;
    }
    .PlaceBid-fields.pb-select-shares {
      width: 350px;
      max-width: 100%;
      .PlaceBid-field .PlaceBid-field-content {
        width: 80%;
        margin: 0 10%;
      }
    }
    .PlaceBid-field .PlaceBid-field-label.place-title {
      font-size: 1.7vh;
    }
		.SelectBidAmount-estimatedcost{
			margin: 3.5vh 0 1vh;
			.PlaceBid-field{
				width: 100%;
        .PlaceBid-field-label {
          margin-bottom: 1.3vh;
        }
        .PlaceBid-field-label, .PlaceBid-field-content {
          font-size: 2vh;
        }
			}
		}
		.SelectBidAmount-error {
			font-size: 1.5vh;
		}
	}
  
}



