.Mainpage{
	display: flex;
	align-items: center;
	flex-direction: column;
	min-height: 100%;
	overflow-y: hidden;
}
.Mainpage.special-access {
	min-height: 100vh;
	flex-direction: column;
}

.Mainpage-body{
	width: 100%;
	display: flex;
	flex-direction: row;
}
.Mainpage-body.special-access {
	flex: 1;
	justify-content: center;
}

.Mainpage-body-content{
  	position: relative;
	width: 78%;
	min-height: 100%;
	border-left: 1px solid #ddd;
	box-sizing: border-box;
}
.Mainpage-body-content.special-access {
	border: 0;
	display: flex;
	flex-direction: column;
	width: 100%;
}

.Mainpage-body-content > div {
	height: 100%;
}

@media only screen and (max-width:1000px) {
	.Mainpage-body-content{
		width: 94vw;
	}
}

@media only screen and (max-width:600px) {
	.Mainpage-body-content{
		width: 100vw;
		border-left: none;
	}
	.Mainpage-body{
		position: fixed;
		height: 93%;
		top: 7%;
		overflow: auto;
	}
}
