.sapopup {
  &__container {
    display: flex;
    flex-direction: column;

    & > div.sapopup__wrapper {
      padding: 0px;
      width: 60%;
      max-width: 680px;
      max-height: 100%;
    }
  }

  &__wrapper {
    padding: 20px 40px 0px;
  }

  &__h2 {
    color: #41c883;
    font-weight: 900;
    font-size: 1.4rem;
    margin: 0;
  }

  &__h1 {
    font-weight: 500;
    font-family: 'Bebasneue', sans-serif;
    font-size: 3.0rem;
    margin-top: 6px;
    margin-bottom: 0;
    line-height: 0.9;
  }

  &__h5 {
    color: #444;
    font-size: 1.1rem;
    margin-top: 0;
    margin-bottom: 4px;
    font-weight: 400;

    &--gray {
      color: #bbb;
      margin: 0 8px;
    }
  }

  &__p {
    line-height: 1.4;
    font-size: 1.2rem;
    color: #666666;
  }

  &__img {
    max-height: 34vh;
    margin: 0 auto;

    &-container {
      padding: 6px 0;
    }
  }

  &__btn {
    width: 100%;
    margin: 0;
    border-radius: 0px;
    font-size: 1.4rem;
    font-weight: 900;

    &:hover {
      opacity: .9;
    }

    &-wrapper {
      width: 100%;
      padding-top: 12px;
    }

    &--cancel {
      color: #cdcdcd;
      padding: 0;
      margin-top: 2rem;
      font-size: 1.2rem;
      font-weight: 500;

      &:hover {
        text-decoration: underline;
        color: white;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .sapopup {
    &__img {
      max-height: 20vh;
    }

    &__h1 {
      font-size: 2.4rem;
    }
  
    &__wrapper {
      padding: 20px 24px 0px;
    }

    &__p {
      line-height: 20px;
      font-size: .8rem;
    }

    &__btn {
      font-size: 1.1rem;
    }
  }
}