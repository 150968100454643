@import 'variables';

.OrderItem {
  display: flex;
  justify-content: space-between;
  font-size: 2vmin;
  border-bottom: 1px solid #eaeaea;

  & .OrderItem-goToAsset {
    color: inherit;
    width: 100%;
  }
}

.OrderItem.OrderItem--canceled {
  display: none;
}

.OrderItem-content {
  line-height: 1.3;
  padding: 2.6vmin 0 2.6vmin 2.6vmin;
}

.OrderItem-header {
  font-weight: bold;
}

.OrderItem-bid {
  font-weight: bold;
  color: $money-green;
}

.OrderItem-ask {
  font-weight: bold;
  color: $rally-red;
}

.OrderItem-btn {
  display: flex;
  & .pendingCancel {
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-weight: bold;
    font-size: 1.4vmin;
    text-transform: uppercase;
  }
  margin-right: 2.6vmin;
}

.OrderItem-btn button {
  margin: 0;
  font-size: 1.4vmin;
  color: $rally-red;
  text-transform: uppercase;
  text-decoration: underline;
}

.OrderItem-btn .OrderItem-edit-btn {
  color: #000;
}

.OrderItem-btn .OrderItem-confirm-btn {
  margin-right: 0;
  padding-right: 0;
  color: #0d5fb1;
}

.OrderItem .Error {
  color: inherit;
}

.OrderItem .CancelOrderPopup,
.OrderItem .OrderItem-error-popup {
  position: fixed;
  .Popup-contents {
    max-width: 700px;
    justify-content: space-around;
  }
}

@media only screen and (max-width: 600px) {
  .OrderItem {
    padding: 4.6vmin;
    font-size: 3.2vmin;
  }
  .OrderItem-content {
    line-height: 1.4;
  }
  .OrderItem-btn {
    justify-self: end;
  }
  .OrderItem-btn button {
    padding: 3vmin 1vmin;
    font-size: 2.8vmin;
  }
}
