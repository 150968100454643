

.DwollaStatusPopup .DwollaStatusPopup-title {
	margin: 8% 6% 2% 6%;
}

.DwollaStatusPopup .DwollaStatusPopup-body {
	margin: 2% 6%;
}

.DwollaStatusPopup .DwollaStatusPopup-button {
	margin: 2% 6% 8% 6%;
}
