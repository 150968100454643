.Docusign {
	position: absolute;
	width: 100%;
	height: calc(100% - 9.1vmin);
	top: 9vmin;
	right: 0;
}

.Docusign iframe {
	display: block;
	width: 100%;
	height: 100%;
} 

.PreparingDocusign {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
}

/* move these to a spinner w background component */
/* because we seem to be reusing */
.PreparingDocusign .PreparingDocusign-spinner{
	width: 14vmin;
	height: 14vmin;
	margin: 6.6vh auto 2vh auto;
	background-color: #e6e6e6;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 2vmin;
}
.PreparingDocusign .PreparingDocusign-spinner .Spinner{
	width: 9vmin;
	height: 9vmin;
}
.PreparingDocusign .PreparingDocusign-spinner .Spinner:after {
	background: #e6e6e6;
	width: 92%;
	height: 92%;
}

@media only screen and (max-width:600px) {
	.Docusign {
		height: calc(100% - 18.1vmin);
		top: 18vmin;
	}

	.MakeInvestment.special-access .Docusign {
		height: calc(100% - 29.1vmin);
	}
}