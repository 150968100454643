.USStatesSelect {
	position: relative;
	border-bottom: 0.18vmin solid #777;
	margin-bottom: 2vh;
	width: 50vmin;
	&.USStatesSelect-invalid {
		border-color: #dd2200;
	}

	.USStatesSelect-select {
		background-color: rgba(0,0,0,0);
		border: none;
		-moz-appearance: none;
		-webkit-appearance: none;
		padding: 0.2vmin 1vmin;
		margin: 0 auto;
		text-align: -webkit-center;
		text-align-last: center;
		opacity: 0;
	}

	.USStatesSelect-select, .USStatesSelect-value {
		text-align: center;
		font-size: 2.5vmin;
		color: #999;
	}

	.USStatesSelect-select-selected + .USStatesSelect-value {
		color: #333;
	}
}

.USStatesSelect-value {
	pointer-events: none;
	position: absolute;
	width: 100%;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
}

@media only screen and (max-width:600px) {
	.USStatesSelect {
		border-bottom-width: 0.36vmin;
		margin-bottom: 4vh;
		width: 90vmin;

		.USStatesSelect-select, .USStatesSelect-value {
			font-size: 7.2vmin;
		}

		.USStatesSelect-select {
			padding: 0.4vmin 2vmin;
		}
	}
}