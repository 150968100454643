.SelectDot {
	width: 1vmin;
	height: 1vmin;
	border-radius: 50%;
	background: rgba(86,86,86,0.9);
	cursor: pointer;
}


.SelectDot.selected {
	background: rgba(183,183,183,0.86);
}

@media only screen and (max-device-width:600px) {
	.SelectDot {
		width: 7px;
		height: 7px;
	}
}