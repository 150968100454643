.modalViewSlideIn-enter,
.modalViewSlideIn-appear {
  transform: translate3d(0, 100vh, 0);
}

.modalViewSlideIn-enter-active,
.modalViewSlideIn-appear-active {
  transform: translate3d(0, 0, 0);
  opacity: 1;
  transition: all 400ms cubic-bezier(0.05, 0.98, 0.1, 1);
}

.modalViewSlideIn-exit {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.modalViewSlideIn-exit-active {
  transform: translate3d(0, 100vh, 0);
  transition: all 400ms cubic-bezier(0.05, 0.98, 0.1, 1);
}

.modalViewFade-enter,
.modalViewFade-appear {
  opacity: 0;
}

.modalViewFade-enter-active,
.modalViewFade-appear-active {
  opacity: 1;
  transition: all 400ms ease;
}

.modalViewFade-exit {
  opacity: 1;
}

.modalViewFade-exit-active {
  opacity: 0;
  transition: all 400ms ease;
}

.modalViewScaleDown-enter,
.modalViewScaleDown-appear {
  opacity: 0;
  transform: scale3d(1.05, 1.05, 1);
}

.modalViewScaleDown-enter-active,
.modalViewScaleDown-appear-active {
  opacity: 1;
  transform: scale3d(1, 1, 1);
  transition: all 300ms ease;
}

.modalViewScaleDown-exit {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

.modalViewScaleDown-exit-active {
  opacity: 0;
  transform: scale3d(0.95, 0.95, 1);
  transition: all 300ms ease;
}