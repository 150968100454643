// Common SASS mixins go here ↓

@import 'variables';

@mixin dot-indicator($animate-enter: true) {
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: saturate($rally-green, 8%);
  transform: scale(.9);
  box-shadow: 0px 0px 4px 0px saturate($rally-green, 8%);
  content: '';
  @if $animate-enter == true {
    animation: dot-indicator-pulse 2s infinite, dot-indicator-bounce-in .3s ease-in;
  } @else {
    animation: dot-indicator-pulse 2s infinite;
  }
}

@keyframes dot-indicator-pulse {
  50% {
    transform: scale(1.3);
    background-color: saturate($rally-green, 15%);;
    box-shadow: 0px 0px 10px 0px saturate($rally-green, 15%);;
  }
}

@keyframes dot-indicator-bounce-in {
  0% { transform: scale(0); }
  100% { transform: scale(.9) }
}
