
.Header{
	width: 100%;
	height: 10vh;
	min-height: 50px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.Header .Header-logowrapper,
.Header .Header-userloginwrapper,
.Header .Header-menuwrapper,
.Header .Header-navmenuwrapper{
	height: 100%;
}
.Header .Header-logowrapper{
	width: 16vh;
	margin-left: 1vh;
	padding: 0 2.4vh;
}
.Header .Header-logowrapper svg{
	max-height: 100%;
}
.Header .Header-userloginwrapper{
	display: flex;
	align-items: center;
	margin-right: 4vh;
}

.Header .Header-menuwrapper{
	width: 10vh;
	display: none;
}
@media only screen and (max-height:500px) {
	.Header .Header-logowrapper{
		width: 80px;
		padding: 0 12px;
	}
}

@media only screen and (max-width:1000px) {
	.Header .Header-logowrapper{
		margin-left: 0;
	}
}

@media only screen and (max-width:600px) {
	.Header{
		position: fixed;
		top: 0;
		background-color: white;
		z-index: 0;
		box-sizing: border-box;
		height: 7%;
		min-height: 25px;
		border-bottom: 1px solid #ccc;
		justify-content: center;
	}
	.Header .Header-logowrapper{
		margin-left: 0;
		width: 8vh;
		padding: 0 4vw;
	}
	.Header .Header-logowrapper svg{
		max-height: inherit;
		transform: translate(-50%,-50%) scale(1.2);
	}
	.Header .Header-userloginwrapper{
		display: none;
	}
	.Header .Header-menuwrapper{
		width: 7.4vh;
		display: block;
		position: absolute;
		right: 0;
	}
	.Header .Header-menuwrapper-toggle{
		width: 7.4vh;
		height: 7.4vh;
		display: block;
		position: absolute;
		left: 0px;
	}

	.Header .AssetsMobileFooter-list-toggler {
		display: flex;
		justify-content: center;
		width: 14vw;
		position: relative;
		height: 100%;

		&:after {
			 width: 0px;
		 }

		.AssetsMobileFooter-nav-btn {
			padding: 0;
			margin: 10px 0;
			height: calc(100% - 20px);
			position: relative;
		}
	}

	.Button-modeIcon-carousel,
	.Button-modeIcon-listview {
		position: absolute;
		width: 11vmin;
		height: 11vmin;
		background-image: url('../../../images/assets/Carousel_Icon_SVG_Active.svg');
		background-size: cover;
		transition: opacity .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
		opacity: 0;
	}

	.Button-modeIcon-listview {
		background-image: url('../../../images/assets/List_Icon_SVG_Active.svg');
		opacity: 1;
		animation-delay: .2s;
	}

	&.listView {
		.Button-modeIcon-carousel {
			opacity: 1;
			animation-delay: .2s;
		}

		.Button-modeIcon-listview {
			opacity: 0;
		}
	}



}
