.VerifyEmailPopup {

  .Popup-contents {
    min-height: inherit;
    max-width: 640px;
    padding: 5vmin;
    p { margin-top: 0; }
    h1, h2 { margin: 14px 0 20px; }
  }
  
  .VerifyEmailPopup-content {
    max-width: 800px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-size: 2vmin;
  }

  .VerifyEmailPopup-buttons {
    display: flex;
    width: 100%;
    justify-content: center;
    .SecondaryButton {
      max-width: 210px;
      width: 100%;
      padding: 2vmin 0;
      margin: 1vmin 2vmin;
      font-size: 2.2vmin;
    }
  }

  .VerifyEmailPopup-retry-btn {
    border-color: #000;
    color: #000;
    &:hover {
      background-color: #000;
    }
  }
}

@media only screen and (max-width:600px) {
  .VerifyEmailPopup {
    .VerifyEmailPopup-buttons .SecondaryButton {
      font-size: 4vmin;
      margin: 2.6vmin 3vmin;
    }
    .VerifyEmailPopup-content {
      font-size: 4vmin;
    }
  }
}