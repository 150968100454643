
.StartedInfo {

}

.StartedInfo .StartedInfo-img {
	height: 8vh;
}

.StartedInfo .Button {
	margin-top: 4vh;
	width: 80%;
}


@media only screen and (max-width:600px) {
	.StartedInfo .StartedInfo-img {
		height: 14vmin;
	}

	.StartedInfo .Button {
		margin-top: 5vmin;
	}
}