
.CarouselNavButton {
	display: block;
	width: 4vh;
	height: 14vh;
	display: flex;
	align-items: center;
	justify-content: center;
}
.CarouselNavButton .CarouselNavButton-button {
	width: 4vh;
	height: 4vh;
	color: #444;
	box-shadow: 0 0 4px rgba(0,0,0,0.2);
	background: rgba(255, 255, 255, 0.9);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.CarouselNavButton .CarouselNavButton-button .UpCaret svg {
	height: 4vh;
	width: 4vh;
	margin-bottom: 0.6vh;
}


@media only screen and (max-width:600px) {
	.CarouselNavButton {
		height: 50vmin;
		width: 14vmin;
	}
	.CarouselNavButton .CarouselNavButton-button {
		width: 10vmin;
		height: 10vmin;
	}
	.CarouselNavButton .CarouselNavButton-button .UpCaret svg {
		height: 9vmin;
		width: 9vmin;
		margin-bottom: 1.35vmin;
	}
}