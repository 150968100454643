@import 'variables';

.BidSummary {
  font-family: 'Gotham Book', sans-serif;
  .OrderSummary {
    margin-bottom: .5vh;
  }
	.BidSummary-info {
		margin-bottom: 3vh;
		text-transform: uppercase;
		font-weight: 800;
		.BidSummary-red{
			color: $rally-red;
		}
		.CheckmarkIcon{
			margin-bottom: 2vmin;
			display: block;
		}
		.BidSummary-info-title {
      font-family: 'Gotham Bold', sans-serif;
			color: $new-green;
      font-size: 1.6vh;
			margin-bottom: 0.8vh;
		}
		.BidSummary-info-status {
      @extend .BidSummary-info-title;
    }
		.BidSummary-info-order {
			color: $grey;
			font-size: 1.6vmin;
		}
	}

	.BidSummary-type {
		display: flex;
		justify-content: space-around;
    align-items: center;
		border-top: 1px solid #5a5a5a;
		border-bottom: 1px solid #5a5a5a;
    box-sizing: border-box;
		text-transform: uppercase;
    max-width: 420px;
    margin: 0 auto 3.3vh;
    height: 11vh;
		.BidSummary-type-column {
			text-align: center;
		}
		.BidSummary-type-header {
      font-family: 'Gotham Bold', sans-serif;
			color: #AFAFAF;
			font-size: 1.4vh;
			letter-spacing: 1px;
      margin-bottom: 1vh;
		}
		.BidSummary-type-value {
			font-size: 1.4vh;
      color: #AFAFAF;
      margin-bottom: 1vh;
      &:last-of-type {
        margin-bottom: 0;
      }
		}
	}

	.BidSummary-tip {
    max-width: 400px;
    margin: 0 auto;
    color: #AFAFAF;
    font-size: 1.7vh;
    line-height: 1.5;
    margin-bottom: 1vh;
		a {
			font-weight: 700;
			color: $blue;
		}
	}
	.BidSummary-contact {
    max-width: 400px;
    margin: 0 auto;
    font-size: 1.3vh;
    line-height: 1.5;
		color: $grey;
		a {
			white-space: nowrap;
      color: $new-green;
		}
	}

	.BidSummary-cancel-btn.TextButton {
		text-transform: uppercase;
		font-weight: 500;
		color: $rally-red;
	}
}

@media only screen and (max-width:600px) {
	.BidSummary {
    .OrderSummary {
      margin-bottom: .8vh;
    }
		.BidSummary-info {
			margin:  0 0 2vh;
			.CheckmarkIcon{
				margin-bottom: 1.6vh;
			}
			.BidSummary-info-title {
				margin-bottom: 1.1vh;
			}
			.BidSummary-info-order {
				font-size: 1.3vh;
			}
      .BidSummary-info-status {
        font-size: 1.3vh;
				margin-bottom: .5vh;
      }
		}
		.BidSummary-type {
			margin-bottom: 2vh;
      height: 11vh;
			.BidSummary-type-header {
				font-size: 1.5vh;
			}
			.BidSummary-type-value {
        font-size: 1.5vh;
			}
		}
		.BidSummary-tip {
      max-width: 41vh;
      margin: 0 auto;
			font-size: 2vh;
      margin-bottom: 1vh;
		}
		.BidSummary-contact {
      max-width: 41vh;
			font-size: 1.4vh;
			line-height: 1.2;
		}
	}
	.TroughButtons {
    .BidSummary-cancel-btn.TextButton {
      font-size: 2.2vh;
    }
  }
}
