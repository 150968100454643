
.TimelineChart{
	position: relative;
	min-height: 22vmin;
	width: 100%;
}

.TimelineChart .TimelineChart-point{
	position: absolute;
	top: 0;
	height: 100%;
	width: 100%;
}

.TimelineChart .TimelineChart-selectedValue{
	position: absolute;
	top: -3.8vh;
	text-decoration: underline;
	font-family: 'Bebasneue', sans-serif;
	font-size: 18px;
	color: white;
	white-space: nowrap;
	width: 20vh;
	margin-left: -10vh;
	text-align: center;
}

.TimelineChart .TimelineChart-selectedLine{
	position: absolute;
	height: 100%;
	width: 1px;
	margin-left: -0.5px;
	background-color: white;
	opacity: 0.6;
}

.TimelineChart .TimelineChart-lines{
	height: 100%;
	width: 100%;
	pointer-events: none;
}

.TimelineChart .TimelineChart-lines-all,
.TimelineChart .TimelineChart-lines-bestfit{
	display: block;
	height: 100%;
	width: 100%;
	pointer-events: none;
}

.TimelineChart .TimelineChart-lines-all{
	position: absolute;
	top: 0;
}

