@import 'variables';
$section-border: 1px solid #ddd;

.AvailableFundsBanner{
	width: 100%;
	height: 6vh;
	color: #333;
  position: absolute;
	top: 3vh;
	display: flex;
	align-items: center;
	background-color: white;
	z-index: 2;
  font-family: 'Gotham Bold', sans-serif;

  &.post-only {
	  top: 3vh;
  }
	.AvailableFundsBanner-section{
		text-align: left;
		margin: 1vmin 2.4vmin;
		.AvailableFundsBanner-sectionheader{
			text-transform: uppercase;
			font-size: 1.2vmin;
		}
		.AvailableFundsBanner-sectionvalue{
			font-size: 2vmin;
			line-height: 2.2vmin;
			color: #444;
		}
	}
  .AvailableFundsBanner-totalsection{
    .AvailableFundsBanner-sectionvalue{
      font-family: 'Gotham book', sans-serif;
      font-weight: 300;
    }
  }
	.AvailableFundsBanner-cashsection{
		margin-left: 1.4vmin;
		.AvailableFundsBanner-section{
			.AvailableFundsBanner-sectionvalue{
				color: $new-green;
			}
		}
	}
}

@media only screen and (max-width:600px) {
	.AvailableFundsBanner{
		height: 7vh;
		.AvailableFundsBanner-section{
			margin: 0 3.8vh 0 0;
			.AvailableFundsBanner-sectionheader{
				font-size: 1.25vh;
        margin-bottom: 0.5vh;
			}
			.AvailableFundsBanner-sectionvalue{
				font-size: 2.4vh;
				line-height: 3.3vmin;
			}
		}
		.AvailableFundsBanner-cashsection{
			margin-left: 2.1vmin;
		}
	}

}
