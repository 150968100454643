@import 'variables';

.TradingApprovedPopup {
  z-index: 10;
}

.TradingApprovedPopup .TradingApprovedPopup-content {
  max-width: 560px;
  h1 {
    font-size: 3.8vh;
    line-height: 1.2;
  }
  p {
    line-height: 1.8;
    font-size: 2vh;
  }
  .SecondaryButton {
    padding: 1.8vh 10vh; 
  }
  footer {
    margin-top: 4vh;
    font-size: 1.8vh;
    line-height: 1.8;
    text-align: center;
    a {
      color: inherit;
      cursor: pointer;
      &:hover { color: $rally-green; }
    }
  }
}