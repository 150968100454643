

.TradingWindowBanner{
	width: 100%;
	background-color: #44D39C;
	color: white;
	height: 3vh;
	/*position: fixed;*/
	position: absolute;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2;
	font-weight: 400;
  font-family: 'Gotham Book', sans-serif;
  letter-spacing: .5px;
}

.TradingWindowBanner.post-only {
	background-color: #ED6F33;
}

.TradingWindowBanner b {
  margin-right: 1.6vh;
  font-family: 'Gotham Bold', sans-serif;
}

@media only screen and (max-width:600px) {
	.TradingWindowBanner{
    font-size: 2.6vw;
	}
}
