.TextButton {
	font-family: 'Muli', sans-serif;
	border-radius: 100vmin;
	border: none;
	font-size: 2.6vmin;
	font-weight: 700;
	color: #53cd7f;
	background: none;
	text-transform: none;
	letter-spacing: normal;
	margin: 0 4vmin;
	padding: 2vmin 2vmin;
	position: relative;
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	justify-content: center;

	@media only screen and (max-width:600px) {
		font-size: 5vmin;
		margin: 2vmin 2.4vmin;
		padding: 4vmin;
	}
	
	/* ----- TextButton states ----- */	

	&:focus {
		outline: none;
	}
	&:disabled, &[disabled] {
		color: #ddd;
		cursor: default;
	}

	/* ----- TextButton arrows ----- */

	&.TextButton-left-arrow, &.TextButton-right-arrow {
		letter-spacing: 0.2vmin;
		font-size: 2.3vmin;
		text-transform: uppercase;

		@media only screen and (max-width:600px) {
			letter-spacing: 0.3vmin;
			font-size: 5.6vmin;
		}
	}
	&.TextButton-left-arrow {
		font-weight: 600;
		color: #ccc;
		&:hover:not([disabled]) {
			color: #999;
		}
		&::before {
			left: 0.2vmin;
			transition: left 0.1s;
			transition-timing-function: ease-out;
			content: '<';
			@media only screen and (max-width:600px) {
				left: -0.6vmin;
			}
		}
		&:hover:not([disabled])::before {
			left: -0.5vmin;
			transition: left 0.2s;
			@media only screen and (max-width:600px) {
				left: -1.1vmin;
			}
		}
	}
	&.TextButton-right-arrow {
		&::after {
			right: 0.2vmin;
			transition: right 0.1s;
			transition-timing-function: ease-out;
			content: '>';
			@media only screen and (max-width:600px) {
				right: -0.6vmin;
			}
		}
		&:hover:not([disabled])::after {
			right: -0.5vmin;
			transition: right 0.2s;
			@media only screen and (max-width:600px) {
				right: -1.1vmin;
			}
		}
		b {
			font-weight: 800;
		}
	}
	&.TextButton-right-arrow::after, &.TextButton-left-arrow::before{
		font-size: 1.7vmin;
		margin-top: -0.36vmin;
		font-weight: 600;
		position: absolute;
		transform: scaleY(1.6) scaleX(0.8);

		@media only screen and (max-width:600px) {
			font-size: 4.4vmin;
		}
	}

	/* ----- TextButton icons ----- */

	&.TextButton-icon {
		margin: 0 0 2vh 1.8vh;
		color: #d0c5ac;
		margin: 0;
		font-size: 1.9vh;

		.TextButton-img-wrapper {
			position: absolute;
			left: -2.4vh;
			top: 0;
			padding: 2vmin 0;
			width: 3.2vh;
			height: 2vh;
			display: flex;
			align-items: center;
			justify-content: center;
			img {
				width: 3.2vh;
				max-height: 2.8vh;
				margin-right: 1vh;
				margin-top: 0.6vmin;
			}
		}

		.TextButton-icon-wrapper {
			position: absolute;
			left: -2.4vh;
			width: 3vh;
			height: 3vh;
			display: flex;
			align-items: center;
			justify-content: center;
			svg {
				max-height: 2.8vh;
				max-width: 3vh;
			}
		}

		&.TextButton-right-arrow::after {
			font-size: 1.5vh;
			margin-top: -0.38vmin;
		}

		@media only screen and (max-width:600px) {
			.TextButton-img-wrapper {
				left: -2.8vh;
				padding: 4vmin 0;
			}
			&.TextButton-right-arrow::after {
				right: -0.4vh;
			}
		}
	}

	/* ----- TextButton content ----- */	

	b {
		font-weight: 700;
	}
	span {
		display: inline;
	}
}
