
.ImageUpload{
	min-height: 26vh;
	margin: 1vmin;
}

.ImageUpload .ImageUpload-inner{
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 2vh 0;
}

.ImageUpload .ImageUpload-img{
	width: 12vmin;
}

.ImageUpload .ImageUpload-description{
	margin: 1vh 8vmin 4vh 8vmin;
	font-size: 2.4vh;
	line-height: 3vh;
}

.ImageUpload .ImageUpload-description b{
	font-weight: normal;
	color: #53cd7f;
}

.ImageUpload .ImageUpload-rules{
	font-size: 1.6vh;
	color: #666;
	position: relative;
	bottom: 0;
}

.ImageUpload .ImageUpload-rules__error {
	font-weight: 700;
	color: #d20;
}