@import 'variables';

.ConfirmTradePopup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0, 0.85);
  z-index: 999;

  &.partial h2 { font-size: 20px; }

  h1 {
    margin-bottom: 0;
    font-size: 32px;
    line-height: 26px;
  }
  h2 {
    margin: 0;
    font-size: 21px;
  }
  p {
    font-size: 20px;
  }

  .ConfirmTradePopup-content {
    position: relative;
    justify-content: flex-start;
    max-width: 394px;
    min-height: inherit;
    padding: 20px 30px;
    overflow: visible;
  }

  .ConfirmTradePopup-price {
    font-size: 24px;
    color: #39e59a;
  }

  .ConfirmTradePopup-main-btn {
    font-weight: bold;
    font-size: 21px;
    letter-spacing: 1px;
    padding: 24px 0;
    width: 100%;
    box-shadow: 0 3px 4px rgba(0,0,0, .4);
  }

  .ConfirmTradePopup-cancel {
    position: absolute;
    bottom: -70px;
    width: 100%;
  }
  .ConfirmTradePopup-cancel-btn {
    color: $grey;
    font-weight: normal;
    font-size: 19px;
    transition: color .1s linear;
    &:hover {
      color: lighten($grey, 10%);
    }
  }
  
  /* -------- START Refuse message styles -------- */
  .ConfirmTradePopup-refuse-back-btn, .ConfirmTradePopup-refuse-link {
    font-size: 2vmin;
    padding: 2vmin;
    font-weight: bold;
  }

  .ConfirmTradePopup-refuse-back-btn {
    margin: 0;
    color: #181818;
  }

  .ConfirmTradePopup-refuse-link {
    color: $rally-red;
  }

  /* -------- END Refuse message styles -------- */

  @media only screen and (max-width:600px) {
    &.partial h2 { font-size: 16px; }
    h1 { font-size: 26px; }
    h2 { font-size: 18px; }
    p { font-size: 15px; }

    .ConfirmTradePopup-content {
      padding: 10px 20px;
    }
    .ConfirmTradePopup-price {
      font-size: 20px;
    }
    .ConfirmTradePopup-popper {
      width: 80px;
    }
    .ConfirmTradePopup-main-btn {
      padding: 19px 0;
      font-size: 19px;
    }
    .ConfirmTradePopup-cancel-btn {
      font-size: 17px;
    }
    .ConfirmTradePopup-refuse-back-btn, .ConfirmTradePopup-refuse-link {
      font-size: 4.5vmin;
      padding: 2vmin 5vmin;
    }
  }

  @media only screen and (max-width:340px) {
    &.partial h2 { font-size: 14px; }
    h1 { font-size: 24px; }
    h2 { font-size: 16px; }
    p { font-size: 14px; }

    .ConfirmTradePopup-content {
      padding: 10px 20px;
    }
    .ConfirmTradePopup-price {
      font-size: 18px;
    }
    .ConfirmTradePopup-popper {
      width: 70px;
    }
    .ConfirmTradePopup-main-btn {
      padding: 13px 0;
      font-size: 17px;
    }
    .ConfirmTradePopup-cancel {
      bottom: -60px;
    }
    .ConfirmTradePopup-cancel-btn {
      font-size: 15px;
    }
  }
}