.BackNextButtons {
	position: relative;
}

.BackNextButtons .BackNextButtons-loader {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #fff;
	z-index: 1;
}

.BackNextButtons .BackNextButtons-loader .DotsLoader-dot {
	margin: 6px !important;
}

.BackNextButtons-centered .BackNextButtons-back,
.BackNextButtons-centered .BackNextButtons-next {
	display: block;
}

.BackNextButtons-inline {
	display: flex;
	flex-direction: row-reverse;
	justify-content: center;
	align-items: center;
}

.BackNextButtons-inline::after {
  content: "";
  clear: both;
  display: table;
}

.BackNextButtons-inline .BackNextButtons-back,
.BackNextButtons-inline .BackNextButtons-next {
	width: 50%;
}

@media only screen and (max-width:600px) {
	.BackNextButtons .BackNextButtons-back,
	.BackNextButtons .BackNextButtons-next {
		width: 100%;
	}

	.Onboarding-wrapper .SocialSecurityNumber.shown .BackNextButtons-inline {
		flex-direction: column;
	}
}


