@import 'variables';

.TradingLegalPopup-content {
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 4px;
  text-align: left;
  color: $text-black;
}

.TradingLegalPopup-header {
  color: $text-black;
  text-align: center;
}

.TradingLegalPopup-logos {
  display: flex;
  align-items: center;
  margin: 10px 0 30px;
}

.TradingLegalPopup-logo {
  display: inline-block;
  img, svg {
    max-width: 80%;
    max-height: 8.5vw;
  }
  &-rally {
    width: 40%;
  }
  &-drivewealth {
    width: 60%;
  }
}

@media only screen and (max-width:750px) {
  .TradingLegalPopup-logo {
    img, svg {
      max-height: 11vh;
    }
  }
}
