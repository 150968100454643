
.Manage p.light{
	margin-bottom: 4vh;
}

.Manage .Manage-img{
	margin-bottom: -8vh;
}

.Manage a{
	display: block;
}

.Manage {
	display: flex;
	flex-direction: column;
	align-items: center;
}