
.Checkbox{
	display: flex;
	position: relative;
}

.Checkbox .Checkbox-input{
	cursor: pointer;
	appearance: none;
	margin: 0;
	background-color: white;
	height: 2.6vmin;
	width: 2.6vmin;
	border: 0.05vmin solid #ccc;
}
.Checkbox .Checkbox-input input{
	margin: 0;
}
.Checkbox .Checkbox-input:checked{
	background-color: #4b6;
	border-color: #aaa;
}

.Checkbox.large .Checkbox-input{
	height: 4.6vh;
	width: 4.6vh;
	border-width: 0.2vmin;
}

.Checkbox.large .Checkbox-input:disabled {
	cursor: not-allowed;
}

.Checkbox-input--green:not([disabled]) {
	border-color: #53cd7f;
	box-shadow: 0px 0px 4px #53cd7f;
}

.Checkbox .Checkbox-checkmark {
	color: white;
	position: absolute;
	pointer-events: none;
	top: 0.3vmin;
	left: 0.3vmin;
}
.Checkbox.large .Checkbox-checkmark {
	top: 0.3vh;
	left: 0.3vh;
}
.Checkbox .Checkbox-checkmark.hidden {
	display: none;
}
.Checkbox .Checkbox-checkmark .CheckmarkIcon svg {
	width: 2vmin;
	height: 2vmin;
}
.Checkbox.large .Checkbox-checkmark .CheckmarkIcon svg {
	width: 4vh;
	height: 4vh;
}


@media only screen and (max-width:600px) {
	.Checkbox .Checkbox-input{
		height: 6vmin;
		width: 6vmin;
	}
	.Checkbox .Checkbox-checkmark {
		top: 0.5vmin;
		left: 0.5vmin;
	}
	.Checkbox .Checkbox-checkmark .CheckmarkIcon svg {
		width: 5vmin;
		height: 5vmin;
	}

}