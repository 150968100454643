.AccountSettings-email-popup {
	.Popup-contents {
		min-height: inherit;
		justify-content: center;
		span {
			font-weight: 600;
		}
	}
}

.UserPassword{
	font-weight: 600;
	display: inline-flex;
	vertical-align: middle;
}
.UserPassword-display{
	padding-left: 4vmax;
	line-height: 3vmax;
	background-image: url('../../../../images/passwordLock.png');
	background-position: left;
	background-repeat: no-repeat;
	background-size: auto 2.2vmax;
	display: flex;
	vertical-align: middle;
}
.UserPassword .AdminButton{
	margin: 0.2vmax 4vmax 0 4vmax;
}

@media only screen and (max-width:600px) {
	.UserPassword{
		width: calc(100% - 4vh);
		justify-content: left;
	}
	.UserPassword .AdminButton{
		margin: 0.4vmin 0;
	}
	.UserPassword-display{
		display: none;
	}
}