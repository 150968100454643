.TransferInitiated .TransferInitiated-checkmark .CheckmarkIcon svg {
	width: 6vh;
	height: 6vh;
}

.TransferInitiated .TransferInitiated-amount {
  color: #41c84b;
  font-size: 5vh;
  font-weight: 700;
  margin: 1vh 0 2vh 0;
}

.TransferInitiated .TransferInitiated-btn {
  white-space: nowrap;
}