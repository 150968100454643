.SignupForm .SignupForm-callout {
  margin-bottom: 3.2vh;
  font-size: 1.8vmin;
}

.SignupForm .Button,
.SignupForm .SecondaryButton {
  font-size: 2vmin;
  margin-top: 1vh;
}

.SignupForm .SignupForm-login {
  margin-top: 3.2vh;
}
.SignupForm .SignupForm-login a {
  font-weight: 600;
}

.SignupForm .VerifyEmailPopup {
  position: fixed;
}

.SignupForm .AuthenticationPages-actionButton {
  border: 3px solid rgb(226, 201, 141);
  background-color: transparent;
}

.SignupForm .AuthenticationPages-actionButton:hover {
  background-color: rgb(226, 201, 141);
}

@media only screen and (max-width: 600px) {
  .SignupForm .SignupForm-login {
    color: #676767;
    font-size: 4vmin;
    margin-top: 18vmin;
  }
  .SignupForm .SignupForm-login a {
    color: #fff;
  }

  .SignupForm .AuthenticationPages-actionButton {
    background-color: #fa864a;
    font-size: 4vmin;
    width: 86vmin;
    margin-top: 4vmin;
    padding: 5.4vmin 0 5.9vmin 0;
    text-transform: uppercase;
    border-radius: 100vmin;
    color: white;
    border: none;
  }
}
