
.IntroMessage {
	
}

.IntroMessage .IntroMessage-img {
	height: 11vh;
}

.IntroMessage .IntroMessage-title {
	margin: 3.8vh;
}

.IntroMessage .Button {
	margin-top: 4vh;
}