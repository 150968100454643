.LoginSignup {
  text-align: center;
  font-size: 1.6vmin;
}

.LoginSignup-box {
  border: 0.05vmin solid #d9d9d9;
  padding: 2.5vmin 5.5vmin;
}

.LoginSignup-video {
  background: black;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -10;
  width: 100%; 
  height: 100%;
  object-fit: cover;
}

.LoginSignup-video-overlay {
  background-color: rgba(34, 34, 34, 0.45);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: -5;
}

.LoginSignup .LoginSignup-logo {
  height: 8vh;
  margin-bottom: 2.8vh;
}

.LoginSignup .LoginSignup-subtitle {
  color: #fff;
  font-size: 6.4vmin;
  margin-bottom: 4.4vmin;
  font-family: 'IBM Plex';
}

.LoginSignup .LoginSignup-text {
  font-family: 'Gotham Book';
  color: #eee;
}

.LoginSignup .LoginSignup-text p {
  margin: 0;
  font-size: 3.8vmin;
  line-height: 1.5;
}

.LoginSignup .LoginSignup-help {
  margin-bottom: 4vh;
}

.LoginSignup .Input input {
  width: 33vmin;
  display: block;
  border: 0.02vmin solid #28352e;
  margin-bottom: 2vmin;
  font-size: 2vmin;
  text-align: left;
  background: #fff;
  padding: 1.3vmin;
}

.LoginSignup .Input input::placeholder {
  color: #28352ed3;
}

.LoginSignup .Button,
.LoginSignup .SecondaryButton {
  width: 33vmin;
  margin: 1.6vmin auto 0;
  padding: 1.6vmin 5vmin;
}

.LoginSignupForm-errors {
  width: 33vmin;
  margin-top: 2vmin;
  font-weight: 800;
  color: #dd2200;
}

.LoginSignup .LoginSignup-help a {
  font-weight: 600;
}

.LoginSignup-signupOrSignin {
  margin: 3.5vmin 0;
  font-size: 1.8vmin;
  color: #28352e;
}

.LoginSignup .LoginSignup-signupOrSignin a {
  font-weight: 900;
  color: #28352e;
  text-decoration: underline;
}

.LoginSignup-appDownloadLinks {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  width: 33vmin;
  justify-content: space-evenly;
  font-size: 0;
}

.LoginSignup-appDownloadLinks li.apple-appstore {
  width: 110px;
  height: 36.5px;
  background-image: url('../../../images/download_appstore_apple.png');
  background-size: cover;
}

.LoginSignup-appDownloadLinks li.google-playstore {
  width: 123px;
  height: 36.5px;
  background-image: url('../../../images/download_playstore_google.png');
  background-size: cover;
}

@media only screen and (max-width: 600px) {
  .LoginSignup {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 3.8vmin;
    line-height: 5vmin;
    position: relative;
  }
  .LoginSignup .LoginSignup-top {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .LoginSignup .LoginSignup-video {
    display: block;
  }
  .LoginSignup .LoginSignup-logo {
    height: auto;
  }
  .LoginSignup .RallyLogo {
    height: 24vmin;
  }
  .LoginSignup .RallyLogo svg {
    max-height: 100%;    
    position: relative;
    top: auto;
    left: auto;
    transform: none;
  }
  .LoginSignup .LoginSignup-form {
    border: 0;
    padding: 0;
    margin-bottom: 5vmin;
  }
  .LoginSignup .Input input {
    border: 1px solid #464646;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    color: #f2f2f2;
    width: 100%;
    margin-bottom: 0;
    padding: 5.1vmin 2.5vmin;
    font-size: 4.2vmin;
    box-sizing: border-box;
    text-align: center;
    caret-color: white;
    background-color: transparent;
  }
  .LoginSignup .Input input::placeholder {
    color: #f2f2f2;
  }
  .LoginSignupForm-errors {
    width: 80vmin;
    margin-top: 4vmin;
    margin-right: auto;
    margin-left: auto;
  }

  .LoginSignup-box {
    border: none;
    padding: 0;
    margin-bottom: 5vmin;
  }

  .LoginSignup .LoginSignup-signupOrSignin {
    color: #999;
    font-size: 4vmin;
    margin-top: 18vmin;
  }

  .LoginSignup .LoginSignup-signupOrSignin a {
    color: #fff;
    text-decoration: none;
  }

  .LoginSignup .LoginSignup-appDownloadLinks {
    display: none;
  }
}
