.SquareButton {
	font-family: 'Muli', sans-serif;
	border-radius: 0;
	border: 0.4vmin solid #444;
	font-size: 2.6vmin;
	font-weight: 900;
	color: #444;
	background-color: rgba(0,0,0,0);
	transition: all 0.2s;
	text-transform: uppercase;
	letter-spacing: normal;
	margin: 1vmin 1.2vmin;
	padding: 2.6vmin 10vmin;
	position: relative;
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	justify-content: center;

	@media only screen and (max-width:600px) {
		font-size: 5vmin;
		margin: 2vmin 2.4vmin;
		padding: 4.2vmin 14vmin;
		border-width: 1vmin;
	}
	
	/* ----- SquareButton states ----- */	

	&:focus {
		outline: none;
	}
	&:disabled, &[disabled] {
		background-color: lightgrey;
		border-color: #666;
	  color: white;
	  cursor: default;
	}
	&:hover:not([disabled]) {
		background-color: #444;
		color: white;
	}

	/* ----- SquareButton themes ----- */	
	
	&.light {
		color: #444;
		background: none;
	}
	&.light:hover:not([disabled]) {
		color: #53cd7f;
		background: none;
	}
	&.light:disabled, &.light[disabled] {
		color: lightgrey;
	  background: none;
	}

	/* ----- SquareButton arrows ----- */

	&.SquareButton-right-arrow.SquareButton-light:not([disabled]){
		color: #53cd7f;
	}
	&.SquareButton-left-arrow, &.SquareButton-right-arrow{
		padding: 2.1vmin 8vmin;
		letter-spacing: 0.2vmin;
		font-size: 2.3vmin;

		@media only screen and (max-width:600px) {
			padding: 4vmin 16vmin;
			letter-spacing: 0.3vmin;
			font-size: 5.6vmin;
		}
	}
	&.SquareButton-right-arrow b{
		font-weight: 800;
	}
	&.SquareButton-right-arrow::after, &.SquareButton-left-arrow::before{
		font-size: 1.7vmin;
		margin-top: -0.36vmin;
		font-weight: 600;
		position: absolute;
		transform: scaleY(1.6) scaleX(0.8);

		@media only screen and (max-width:600px) {
			font-size: 4.4vmin;
			margin-top: -1vmin;
		}
	}
	&.SquareButton-right-arrow::after{
		content: '>';
		right: 5.4vmin;

		@media only screen and (max-width:600px) {
			right: 6vmin;
		}
	}
	&.SquareButton-left-arrow::before{
		content: '<';
		left: 5.4vmin;

		@media only screen and (max-width:600px) {
			left: 6vmin;
		}
	}

	/* ----- SquareButton content ----- */	

	b {
		font-weight: 700;
	}
	span {
		display: inline;
	}
}
