.Backbutton {
  position: fixed;
  top: 1.5vmax;
  left: 1vmin;
  width: 7.8vmin;
  height: 7.8vmin;
  color: #4a4947;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  z-index: 10;
}

.Backbutton .Backbutton-chevron,
.Backbutton .LeftCaret,
.Backbutton .UpCaret {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Backbutton .Backbutton-chevron svg {
  fill: currentColor;
  width: 100%;
  height: 100%;
}