
.Tour .Tour-wrapper {
	margin: 0 auto;
	box-sizing: border-box;
	max-width: 600px;
}
.Tour .TourForm-container-contents{
	min-height: 30vh;
}

.Tour h2 {
	margin-top: -5vh;
}

.Tour p {
	min-height: 10vh;
	font-size: 2.4vh;
	line-height: 4.4vh;
	color: #444;
}
.Tour p.light {
	font-size: 2.2vh;
	line-height: 3.6vh;
	color: #666;
}

.Tour .Tour-gif{
	height: 45vh;
}

.Tour .Tour-skipbutton{
	font-size: 2vmin;
	color: #9e9e9e;
}

.Tour .BackNextButtons .TextButton  {
	margin: 0;
}

.Tour .BackNextButtons .TextButton-right-arrow {
	padding-left: 1.6vh;
}
.Tour .BackNextButtons .TextButton-left-arrow {
	padding-right: 1.6vh;
}

@media only screen and (max-width:600px) {
	.Tour .Tour-wrapper {
		margin: 0;
		width: 100%;
	}
	.Tour p {
		font-size: 2.8vh;
		line-height: 5.4vh;
		margin: 0 8vw;
	}
	.Tour p.light {
		font-size: 2.2vh;
		line-height: 3.4vh;
		margin: 0 8vw;
	}

	.Tour .Tour-skipbutton{
		font-size: 4vmin;
	}

}