.InvestingPassport {
  height: 100%;
  display: flex;
  flex-direction: column;


  &-wrapper {
    overflow: auto;
    
  .profile-update & {
      max-height: 70vh;
      margin-bottom: 10px;
    }
  }

  .Button, .TextButton {
    font-weight: bold;
    text-transform: uppercase;
  }

  .TextButton {
    color: #bbb;
    font-size: 1.6vh;
    letter-spacing: 0.5px;
    &:hover {
      color: #a9a9a9;
    }
  }

  .InvestingPassport-title {
    font-size: 2.6vh;
    margin: 4vh 0;
  }

  .InvestingPassport-items {
    margin-bottom: 2vh;
    text-align: left;
  }

  .InvestingPassport-item {
    margin-bottom: 2vh;
    padding: 0 3vw 2vh;
    border-bottom: 1px solid #ccc;
    line-height: 1.4;
    &-title {
      line-height: 1.8;
      letter-spacing: 0.5px;
      font-size: 1.5vh;
      font-weight: bold;
      color: #bbb;
      text-transform: uppercase;
    }
    &-content {
      font-size: 1.9vh;
    }
    &:last-child {
      border-bottom: none;
    }
  }
}