.ConnectBank {

}

.ConnectBank .ConnectBank-choice {
  display: block;
  margin: 1.4vh 0;
  width: 100%;
	border: 0.1vh solid #aaa;
	border-radius: 1vh;
  padding: 2vh;
  background-color: white;
  cursor: pointer;
}

.ConnectBank .ConnectBank-choice:hover {
	background-color: #ddd;
}