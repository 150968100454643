

.NotEnoughCash .NotEnoughCash-difference{
	color: #dd2200;
}

.NotEnoughCash .NotEnoughCash-linkedbanktransfer{
	margin: 8vh 4vh;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.NotEnoughCash .NotEnoughCash-amount{
	font-weight: 800;
	font-size: 4vh;
}

.NotEnoughCash .NotEnoughCash-bankinfo{
	text-transform: uppercase;
	color: #888;
	font-size: 1.8vh;
}