
.OrderSummary{
  font-family: 'Gotham Bold', sans-serif;
	font-size: 2.6vh;
	color: white;
	font-weight: 900;
}

@media only screen and (max-width:600px) {
	.OrderSummary{
		font-size: 2.2vh;
	}
}