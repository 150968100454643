@import 'variables';

.employment-details {
	margin-bottom: 4vh;
  max-width: 100%;
  @media only screen and (min-width: $breakpoint-sm) {
    width: 400px;
  }

  &__title {
    white-space: nowrap;
    margin: 4vmin 0 10vmin;
    font-size: 2.8vh;
    line-height: 5.4vh;
    font-weight: normal;
    @media only screen and (min-width: $breakpoint-sm) {
      margin-bottom: 6vmin;
    }
  }

  &__input {
    margin-bottom: 5vmin;
  }


  .MakeInvestment & .Input {
    margin-bottom: 0;

    .Input-description {
      line-height: 1.25;
      text-transform: uppercase;
      font-size: 2.8vmin;
      font-weight: 900;
      margin: 1vmin 0;
      @media only screen and (min-width: $breakpoint-sm) {
        font-size: 1.5vmin;
        margin-top: 0.6vmin;
      }
    }
  
  }
}
