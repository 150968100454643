.LightBox {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  padding: 0 20px;
  z-index: 50;
  box-sizing: border-box;

  .LightBox-backdrop {
    position: absolute;
    background-color: rgba(0,0,0, .8);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .LightBox-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    max-width: 1000px;

    height: 100%;
  }

  .Lightbox-media-container {
    position: relative;
  }

  .Closebutton {
    margin: 0;
    top: -5.8vmin;
    right: -3vmin;
  }

  .LightBox-video, .LightBox-image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  .LightBox-content svg, .LightBox-image img, .LightBox-video video {
    z-index: 51;
    max-height: calc(100vh - 140px);
  }

  .LightBox-video video {
    max-height: 100%;
    max-width: 100%;
  }

  .LightBox-arrow {
    display: inline-block;
    width: 10vh;
    height: 8vh;
    font-size: 8vh;
    font-weight: 400;
    line-height: 0;
    transform: scaleY(1.4) scaleX(0.8);
    color: #9e9e9e;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    z-index: 52;
    &:hover {
      color: #fff;
    }
  }
}

@media only screen and (max-width:600px) {
  .LightBox {    
    .LightBox-image img, .LightBox-video video {
      z-index: 51;
      max-height: calc(100vh - 220px);
    } 
    
    .LightBox-content {
      width: 100%;
    }
    .LightBox-arrow {
      position: absolute;
      bottom: 30px;
    }
    .Closebutton {
      width: 8vmin;
      height: 8vmin;
      top: -12vmin;
      right: 0;
    }
    .LightBox-arrow-prev {
      left: 20px;
    }
    .LightBox-arrow-next {
      right: 20px;
    }
  }
}
