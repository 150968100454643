@import 'variables';

.Details {
	@extend %asset-page-content;
	color: rgba(0,0,0,0.7);
  position: relative;
  display: inline-block;
	overflow-y: scroll;
	float: left;
}
.Details.special-access {
	width: 100%;
	height: 100%;
}

.Details .Details-header {
	width: 100%;
	padding-top: 8vh;
	text-align: center;
	font-family: 'Bebasneue', sans-serif;
	background-color: #ddd;
}

.Details .Details-header-ticker {
	font-weight: 600;
	font-size: 6vh;
	line-height: 5vh;
}

.Details .Details-header-name {
	font-size: 2.8vh;
	margin-bottom: 2.2vh;
}

.Details .Details-header-documents {
	height: 7vh;
	background-color: rgba(0,0,0,0.14);
	overflow-x: auto;
	white-space: nowrap;
}
