.Closebutton {
  position: absolute;
  top: 0;
  right: 0;

  margin: 2.6vmin;
	width: 3.8vmin;
	height: 3.8vmin;
	border-radius: 50%;
	background: rgba(255, 255, 255, 0.6);
	color: #4a4947;
	display: block;
	cursor: pointer;

	display: flex;
	align-items: center;
	justify-content: center;

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;

	z-index: 10;
}

.Closebutton.isHowItWorksPopup {
	position: relative;
	margin: 0;
}

.Closebutton .Closebutton-cross {
	width: 48%;
	height: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.Closebutton .Closebutton-cross svg {
	fill: currentColor;
	width: 100%;
	height: 100%;
}

.Closebutton:hover {
	background: rgba(255, 255, 255, 0.7);
}

@media only screen and (max-width:600px) {
	.Closebutton{
		width: 10vmin;
		height: 10vmin;
		margin: 4vmin;
	}
}


