@import 'variables';
$pending-yellow: #cfa600;

.Receipt{
	margin: 5vh 0;
	font-size: 1.4vh;
	max-width: 400px;

	.Receipt-dark {
		color: #444;
	}
	.Receipt-purchase {
		color: $money-green;
	}
	.Receipt-sale {
		color: #dd2200;
	}
	
	.Receipt-type {
		color: $money-green;
		font-weight: 800;
		font-size: 1.4vh;
		text-transform: uppercase;
		margin-bottom: 1vh;
	}


	.Receipt-description{
		color: black;
		font-weight: 700;
		font-size: 1.6vh;
		margin-bottom: 1.2vh;
	}

	.Receipt-source{
		margin-bottom: 1.2vh;
		color: #444;
		font-weight: 500;
	}

	.Receipt-date{
		color: $grey;
		font-weight: 600;
	}

	.Receipt-status{
		text-transform: uppercase;
		color: $money-green;
	}

	.Receipt-transaction {
		color: $grey;
		text-transform: uppercase;
		margin-bottom: 1.2vh;
	}

	.Receipt-footer {
		display: flex;
		justify-content: space-between;
	}

	.Receipt-status {
		font-size: 1.2vh;
		font-weight: 700;
		text-transform: uppercase;

		&.pending {
			color: $pending-yellow;
		}
		&.processed {
			color: $money-green;
		}
		&.failed{
			color: #dd2200;
		}
	}
}
