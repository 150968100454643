.Popup {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
}

.Popup .Popup-contents {
  box-sizing: border-box;
  max-height: 80%;
  min-height: 45%;
  width: 80%;
  background: white;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  padding: 5%;
  overflow: hidden;

  &-fullWidth {
    max-height: 100%;
    width: 100%;
    height: 100%;
    justify-content: center;
  }
}

@media only screen and (max-width: 600px) {
  .Popup .Popup-contents:not(.Popup-contents-fullWidth) {
    max-height: 85%;
    width: 90%;
  }
}
