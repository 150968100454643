
.UserSetting{
	padding: 2.4vh 2vh;
	border-bottom: 0.1vh solid #ccc;
}

.UserSetting .Error {
	padding-left: 0;
}

.UserSetting .UserSetting-name{
	font-size: 1.2vmax;
	font-weight: 800;
	color: #aaa;
	text-transform: uppercase;
}

.UserSetting .UserSetting-value{
	font-size: 1.6vmax;
	line-height: 3vmax;
	color: #444;
	font-weight: 800;
}
.UserSetting .USStatesSelect .USStatesSelect-select,
.UserSetting .USStatesSelect .USStatesSelect-value{
	text-align: left;
	text-align: -webkit-left;
	text-align-last: left;
	font-weight: 400;
	padding: 0.8vmin 1vmin;
}
.UserSetting .USStatesSelect{
	margin: 0;
}
.UserSetting .Input input{
	text-align: left;
}

@media only screen and (max-width:600px) {
	.UserSetting .UserSetting-name{
		font-size: 2.8vmin;
	}
	.UserSetting .UserSetting-value{
		font-size: 4vmin;
		line-height: 10vmin;
	}
	.UserSetting .Input input,
	.UserSetting .USStatesSelect{
		width: 62vw;
		display: inline-block;
		margin: 0;
	}
	.UserSetting .Input input,
	.UserSetting .USStatesSelect .USStatesSelect-select,
	.UserSetting .USStatesSelect .USStatesSelect-value{
		width: 62vw;
		font-size: 5vmin;
		padding: 0.4vmin 2vmin;
		line-height: 6vmin;
	}

	.UserSetting .USStatesSelect{
		margin: 0;
		line-height: 6vmin;
	}
	.UserSetting .USStatesSelect .USStatesSelect-select option{
		text-align: left;
	}
	.UserSetting .Button{
		float: right;
	}
}