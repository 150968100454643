@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0.01;
  }
  100% {
    opacity: 0;
    z-index: -10;
  }
}

@keyframes move {
  0% {
    transform: translateX(120%);
    opacity: 0;
  }
  20% {
    transform: translateX(120%);
    opacity: 0;
  }
  40% {
    transform: translateX(0);
    opacity: 1;
  }
  60% {
    transform: translateX(0);
    opacity: 1;
  }
  80% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-120%);
    opacity: 0;
  }
}
