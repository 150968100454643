.Receipts {
	position: relative;
	margin: 1.8vh 4vh;
}

.Receipts .Receipts-header{
	display: flex;
	align-items: center;
}

.Receipts .Receipts-back{
	position: absolute;
	left: -1.8vh;
}

.Receipts .Receipts-back .LeftCaret{
	color: #999;
}

.Receipts .Receipts-header-title{
	color: #444;
	font-size: 1.8vh;
}
