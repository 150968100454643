.FundingSourceDeletePopup {
  position: fixed;
  .Error {
    margin-bottom: 2vh;
    color: inherit;
  }

  .FundingSourceDeletePopup-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .FundingSourceDeletePopup-info {
    align-self: stretch;
    margin-bottom: 2.5vh;
    padding: 3vh 2.5vw;
    text-align: left;
    text-transform: uppercase;
    background-color: #e7e7e7;

    .FundingSourceDeletePopup-name {
      color: #7d7d7d;
      margin-bottom: 2.4vh;
    }

    .FundingSourceDeletePopup-status {
      color: #53cd7f;
      font-weight: 700;
    }
  }

  .FundingSourceDeletePopup-delete {
    margin-top: auto;
    .SecondaryButton {
      border-color: #dd5c56;
      color: #dd5c56;
      &:hover {
        background-color: #dd5c56;
        color: #fff;
      }
    }
  }

  .FundingSourceDeletePopup-cancel {
    margin-top: auto;
    .TextButton {
      font-size: 2vmin;
      font-weight: normal;
      color: #7d7d7d;
      @media only screen and (max-width: 600px) {
        font-size: 4vmin;
      }
    }
  }
}
