@import 'variables';

.TradingApproved {
  footer {
    margin-top: 4vh;
    margin-bottom: 2vh;
    font-size: 2vh;
    line-height: 1.8;
    text-align: center;
    a {
      color: inherit;
      cursor: pointer;
      &:hover { color: $rally-green; }
    }
  }
}
