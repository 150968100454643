.ValidationWarning{
	color: #dd2200;
	height: 3vmin;
	font-size: 1.8vmin;
}
.ValidationWarning span{
	display: inline-block;
	line-height: 4vmin;
}

@media only screen and (max-width:600px) {
	.ValidationWarning{
		height: 5vmin;
		font-size: 3.6vmin;
	}
}