
.SettingsBlock{
	width: 100%;
	border-bottom: 0.4vh solid #ccc;
	position: relative;
}

.SettingsBlock .SettingsBlock-header{
	padding: 2vh;
	display: inline-flex;
}

.SettingsBlock .SettingsBlock-title,
.SettingsBlock .SettingsBlock-edit,
.SettingsBlock .SettingsBlock-progressbar{
	display: flex;
	align-items: center;
	justify-content: center;
}

.SettingsBlock .SettingsBlock-title h2{
	font-size: 2.2vh;
	font-weight: 700;
	letter-spacing: 0.1vh;
}

.SettingsBlock .SettingsBlock-edit .Button{
	padding: 0;
	margin: 0 2.6vh;
	font-size: 1.8vh;
	font-weight: 600;
	color: #53cd7f;
}
.SettingsBlock .SettingsBlock-edit .Button .UpCaret svg{
	width: 2.6vh;
	height: 2.6vh;
	margin-bottom: 0.2vh;
}
.SettingsBlock .SettingsBlock-edit .Button:focus{
	outline: 0;
}

.SettingsBlock .SettingsBlock-progressbar{
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	margin: 0 8vh;
	font-weight: 600;
}

.SettingsBlock .SettingsBlock-progressbar.editing{
	display: none;
}

.SettingsBlock .SettingsBlock-progressbar .ProgressBar .ProgressBar-text{
	text-transform: uppercase;
}

.SettingsBlock .SettingsBlock-contents{
	display: block;
	width: 100%;
}

@media only screen and (max-width:600px) {
	.SettingsBlock .SettingsBlock-progressbar{
		display: none;
	}
}

