.Button {
	font-family: 'Muli', sans-serif;
	border-radius: 100vmin;
	border: none;
	font-size: 2.6vmin;
	font-weight: 400;
	color: white;
	background-color: #53cd7f;
	transition: background-color 0.2s;
	text-transform: none;
	letter-spacing: normal;
	margin: 1vmin 1.2vmin;
	padding: 2.6vmin 10vmin;
	position: relative;
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	justify-content: center;

	@media only screen and (max-width:600px) {
		font-size: 4vmin;
		margin: 2vmin 2.4vmin;
		padding: 4.2vmin 14vmin;
	}
	
	/* ----- Button states ----- */	
	&:active {
		b {
			opacity: .5;
		}
	}

	&:focus {
		outline: none;
	}
	&:disabled, &[disabled] {
		background-color: lightgrey;
		color: white;
		cursor: default;
	}
	&:hover:not([disabled]) {

	}

	/* ----- Button themes ----- */	
	
	&.light {
		color: darkgrey;
		background: none;
	}
	&.light:hover:not([disabled]) {
		color: #53cd7f;
		background: none;
	}
	&.light:disabled, &.light[disabled] {
		color: lightgrey;
	  background: none;
	}

	/* ----- Button arrows ----- */

	&.Button-right-arrow.Button-light:not([disabled]){
		color: #53cd7f;
	}
	&.Button-left-arrow, &.Button-right-arrow{
		padding: 2.1vmin 8vmin;
		letter-spacing: 0.2vmin;
		font-size: 2.3vmin;

		@media only screen and (max-width:600px) {
			padding: 3.6vmin 16vmin;
			letter-spacing: 0.3vmin;
			font-size: 5.6vmin;
		}
	}
	&.Button-right-arrow b{
		font-weight: 800;
	}
	&.Button-right-arrow::after, &.Button-left-arrow::before{
		font-size: 1.7vmin;
		margin-top: -0.36vmin;
		font-weight: 600;
		position: absolute;
		transform: scaleY(1.6) scaleX(0.8);

		@media only screen and (max-width:600px) {
			font-size: 4.4vmin;
			margin-top: -1vmin;
		}
	}
	&.Button-right-arrow::after{
		content: '>';
		right: 5.4vmin;

		@media only screen and (max-width:600px) {
			right: 6vmin;
		}
	}
	&.Button-left-arrow::before{
		content: '<';
		left: 5.4vmin;

		@media only screen and (max-width:600px) {
			left: 6vmin;
		}
	}

	/* ----- Button content ----- */	

	b {
		font-weight: 900;
	}
	span {
		display: inline;
	}
}
