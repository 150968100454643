.AuthenticationPages {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  background-color: #fbf5e7;
}

.AuthenticationPages .AuthenticationPages-actionButton {
  font-family: 'Muli', sans-serif;
  font-size: 2vmin;
  font-weight: 900;
  color: #28352e;
  transition: background-color 0.2s;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  margin: 0.5vmin 0;
  padding: 1.5vmin 0;
  position: relative;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  outline: none;
}

@media only screen and (max-width: 600px) {
  .AuthenticationPages.LoginSignup,
  .AuthenticationPages.PasswordReset {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: transparent;
    justify-content: space-between;
  }
}
