

.ContactPage .ContactPage-cta .Button {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
}

.ContactPage .AdminButton{
    margin-left: 0;
}

.menuContainer{
    margin: 0;
    padding: 20px;
}

.ContactPage .menuContainer h2, .ContactPage .menuContainer b {
    font-weight: 900;
}

.contactEmailContainer{
    margin-top: 30px;
    font-weight: 600;
    margin-bottom: 30px;
}

.contactEmailContainer p a{
    color: #000;
}

.contactEmailContainer p span{
    color: #45ca86;
}

.contactEmailContainer p a {
    color: #000;
}

.socialNetworkicons{
    margin-top: 30px;
    height: 35px;
}
.socialNetworkicons a{
    display: inline-block;
    width: 35px;
    height: 100%;
    margin-right: 30px;
    background-image: url('../../../../images/social_network_icons.png');
    background-repeat: no-repeat;
    background-size: auto 30px;
}
.socialNetworkicons a:first-of-type{
    background-position: 3px 0;
}
.socialNetworkicons a:nth-of-type(2){
    background-position: -69px 0;
}
.socialNetworkicons a:nth-of-type(3){
    background-position: -142px 0;
    margin-right: 0;
}