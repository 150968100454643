
.LeftCaret {
	transform: rotate(270deg);
	display: flex;
	align-items: center;
	justify-content: center;
	width: 0;
	height: 0;
}

.LeftCaret svg {
	width: 3.6vmax;
	height: 3.6vmax;
}
