.Statements {
  height: 100%;
}

.Statements-header {
  display: flex;
  align-items: center;
  padding: 2vh 0 0 2.2vh;

  .LeftCaret {
    margin-right: 1.8vh;
    svg { fill: #999; }
  }
}

.Statements-header-title{
  color: #444;
  font-size: 1.8vh;
}

.Statements-loader-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 0;
  bottom: 2vmin;
  left: 3.2vh;
  .DotsLoader-dot {
    width: 1.2vmin !important;
    height: 1.2vmin !important;
    margin: 0.6vmin !important;
    background-color: #ccc !important;
  }
}

.Statements-content {
  margin-top: 2vh;
}

.Statements-collapse {
  position: relative;
  padding: 2.8vh 4vh;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
  cursor: pointer;
  transform: translateY(-1px);
  &:first-child {
    transform: translateY(0);
    margin-top: 0px;
    border-top: none;
  }
  &.open .UpCaret {
    transform: translateY(-50%) rotate(0deg);
  }
  &.loading .Statements-collapse-desc {
    visibility: hidden;
  }
  &.disabled {
    cursor: default;
    .UpCaret {
      display: none;
    }
  }
  button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: none;
    background-color: transparent;
    outline: none;
    cursor: help;
  }
  .Info {
    display: inline-flex;
    width: 2vmin;
    height: 2vmin;
    margin-left: 1vmin;
    svg {
      fill: #444;
    }
  }
  .UpCaret {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    transition: transform .2s ease-in;
    svg {
      fill: #444;
    }
  }
}

.Statements-collapse-header {
  display: flex;
  align-items: center;
  margin-bottom: 1vmin;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.Statements-collapse-desc {
  font-family: serif;
  letter-spacing: 1px;
  font-size: 1.8vmin;
}

.Statements-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  li {
    border-bottom: 1px solid #ccc;
    &:last-child { border-bottom: none; }
  }
  a {
    display: block;
    padding: 2.8vh 4vh;
    font-weight: 600;
    color: #181818;
    background-color: #fcfcfc;
    transition: .2s ease-in;
    &:hover { background-color: #f5f5f5; }
  }
}

.Statements-popup {
  .Popup-contents {
    justify-content: center;
    min-height: 35%;
  }
  .Statements-popup-message {
    max-width: 600px;
    line-height: 1.8;
    margin-bottom: 4vh;
  }
  .SecondaryButton {
    font-size: 2vmin;
    padding: 2vmin 6vmin;
  }
}

@media only screen and (max-width:600px) {
  .Statements-loader-container {
    bottom: 2.8vmin;
    left: 3.2vh;
    .DotsLoader-dot {
      width: 2.2vmin !important;
      height: 2.2vmin !important;
      margin: 1.2vmin !important;
    }
  }
  .Statements-collapse-desc {
    font-size: 3.2vmin;
  }
  .Statements-collapse {
    .Info {
      width: 3.8vmin;
      height: 3.8vmin;
      margin-left: 2.4vmin;
    }
    .UpCaret svg {
      width: 7vmin;
      height: 7vmin;
    }
  }
  .Statements-popup {
    .Statements-popup-message {
      font-size: 3.6vmin;
      margin-bottom: 2vh;
    }
    .SecondaryButton {
      font-size: 3.5vmin;
      padding: 4vmin 12vmin;
    }
  }
}