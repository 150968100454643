
.AssetValueChart {
	width: 100%;
	height: 100%;
}
.AssetValueChart .TickerPrice{
	width: auto;
	height: 4vh;
	border-radius: 4vh;
	padding: 0 8px;
}
.AssetValueChart .TickerPrice-ticker {
	font-family: Muli;
	font-weight: 900;
}
.AssetValueChart .TickerPrice-price {
	font-family: Muli;
	font-weight: 700;
}
.AssetValueChart .TickerPrice .TickerPrice-content{
	font-size: 2.6vh;
}
.AssetValueChart .TickerPrice .TickerPrice-divider{
	margin: 0 0.6vh;
}

.AssetValueChart .AssetValueChart-legal {
  font-size: 1.8vmin;
  color: #fff;
  font-family: 'Gotham Book';
}

.AssetValueChart .AssetValueChart-title,
.AssetValueChart .AssetValueChart-pointInfo{
	min-height: 6.5vh;
	max-height: 6.5vh;
	margin-bottom: 0.1vh;
}
.AssetValueChart .AssetValueChart-legal.hidden,
.AssetValueChart .AssetValueChart-title.hidden,
.AssetValueChart .AssetValueChart-pointInfo.hidden {
	display: none;
}
.AssetValueChart .AssetValueChart-title {
	color: #444;
	font-family: 'Bebasneue', sans-serif;
	font-weight: 200;
	font-size: 14px;
}
.AssetValueChart .AssetValueChart-pointInfo {
	width: 100%;
	color: white;
	font-weight: 600;
	text-transform: uppercase;
	text-align: center;
	font-size: 16px;
	text-shadow: 0.2vh 0.2vh 0.3vh rgba(68,68,68,.5);
}
.AssetValueChart .AssetValueChart-pointInfo.hide {
	visibility: hidden;
}
.AssetValueChart .AssetValueChart-pointInfo.show {
	visibility: visible;
}

.AssetValueChart .AssetValueChart-pointInfo-source {
	color: rgba(255,255,255,0.8);
	font-size: 16px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin: 0 auto;

	line-height: 16px;
}
.AssetValueChart .AssetValueChart-pointInfo-value {
	font-family: Muli;
	font-weight: 700;
	line-height: 16px;
}
.AssetValueChart .AssetValueChart-pointInfo-since {
	font-size: 16px;
	line-height: 16px;
}

.AssetValueChart .AssetValueChart-chart {
	min-height: 25vmin;
	position: relative;
	width: 70%;
	max-width: 60vmin;
	margin-top: 15px;
}

.AssetValueChart.special-access .AssetValueChart-chart {
	max-width: 65vmin;
}

.AssetValueChart .AssetValueChart-chart img {
	width: 100%;
	height: 100%;
}
.AssetValueChart .AssetValueChart-intervals {
	display: flex;
	justify-content: space-around;
	margin-top: 10px;
}
.AssetValueChart .AssetValueChart-interval {
	font-weight: 500;
	font-size: 2.2vh;
	padding: 0.8vh 1vh;
	color: white;
	cursor: pointer;
	min-width: 45px;
	text-align: center;
	margin: 0 5px;
}
.AssetValueChart .AssetValueChart-interval:hover {
	background: #4e4e4b;
	font-weight: 700;
}
.AssetValueChart .AssetValueChart-interval.active {
	background: #3e3e3b;
	min-width: 45px;
	font-weight: 700;
}
.AssetValueChart .AssetValueChart-hr {
	margin-top: 1vh;
	height: 1px;
	width: 44vh;
	color: rgba(0,0,0,0.4);
}
.AssetValueChart .AssetValueChart-summary {
	display: flex;
	justify-content: space-around;
	width: 50vh;
	margin: 0.5vh 0 0 0;
}
.AssetValueChart .AssetValueChart-summary-value {
	max-width: 50%;
	min-width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}
.AssetValueChart .AssetValueChart-summary-value .title {
	color: rgba(0,0,0,0.4);
	font-size: 1.5vh;
	font-weight: 800;
	text-align: center;
}
.AssetValueChart .AssetValueChart-summary-value .value {
	font-family: Muli;
	font-weight: 900;
	font-size: 3.6vh;
	color: rgba(0,0,0,0.6);
	vertical-align: text-top;
	display: flex;
}
.AssetValueChart .AssetValueChart-summary-value .pct {
	font-size: 3.6vh;
	font-weight: 700;
}

.AssetValueChart .NotifyMeButton.unselected:not(:hover):not([disabled]) {
	background-color: #444;
}

.AssetValueChart-placeholder {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	text-align: center;
	margin: 0;
	text-transform: uppercase;
}

.AssetValueChart .NotifyMeButton.unselected:not(:hover):not([disabled]) span {
	color: #53cd7f;
}

@media only screen and (max-width:600px) {
	.AssetValueChart .AssetValueChart-summary-value .pct {
		font-size: 2.6vh;
		font-weight: 700;
	}
	.AssetValueChart .AssetValueChart-interval {
		font-weight: 500;
		font-size: 1.8vh;
		padding: 0.4vh 0.4vh;
		color: white;
		cursor: pointer;
		min-width: 35px;
		text-align: center;
		margin: 0 8px;
	}
	.AssetValueChart .TickerPrice {
		width: auto;
		padding-right: 2vmin;
		padding-left: 2vmin;
	}
  .AssetValueChart .AssetValueChart-legal {
    font-size: 2.8vmin;
  }
	.AssetValueChart .AssetValueChart-chart {
		width: 75vmin;
		height: 35vmin;
		max-width: inherit;
	}
	.AssetValueChart .AssetValueChart-pointInfo-source {
		width: 76%;
	}
	.AssetValueChart .AssetValueChart-summary-value .value {
		font-size: 2.6vh;
	}

	.AssetValueChart .AssetValueChart-summary {
		border-top: 1px solid #323232;
		padding-top: 10px;
		justify-content: center;
	}
	.AssetValueChart .AssetValueChart-summary-value {
		max-width: 48%;
		min-width: 48%;
	}

}

