.DocusignCallback {
  position: absolute;

  width: 100%;
  top: 45vh;

  display: inline-flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}