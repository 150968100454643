.SelectDots {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	margin: 4vmin;
	height: 20vmin;
}

.SelectDots .SelectDots-dot {
	display: block;
}

@media only screen and (max-device-width:600px) {
	.SelectDots {
		height: 28vmin;
	}
}



