.PrivacyPage{
  margin: 0 200px 4vmin;
}

@media only screen and (max-width: 860px) {
  .PrivacyPage{
    margin: 0 120px 4vmin;
  }
}

@media only screen and (max-width:600px) {
  .PrivacyPage{
    margin: 0 14vmin 4vmin;
  }
}
