
.BuySharesButton {
  display: flex;
  align-items: flex-start;
	justify-content: center;
}

.BuySharesButton .button-divider {
  color: #333;
  font-weight: 200;
  margin: 0 5px;
}

@media only screen and (max-width: 600px){
	.BuySharesButton .Button {
		padding-left: 6vh;
		padding-right: 6vh;
	}
}