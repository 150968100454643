@import 'variables';

.Transfer .Transfer-input {
  position: relative;
  padding-bottom: 5vh;
  .Input {
    margin-bottom: 0;
    input {
      text-align: left;
      font-size: 5.4vh;
      font-weight: 400;
      letter-spacing: 0vh;
      padding: 0 0.4vw 0.4vh 0;
      margin-bottom: 0;
    }
  }
}
.Transfer .Transfer-input .Input input::placeholder {
  color: #ccc;
}

.Transfer {
  .Transfer-bankinfo,
  .Transfer-error {
    text-transform: uppercase;
    color: #666;
    font-weight: 600;
    font-size: 1.6vh;
  }
  .Transfer-explanation {
    font-size: 1.8vh;
    padding: 30px 10px 0;
  }
}

.Transfer .Transfer-error {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;

  margin-top: 4px;
  font-weight: bold;
  color: $rally-red;
}

.Transfer .Button .Button-contents {
  white-space: nowrap;
}

@media only screen and (max-width:600px) {
  .Transfer-explanation {
    font-size: 2vh;
  }
}