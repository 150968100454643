
.Info{
	display: flex;
	align-items: center;
	justify-content: center;
}
.Info svg{
	fill: currentColor;
	width: 3vmax;
	height: 3vmax;
}