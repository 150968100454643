
.ContentHeader{
	border-bottom: 1px solid #ccc;
	box-sizing: border-box;
}
.ContentHeader-border{
	width: 100%;
	height: 0.7vh;
	background-color: #666;
}
.ContentHeader-title{
	/*text-align: center;*/
	margin: 0 2vh;
	font-size: 1.8vh;
	letter-spacing: 0.2vh;
	line-height: 5vh;
	font-weight: 600;
	color: #888;
}


@media only screen and (max-height:500px) {
	.ContentHeader{
		border-width: 0.5px;
	}
	.ContentHeader-border{
		height: 3.5px;
	}
	.ContentHeader-title{
		margin: 0 10px;
		font-size: 9px;
		letter-spacing: 1px;
		line-height: 25px;
	}
}

@media only screen and (max-width:1000px) {
	.ContentHeader-title{
		text-align: center;
	}
}

@media only screen and (max-width:750px) {
	.ContentHeader{
		display: none;
	}
}

@media only screen and (max-width:600px) {
	.ContentHeader{
		display: none;
	}
/*	.ContentHeader-title{
		font-size: 3vw;
		line-height: 10vw;
		letter-spacing: 0.4vw;
	}*/
}