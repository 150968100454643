
.AssetTimeline {
	width: 100%;
	height: 100%;
}

.AssetTimeline .AssetDetailsPage-nextslide.previous{
	top: 0;
}
.AssetTimeline .AssetDetailsPage-nextslide .TextButton{
	color: #444;
	transform: scaleX(2) rotate(180deg);
}

.AssetTimeline .TickerTitle {
	margin-bottom: 2vh;
}
.AssetTimeline .TickerTitle-ticker {
	color: #888;
}

.AssetTimeline .AssetTimeline-timeline {
	width: 100%;
	height: 60%;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.AssetTimeline .AssetTimeline-timeline-container {
	padding: 5vh 70px 2vh;
	box-sizing: border-box;
	overflow: scroll;
}
.AssetTimeline .AssetTimeline-timeline-container::-webkit-scrollbar {
	display: block;
}

.AssetTimeline .AssetTimeline-topfog,
.AssetTimeline .AssetTimeline-bottomfog {
	position: absolute;
	height: 5vh;
	width: 90%;
	z-index: 13;
	opacity: 0.7;
}
.AssetTimeline .AssetTimeline-topfog {
	top: 0;

	background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0.81) 59%, rgba(255,255,255,0) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,1)), color-stop(59%, rgba(255,255,255,0.81)), color-stop(100%, rgba(255,255,255,0)));
	background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0.81) 59%, rgba(255,255,255,0) 100%);
	background: -o-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0.81) 59%, rgba(255,255,255,0) 100%);
	background: -ms-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0.81) 59%, rgba(255,255,255,0) 100%);
	background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,0.81) 59%, rgba(255,255,255,0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0 );
}
.AssetTimeline .AssetTimeline-bottomfog {
	bottom: 0;

	background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.81) 26%, rgba(255,255,255,0.95) 56%, rgba(255,255,255,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,0)), color-stop(26%, rgba(255,255,255,0.81)), color-stop(56%, rgba(255,255,255,0.95)), color-stop(100%, rgba(255,255,255,1)));
	background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.81) 26%, rgba(255,255,255,0.95) 56%, rgba(255,255,255,1) 100%);
	background: -o-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.81) 26%, rgba(255,255,255,0.95) 56%, rgba(255,255,255,1) 100%);
	background: linear-gradient(to top, rgba(255,255,255,1) 0%, rgba(255,255,255,0.81) 59%, rgba(255,255,255,0) 100%);
	background: -ms-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.81) 26%, rgba(255,255,255,0.95) 56%, rgba(255,255,255,1) 100%);
}

.AssetTimeline .AssetTimeline-timeline-item {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	/*width: 52vh;*/
	color: #424446;
	position: relative;
}
.AssetTimeline .AssetTimeline-timeline-item-date {
	font-family: 'Cardo', serif;
	font-style: italic;
	width: 9vh;
	font-size: 1.6vh;
	margin: 0.9vh 1.2vh 0 0;
	font-weight: 800;
	flex-shrink: 0;
}
.AssetTimeline .Circle {
	position: absolute;
	top: 0;
	/*left: 8vh;*/
	left: 8.2vh;
	background-color: white;
	z-index: 12;
}
.AssetTimeline .AssetTimeline-timeline-item-text {
	line-height: 100%;
	font-family: Georgia, serif;
	position: relative;
	border-left: 0.2vh solid #555a5c;
	padding-left: 3.2vh;
	padding-bottom: 24px;
	/*width: 40vh;*/
	font-size: 1.9vh;
	line-height: 2.5vh;
}

.AssetTimeline .NotifyMeButton.unselected:not(:hover):not([disabled]) span{
	color: #53cd7f;
}

/*.AssetTimeline .AssetDetailsPage-bidask-ask .SecondaryButton{
	background-color: #c04c47;
}*/

@media only screen and (max-width:600px) {
	.AssetTimeline .AssetTimeline-topfog,
	.AssetTimeline .AssetTimeline-bottomfog {
		width: 90vmin;
	}
	.AssetTimeline .AssetTimeline-bottomfog {
		border-bottom-width: 0.4vmin;
	}
	/*.AssetTimeline .AssetTimeline-timeline-item {
		width: 82vmin;
	}*/
	.AssetTimeline .AssetTimeline-timeline-container {
		padding: 5vh 9vmin 0;
	}

	.AssetTimeline .AssetTimeline-timeline-item-date {
		width: 15vmin;
		margin: 1.6vmin 2.5vmin 0 0;
		font-size: 3vmin;
	}
	.AssetTimeline .Circle {
		width: 15vmin;
		left: 17.5vmin;
		left: calc(17.5vmin - (2.8vh / 2) - 0.6vh );
	}
	.AssetTimeline .AssetTimeline-timeline-item-text {
		padding-left: 5vmin;
		padding-bottom: 16px;
		width: 60vmin;
		font-size: 3.6vmin;
	}

}

