.NumberInput {
  box-sizing: border-box;
  position: relative;
  display: inline-flex;
  align-items: baseline;
  width: 16vmin;
  padding-right: 4vmin;
  padding-bottom: 1px;
  padding-top: 1px;
  margin: 0 .6vmin;
  text-align: left;
  border-bottom: .1vmin solid #a9a9a9;
  transition: border .2s linear;
  &.hiddenButtons {
    padding-right: 0;
  }
  .NumberInput-prefix {
    font-size: 2.6vmin;
  }
  .NumberInput-input {
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    padding: 0 1vmin;
    font-size: 3.2vmin;
    color: #444;
    caret-color: #d3d3d3;
    border: none;
    outline: none;
  }

  .NumberInput-btn {
    display: inline-block;
    position: absolute;
    right: 0;
    width: 4vmin;
    height: 50%;
    padding: 0;
    border: none;
    overflow: hidden;
    border: 1px solid #a9a9a9;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: background-color .15s linear;
    i {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      line-height: 100%;
      font-size: 2.4vmin;
      font-style: normal;
      pointer-events: none;
    }
    &:active {
      background-color: #ccc;
    }
  }
  // Button down
  .NumberInput-btn:last-child {
    bottom: 0;
    border-top: none;
    border-bottom: none;
  }
}

@media only screen and (max-width: 600px) {
  .NumberInput {
    width: 28vmin;
    margin: 0 1.2vmin;
    padding-right: 6vmin;
    &.hiddenButtons {
      padding-right: 0;
    }
    .NumberInput-prefix {
      font-size: 5vmin;
    }
    .NumberInput-input {
      padding: 0 2vmin;
      font-size: 6.4vmin;
    }
    .NumberInput-btn {
      width: 6vmin;
      i {
        font-size: 4vmin;
      }
    }
  }
}
