
.TickerPrice{
	background-color: #333;
	border-radius: 2vmax;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 12vmax;
	height: 2vmax;
}
.TickerPrice .TickerPrice-content{
	display: inline-block;
	font-size: 1.4vmax;
	color: #666;
}
.TickerPrice .TickerPrice-ticker{
	font-family: 'Muli', sans-serif;
	letter-spacing: -0.1vmax;
	color: white;
	font-weight: 900;
}
.TickerPrice .TickerPrice-divider{
	margin: 0 0.2vmax;
}
.TickerPrice .TickerPrice-price{
	font-weight: 900;
}