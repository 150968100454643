
@import 'variables';
@import 'placeholders';

.Chat {
	@extend %flex-center;
	@extend %asset-page-content;
  position: relative;
	text-align: center;
}
.Chat.special-access {
	height: 100%;
	width: 100%;
}

.Chat .Chat-contents {
	margin: 0 16vh;
	font-size: 2.6vh;
}

.Chat .Chat-contents p {
	margin-bottom: 8vh;
}