@import 'variables';
@import 'keyframes';

.DocumentUpload {
	.ImageUpload {
		animation: fade-in .4s linear;
	}
	.ImageUpload-img {
		height: 8vh;
		width: auto;
		animation: fade-in .4s linear;
	}
}
.DocumentUpload .DocumentUpload-wrapper {
	margin: 0 28vw 10vmin 28vw;
}
.DocumentUpload .DocumentUploadForm-container-contents{
	min-height: 30vh;
}

.DocumentUpload .DocumentUpload-title {
	font-size: 3.4vmin;
}
.DocumentUpload .DocumentUpload-description {
	display: block;
	font-size: 2.2vh;
	line-height: 4.2vh;
	color: #444;
}

.DocumentUpload .DocumentUpload-description-failure {
	display: block;
	font-size: 2vh;
	font-weight: 700;
	color: #d20;
}

.DocumentUpload-type-select {
	display: flex;
	flex-direction: column;
	width: 75vw;
	max-width: 440px;
	min-height: 26vh;
	margin: 2vmin auto;
	animation: fade-in .5s linear;
}

.DocumentUpload-type-select button {
	margin-bottom: 2vh;
	padding: 2vh;
	font-size: 2.2vh;
	color: #444;
	border: 1px solid #ccc;
	background-color: transparent;
	cursor: pointer;
	outline: none;
	transition: .15s ease-in;
	&:last-child {
		margin-bottom: 0;
	}
	&:hover {
		background-color: $rally-green;
		border-color: $rally-green;
		color: #fff;
	}
}

.DocumentUpload .DocumentUpload-dropzone.hidden,
.DocumentUpload .DocumentUpload-spinner.hidden,
.DocumentUpload .DocumentUpload-success.hidden {
	display: none;
}

.DocumentUpload .DocumentUpload-spinner-image{
	width: 14vmin;
	height: 14vmin;
	margin: 6.6vh auto 2vh auto;
	background-color: #e6e6e6;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 2vmin;
}
.DocumentUpload .DocumentUpload-spinner-image .Spinner{
	width: 9vmin;
	height: 9vmin;
}
.DocumentUpload .DocumentUpload-spinner-image .Spinner:after {
  background: #e6e6e6;
  width: 92%;
	height: 92%;
}


@media only screen and (max-width: 1000px) {
	.DocumentUpload .DocumentUpload-wrapper {
		margin: 0 20vw 18vmin 20vw;
	}
}

@media only screen and (max-width:750px) {
	.DocumentUpload .DocumentUpload-wrapper {
		margin: 0 14vw 18vmin 14vw;
	}
}

@media only screen and (max-width:600px) {
	.DocumentUpload .DocumentUpload-wrapper {
		margin: 0 6vw;
	}
	.DocumentUpload .DocumentUpload-title {
		font-size: 6vmin;
	}
	.DocumentUpload .DocumentUpload-description {
		font-size: 4.2vmin;
		line-height: 7.2vmin;
	}
}