.PasswordReset-inputs p {
  max-width: 31vmin;
  margin-left: auto;
  margin-right: auto;
  color: #767675;
}

.PasswordReset-message {
  max-width: 35vmin;
  text-align: center;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.PasswordReset .AuthenticationPages-actionButton {
  border: none;
  background-color: rgb(226, 201, 141);
}

@media only screen and (max-width: 600px) {
  .PasswordReset-inputs p {
    max-width: 80vw;
  }

  .PasswordReset-message {
    max-width: 100%;
    text-align: center;
  }

  .PasswordReset-message h3 {
    width: 80vw;
    color: #fff;
  }

  .PasswordReset .AuthenticationPages-actionButton {
    background-color: #fa864a;
    font-size: 4vmin;
    width: 86vmin;
    margin-top: 4vmin;
    padding: 5.4vmin 0 5.9vmin 0;
    text-transform: uppercase;
    border-radius: 100vmin;
    color: white;
  }
}
