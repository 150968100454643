@import 'variables';
@import 'mixins';
@import 'keyframes';

.NavigationMenu {
	height: 90vh;
	width: 22vw;
	padding: 0 0.4vw;
	border-top: 0.1vh solid #ddd;
	box-sizing: border-box;
	overflow-y: auto;
}
.NavigationMenu.special-access {
	display: none;
}

.NavigationMenu-list {
	margin: 0;
	padding: 2vh 0 0 0;
	list-style: none;
	&.nested {
		width: 100%;
		margin: 0 0 -2vmin;
		padding: 1vh 0 0;
	}
}

.NavigationMenu-item {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin: 4.2vmin 0;
	font-size: 1.1vw;
	font-weight: 600;
	color: #666;
	text-transform: uppercase;
	white-space: nowrap;
	&.nested {
		height: 46px;
		margin: 0;
		padding: 0;
		font-size: 0.9vw;
		.NavigationMenu-link {
			&::after { display: none; }
			padding-top: 0;
			padding-bottom: 0;
		}
    .NavigationMenu-icon {
			position: relative;
      width: 20px;
    	max-height: 32px;
      margin: 0;
      margin-right: 16px;
      &.Cars { width: 22px; }
    }
	}
	&.mobile-only {
		@media only screen and (min-width:600px) { display: none; }
		@media only screen and (max-width:600px) { display: flex; }
	}

	&.desktop-only {
		@media only screen and (min-width:600px) { display: flex; }
		@media only screen and (max-width:600px) { display: none; }	
	}
}

.NavigationMenu-item-chevron {
	width: 8px;
	height: auto;
	margin-left: 6px;
	fill: #666 !important;
	transition: transform .2s linear;
}

.NavigationMenu-link {
	position: relative;
	width: 100%;
	display: flex;
	align-items: center;
	padding: 8px 1.6vw;
	color: inherit;
	/* underline: */
	&::after {
		position: absolute;
		left: 1.6vw;
		bottom: 0;
		width: 6.4vw;
		height: 1px;
		background-color: #ccc;
		content: '';
	}
	/* notification dot indicator: */
	&.notification {
		&::before {
			@include dot-indicator;
			position: absolute;
			top: 50%;
			margin-top: -7px;
			z-index: 1;	
		}	
		.NavigationMenu-icon { opacity: 0; }
	}

	&.active {
		color: #45ca86;
		&::after {
			background-color: #45ca86;
			height: 2px;
		}
	}
}

.NavigationMenu-icon {
	width: 15px;
	height: auto;
	margin-right: 8px;
	transition: opacity .2s ease-in;
	&.Cars {
		width: 18px;
	}
}

@media only screen and (max-width:1000px) and (min-width:601px) {
	.NavigationMenu{
		width: 6vw;
	}
	.NavigationMenu-label {
		display: none;
	}	
	.NavigationMenu-item {
		&.nested {
			height: 42px;
			.NavigationMenu-icon {
				margin: 0 auto;
				width: 16px;
				&.Cars { width: 18px; }
			}
		}
	}
	.NavigationMenu-icon {
		margin: 0 auto;
	}
	.NavigationMenu-link {
		padding-left: 0;
		padding-right: 0;
		&::after {
			width: 18px;
			left: 50%;
			margin-left: -9px;
		}
		&::before {
			left: 50%;
			margin-left: -7px;
		}
		.NavigationMenu-item-chevron {
			width: 14px;
			margin: auto;
		}
	}
}

@media only screen and (max-width:600px) {
	.NavigationMenu {
		position: fixed;
		left: 0;
		top: 7%;
		height: 0;
		width: 100%;
		opacity: 0.6;
		visibility: hidden;
		overflow: hidden;
		padding-top: 5vh;
		background-color: rgba(16,16,16, .9);
		transition: 0.2s;
		z-index: 20;
		&.mobile-opened {
			visibility: visible;
			height: 95vh;
			opacity: 1;
		}
	}
	.NavigationMenu.special-access {
		display: block;
	}
	.NavigationMenu-item {
		justify-content: center;
		margin: 6vh 0;
		font-size: 5vmin;
		color: #fff;
		font-weight: 700;
	}
	.NavigationMenu-link {
		width: auto;
		&::after, &.notification::before { display: none; }
	}
	.NavigationMenu-icon {
		display: none;
	}
}
