@import 'variables';

.DotsLoader {
  display: inline-flex;
}

.DotsLoader-dot {
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: $money-green;
  animation-name: pulse;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  &:nth-child(2) { animation-delay: .12s }
  &:nth-child(3) { animation-delay: .24s }
}

@keyframes pulse {
  0% { 
    opacity: .15;
    transform: scale(1); 
  }
  44% { 
    opacity: 1; 
    transform: scale(1.2); 
  }
  88% { 
    opacity: .15; 
    transform: scale(1.1); 
  }
  100% {
    opacity: .15;
    transform: scale(1);
  } 
}