
.OnboardingIntro {
	
}

.OnboardingIntro .OnboardingIntro-img {
	height: 8vh;
}

.OnboardingIntro .Button {
	margin-top: 4vh;
}