@import 'variables';


.AssetList{
	position: relative;
	height: $content-height;
	float: left;
	overflow-y: scroll;
	-ms-overflow-style:none;
	border-right: 1px solid #ddd;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	display: inline-block;
	vertical-align: top;
	width: 32%;
	background-color: #fff;

  &-search-result {
    text-transform: uppercase;
    text-align: center;
    color: #999;
    font-size: 2.3vw;
    letter-spacing: .5px;
    margin-bottom: 2.5vw;
    @media only screen and (min-width:750px) {
      border-bottom: 1px solid #ccc;
      color: #aaa;
      font-size: 1.3vh;
      padding: .8vh 0;
      margin-bottom: 0;
    }
  }

  &-search-list {
    @media only screen and (max-width:600px) {
      overflow-y: scroll;
      height: 90%;
      padding: 0 3vw;
      .AssetItem {
        border-bottom: 1px solid #333;
      }
      .AssetItem-info-status, .TickerPrice-ticker {
        color: #878787;
      }
      .AssetItem-info-name {
        color: #fff;
        font-size: 7.2vw;
        line-height: 7.2vw;
      }
      .AssetItem-info-status {
        font-size: 2.4vw;
      }
      .AssetItem .TickerPrice .TickerPrice-content {
        padding: 0;
      }
      .AssetItem .TickerPrice .TickerPrice-divider {
        margin: 0 2vw;
        vertical-align: top;
        font-size: 3.6vw;
      }
      .TickerPrice-ticker {
        font-weight: 700;
        font-size: 3.6vw;
        letter-spacing: .05vw !important;
      }
      // reseting inline style
      .TickerPrice-price {
        color: #878787 !important;
        font-weight: normal;
        font-size: 3.6vw;
      }
      .AssetItem, .TickerPrice {
        background: none !important;
      }
      .AssetItem-activebar {
        visibility: hidden !important;
      }
    } 
  }
}

.AssetList-items {
	position: relative;
	height: auto;
	&::before {
		display: none;
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		content: '';
		background-color: rgba(255, 255, 255, .7);
		z-index: 11;
	}
	&.loading::before {
		display: block;
	}
}

.AssetList::-webkit-scrollbar {
	width: 0px;
	background: transparent; /* make scrollbar transparent */
}

@media only screen and (max-width:750px) {
	.AssetList{
		position: absolute;
		display: none;
		left: 0;
		top: 0;
		width: 100%;
		z-index: 11;
	}
}

@media only screen and (max-width:600px) {
	.AssetList{
		height: calc(100% - 7%); /* mobile Header height is 7% */
		&.mobile-visible {
			display: block;
		}
		&.mobile-hidden { 
			display: none;
		}
	}
}
