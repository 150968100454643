.LoginForm .LoginForm-inputs {
  position: relative;
}
.LoginForm .LoginForm-memory {
  display: flex;
  justify-content: space-between;
  margin: 2.4vmin 0 1vmin;
  font-size: 1.5vmin;
}
.LoginForm .LoginForm-remember,
.LoginForm .LoginForm-forget {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
}

.LoginForm .LoginForm-forget {
  text-decoration: underline;
}

.LoginForm .LoginForm-remember-text {
  margin-left: 1vmin;
}

.LoginForm .LoginForm-remember .Checkbox-input {
  outline: none;
}

.LoginForm .LoginForm-remember .Checkbox-input:checked {
  background-color: #28352e;
  border-color: #28352e;
}
.LoginForm .LoginForm-forget a {
  color: #181818;
}
.LoginForm .LoginForm-forget a:hover {
  color: #53cd7f;
}

.LoginForm .LoginForm-signup {
  margin-top: 3.2vh;
}
.LoginForm .LoginForm-signup a {
  font-weight: 600;
}

.LoginForm .AuthenticationPages-actionButton {
  border: none;
  background-color: rgb(226, 201, 141);
}

@media only screen and (max-width: 600px) {
  .LoginForm .LoginForm-memory {
    display: inline-block;
    position: absolute;
    top: 21.3vmin;
    right: 4vmin;
    margin: 0;
    text-transform: uppercase;
  }
  .LoginForm .LoginForm-forget a {
    color: #ccc;
    font-size: 3.2vmin;
  }
  .LoginForm .LoginForm-remember-text {
    margin-left: 2vmin;
  }
  .LoginForm .LoginForm-signup {
    color: #676767;
    font-size: 4vmin;
    margin-top: 18vmin;
  }
  .LoginForm .LoginForm-signup a {
    color: #fff;
  }

  .LoginForm .AuthenticationPages-actionButton {
    background-color: #fa864a;
    font-size: 4vmin;
    width: 86vmin;
    margin-top: 4vmin;
    padding: 5.4vmin 0 5.9vmin 0;
    text-transform: uppercase;
    border-radius: 100vmin;
    color: white;
  }
}
