@import 'variables';

.CancelOrderPopup {
  .Popup-contents {
    padding: 4vmin 4vmin 2vmin;
    min-height: inherit;
  }

  .CancelOrderPopup-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      line-height: 1.8;
      margin-bottom: 2vmin;
    }
  }

  .CancelOrderPopup-cancel {
    margin: auto 0 1.6vmin;
    padding: 2.6vmin 7vmin;
    border-color: $rally-red;
    font-size: 2.2vmin;
    color: $rally-red;
    &:hover {
      background-color: $rally-red;
      color: white;
    }
  }

  .CancelOrderPopup-back {
    margin-top: auto;
    color: #656565;
    font-size: 2.2vmin;
    font-weight: 500;
    text-transform: uppercase;
  }
}

@media only screen and (max-width:600px) {
  .CancelOrderPopup {
    .Popup-contents {
      padding: 6vmin 6vmin 4vmin;
    }
    .CancelOrderPopup-content p {
      margin-top: 0;
    }
    .CancelOrderPopup-cancel {
      font-size: 4vmin;
      padding: 4vmin 10vmin;
      margin-top: 2vmin;
    }
    .CancelOrderPopup-back {
      font-size: 3.4vmin;
      margin: 0;
    }
  }
}