.Preloader {
	background-color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 9999;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
}

.Preloader-box {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 138px;
	height: 32px;
	position: relative;
	overflow: hidden;
}

.Preloader-box-top {
	background-color: #323232;
	width: 97%;
	height: 13px;
	animation: move 2s ease-in-out both infinite;
}

.Preloader-box-bottom {
	display: flex;
	justify-content: center;
	width: 100%;
	height: 13px;
}

.Preloader-box-bottom-left {
	background-color: #F99041;
	width: 35%;
	height: 13px;
	animation: move 2s ease-in-out both infinite;
	animation-delay: .1s;
	margin-right: 12px;
}

.Preloader-box-bottom-right {
	background-color: #90C7E8;
	width: 35%;
	height: 13px;
	animation: move 2s ease-in-out both infinite;
	animation-delay: .2s;
}

.Preloader-hidden {
	opacity: 0;
}