
.Cross{
	display: flex;
	align-items: center;
	justify-content: center;
}
.Cross svg{
	fill: currentColor;
	width: 3vmax;
	height: 3vmax;
}