@import 'variables';
@import 'placeholders';

.PlaceBid {
  @extend %flex-center;
	@extend %asset-page-content;
  position: relative;
	text-align: center;
	background: #222;
	color: white;
  .Closebutton {
    top: 4vh;
    right: 1vh;
    margin: 0;
  }
  &.post-only .Closebutton {
    top: 4vh;
  }

	.PlaceBid-wrapper {
    padding: 3.4vh 5vh 0;
    margin: 0;
    position: absolute;
    bottom: 0;
    height: 75.2vh;
    width: 100%;
    box-sizing: border-box;
		overflow: hidden;

		&.PassportView {
			margin: 0;
			background: white;
      color: #222222;
      position: absolute;
			height: 100%;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 2;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			
			& > div {
				padding: 9vmin 5vmin;
			}
		}
	}
	.PlaceBidForm-container-contents{
		min-height: 30vh;
	}
	p {
		font-size: 2.2vh;
		line-height: 4.4vh;
	}
	small {
		display: inline-block;
		line-height: 3.4vh;
	}
	h2 {
		font-size: 2.6vh;
	}


	.PlaceBid-fields{
		@extend %flex-center;
		display: flex;
		color: $grey;
	}
	.PlaceBid-field{
		height: 12vmin;
		width: 19vmin;
		display: inline-block;
		.PlaceBid-field-label,
		.PlaceBid-field-content{
			@extend %flex-center;
			height: 50%;
		}
		.PlaceBid-field-label{
			text-transform: uppercase;
			font-weight: 700;
      font-family: 'Gotham Bold', sans-serif;
			font-size: 2vh;
			margin: 0 10%;
		}
		.PlaceBid-field-content{
			width: 100%;
			position: relative;
			.NumberInput {
				width: 100%;
				border: 1px solid #C4C4C4;
				background-color: transparent;
        box-sizing: border-box;
        padding-top: 0;
        padding-bottom: 0;

				.NumberInput-input {
					color: white;
					font-weight: 700;
					text-align: center;
          font-family: 'Gotham Bold', sans-serif;
					background-color: transparent;
          padding: .5vh;
          font-size: 2.6vh;
				}
				.NumberInput-btn {
					border: 1px solid $placebid-lines;
					color: white;
					border-right: none;
					background-color: #434343;
					&:active {
						background-color: #9e9e9e;
					}
				}
				.NumberInput-prefix {
          font-family: 'Gotham Book', sans-serif;
					padding-left: 2px;
				}
			}
		}
	}

	.Input,
	.USStatesSelect{
		width: 100%;
		margin-bottom: 4vh;
	}
	.Input input,
	.USStatesSelect .USStatesSelect-select,
	.USStatesSelect .USStatesSelect-value{
		font-size: 3vh;
	}

	.BackNextButtons .TextButton .Button.Button-right-arrow {
		padding-left: 1.6vh;
	}
	.BackNextButtons .TextButton .Button.Button-left-arrow {
		padding-right: 1.6vh;
	}

	.Popup {
		color: $text-black;
	}

}

@media only screen and (max-width:600px) {
	.PlaceBid {
		height: 93%;
		position: fixed;
    top: 7%;
    overflow: scroll;
    
    &.post-only .Closebutton{
      top: 2.5vh;
    }

    .Closebutton {
      width: 5vh;
      height: 5vh;
      margin: 1vh;
      top: 3vh;
    }

		.PlaceBid-wrapper {
      padding: 0 3.4vh 0 3.4vh;
		}
		p {
			font-size: 4.2vmin;
			line-height: 7.6vmin;
		}
		h2 {
			font-size: 6vmin;
		}

		.PlaceBid-field{
			height: 21vmin;
			width: 33vmin;
			.PlaceBid-field-label{
				font-size: 2.7vmin;
			}
			.PlaceBid-field-content{
				.NumberInput {
					.NumberInput-input {
						font-size: 5vmin;
						height: 4.6vh;
            font-family: 'Gotham Bold', sans-serif;
            font-size: 2.2vh;
					}
					.NumberInput-prefix {
            font-family: 'Gotham Book', sans-serif;
            font-size: 2.2vh;
            margin-left: .5vh;
					}
				}
			}
		}

		.Input,
		.USStatesSelect{
			margin-bottom: 5vmin;
		}
		.Input input,
		.USStatesSelect .USStatesSelect-select,
		.USStatesSelect .USStatesSelect-value{
			font-size: 5.8vmin;
			padding: 1.4vmin 2vmin;
		}
		.TextButton{
			font-size: 4.4vmin;
		}
	}
}
