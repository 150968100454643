.AssetVisuals {
	width: 100%;
	height: 100%;
	background-size: cover !important;
	background-position: center !important;
}

.AssetVisuals .AssetVisuals-gradient {
	width: 100%;
	height: 100%;
	background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(29,29,29,1) 66%, rgba(29,29,29,1) 100%);
}

.AssetVisuals .AssetVisuals-description {
	/*width: 52vh;*/
	margin: 2vh 70px;
	text-align: center;
	color: #868686;
	font-family: Georgia, serif;
	font-size: 1.9vh;
	line-height: 3.8vh;
	/*margin: 2vh;*/
}

.AssetVisuals .TextButton {
	font-family: Muli;
	font-weight: 900;
}

@media only screen and (max-width:600px) {
	.AssetVisuals .AssetVisuals-description {
		width: 82vmin;
	}
}