.SecondaryButton {
	font-family: 'Muli', sans-serif;
	border-radius: 100vmin;
	border: 0.4vmin solid #53cd7f;
	font-size: 2.6vmin;
	font-weight: 900;
	color: #53cd7f;
	background-color: rgba(0,0,0,0);
	transition: background-color 0.2s;
	text-transform: uppercase;
	letter-spacing: normal;
	margin: 1vmin 1.2vmin;
	padding: 2.6vmin 10vmin;
	position: relative;
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	justify-content: center;

	@media only screen and (max-width:600px) {
		font-size: 3vmin;
		margin: 2vmin 2.4vmin;
		padding: 3.8vmin 14vmin;
		border-width: 0.6vmin;
	}
	
	/* ----- SecondaryButton states ----- */	
	&:active {
		b {
			opacity: .5;
		}
	}

	&:focus {
		outline: none;
	}
	&:disabled, &[disabled] {
	  background-color: rgba(100,100,100,0.5);
		color: white;
		border-color: #666;
		cursor: default;
	}
	&:hover:not([disabled]) {
		background-color: #53cd7f;
    color: #fff;
	}

	/* ----- SecondaryButton arrows ----- */

	&.SecondaryButton-left-arrow, &.SecondaryButton-right-arrow{
		padding: 2.1vmin 8vmin;
		letter-spacing: 0.2vmin;
		font-size: 2.3vmin;

		@media only screen and (max-width:600px) {
			padding: 3.4vmin 16vmin;
			letter-spacing: 0.3vmin;
			font-size: 4.5vmin;
		}
	}
	&.SecondaryButton-right-arrow b{
		font-weight: 800;
	}
	&.SecondaryButton-right-arrow::after, &.SecondaryButton-left-arrow::before{
		font-size: 1.7vmin;
		margin-top: -0.36vmin;
		font-weight: 600;
		position: absolute;
		transform: scaleY(1.6) scaleX(0.8);

		@media only screen and (max-width:600px) {
			font-size: 4.4vmin;
			margin-top: -1vmin;
		}
	}
	&.SecondaryButton-right-arrow::after{
		content: '>';
		right: 5.4vmin;

		@media only screen and (max-width:600px) {
			right: 6vmin;
		}
	}
	&.SecondaryButton-left-arrow::before{
		content: '<';
		left: 5.4vmin;

		@media only screen and (max-width:600px) {
			left: 6vmin;
		}
	}

	/* ----- SecondaryButton content ----- */	

	b {
		font-weight: 900;
	}
	span {
		display: inline;
	}
}


/*.SecondarySecondaryButton .SecondaryButton{
	color: #53cd7f;
	background-color: rgba(0,0,0,0);
	border: 0.4vmin solid #53cd7f;
	text-transform: uppercase;
	font-weight: 700;
}

.SecondarySecondaryButton .SecondaryButton:disabled,
.SecondarySecondaryButton .SecondaryButton[disabled]{
	background-color: rgba(100,100,100,0.5);
	color: white;
	border-color: #666;
	cursor: default;
}

.SecondarySecondaryButton .SecondaryButton:hover:not([disabled]){
	background-color: #53cd7f;
	color: white;
}

@media only screen and (max-width:600px) {
	.SecondarySecondaryButton .SecondaryButton{
		border-width: 0.8vmin;
	}
}*/