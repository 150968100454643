
.AssetItem{
	position: relative;
	margin: auto;
	width: 100%;
	height: 12vmin;
	display: flex;
	overflow: hidden;
	align-items: center;
	vertical-align: middle;
	border-bottom: 1px solid #e0e0e0;
	overflow: hidden;
}
.AssetItem:hover{
	cursor: pointer;
}
.AssetItem.selected{
	background: linear-gradient(
		to right, 
		rgba(0,0,0,1) 0%, rgba(240,47,23,1) 0%, 
		rgba(0,0,0,1) 0%, rgba(0,0,0,1) 0%, 
		rgba(112,112,112,1) 0%, rgba(204,202,204,1) 40%, 
		rgba(214,214,214,1) 62%, rgba(227,227,227,1) 100%
	);
}
.AssetItem .AssetItem-image-container{
	display: flex;
	flex-shrink: 0;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 30%;
	overflow: hidden;
}
.AssetItem .AssetItem-image-container svg {
	width: 70%;
	height: 70%;
}
.AssetItem .AssetItem-image{
	display: block;
	height: 100%;
	width: 100%;
	background-repeat: no-repeat;
	background-position: 100%;
	background-size: auto 120%;
}
.AssetItem .AssetItem-info{
	text-align: left;
	width: 68%;
	margin-left: -2.2%;
	margin-left: 5px;
}

.AssetItem .TickerPrice{
	display: inline;
	height: 2.6vmin;
	border-radius: 2.6vmin;
}
.AssetItem .TickerPrice .TickerPrice-content{
	font-size: 2vmin;
	padding: 0 6px;
}
.AssetItem .TickerPrice .TickerPrice-ticker{
	letter-spacing: -0.2vmin;
}
.AssetItem .TickerPrice .TickerPrice-divider{
	margin: 0 0.3vmin;
}

.AssetItem .AssetItem-info-name{
	color: black;
	font-family: Bebasneue;
	font-size: 3.8vmin;
	line-height: 3.8vmin;
	margin-top: 0.4vmin;
}
.AssetItem-info-status{
	font-size: 1.3vmin;
	font-weight: 600;
}
.AssetItem-info-status.available,
.AssetItem-info-status.trading{
	color: green;
  font-family: 'Gotham book';
	font-weight: 600;
}
.AssetItem-info-status.finishing{
	color: #c54e48;
  font-family: 'Gotham bold';
	font-weight: 600;
}
.AssetItem-info-status.reserveshares{
	color: #3c6bff;
	font-weight: 600;
}
.AssetItem-info-status.closed{
	font-weight: 600;
}
.AssetItem .AssetItem-info-name > span, .AssetItem-info-status > span{
	white-space: nowrap;
	text-overflow: ellipsis;
	display: block;
	overflow: hidden;
}
.AssetItem .AssetItem-activebar{
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: #555;
	height: 100%;
	width: 1.4vmin;
}

@media only screen and (max-width:600px) {
	.AssetItem{
		height: 22vmin;
	}
	.AssetItem .AssetItem-image-container{
		width: 23%;
	}
	.AssetItem .AssetItem-info{
		width: 77%;
	}
	.AssetItem .TickerPrice{
		display: inline;
		height: 5.2vmin;
		border-radius: 5.2vmin;
	}
	.AssetItem .TickerPrice .TickerPrice-content{
		font-size: 4vmin;
		padding: 0 6px;
	}
	.AssetItem .TickerPrice .TickerPrice-ticker{
		letter-spacing: -0.4vmin;
	}
	.AssetItem .TickerPrice .TickerPrice-divider{
		margin: 0 0.6vmin;
	}

	.AssetItem .AssetItem-info-name{
		font-size: 7.6vmin;
		line-height: 7.6vmin;
		margin-top: 0.8vmin;
	}
	.AssetItem-info-status{
		font-size: 2.6vmin;
	}
}
