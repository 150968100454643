.AssetStory {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.AssetStory .AssetStory-ticker {
	font-size: 2.5vh;
	color: #868686;
	font-weight: 900;
	letter-spacing: -0.2vh;
	text-transform: uppercase;
	font-family: 'Muli';
}
.AssetStory .AssetStory-assetname {
	color: #222;
	font-family: 'Bebasneue', sans-serif;
	font-weight: 200;
	font-size: 4.6vh;
	margin-bottom: 0.8vh;
	padding: 0 3vmin;
    text-align: center;
    line-height: 5.2vh;
}
.AssetStory .AssetStory-description {
	position: relative;
	max-height: 60%;
	margin: 0 9vmin 2vh;
	overflow-y: auto;
	text-align: center;
}
.AssetStory .AssetStory-description::-webkit-scrollbar {
	width: 0;
  background: transparent;
}
.AssetStory .AssetStory-description p {
	font-family: Georgia, serif;
	font-size: 1.9vh;
	line-height: 3.8vh;
	margin: 2vh;
}
.AssetStory .TextButton {
	font-family: Muli;
	font-weight: 900;
}
.AssetStory .TextButton{
	color: #444;
}

.AssetStory .NotifyMeButton.unselected:not(:hover):not([disabled]) span {
	color: #53cd7f;
}

/*.AssetStory .AssetDetailsPage-bidask-ask .SecondaryButton{
	background-color: #c04c47;
}*/

@media only screen and (max-width:600px) {
	.AssetStory .AssetStory-description {
		margin: 0 10vmin;
		max-height: 50vh;
		overflow-y: auto;
	}
	.AssetStory .AssetStory-description p {
		font-size: 3.6vmin;
		line-height: 7vmin;
		margin: 3vmin 0 2vmin 0;
	}
	.AssetStory .AssetStory-assetname {
		line-height: 9.5vmin;
		padding-right: 4vmin;
		padding-left: 4vmin;
		text-align: center;
	}
}
