
.ChartPoint{
	height: 100%;
	width: 100%;
	cursor: pointer;
}
.ChartPoint-point{
	position: absolute;
	z-index: 2;
	height: 1vh;
	width: 1vh;
	border-radius: 0.5vh;
	margin-bottom: -0.5vh;
	margin-left: -0.5vh;
	background-color: white;
}
