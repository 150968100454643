.AssetCarousels {
  height: 84.2vh;
  overflow: hidden;
  width: 68%;
  display: inline-block;
  vertical-align: top;
  position: relative;
  /* a fix for safari carousel glitchy css transitions (on mobile mostly) */
  -webkit-backface-visibility: hidden;
}
/* alters display of carousel for the special access application mode */
.AssetCarousels.special-access {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: calc(100vh - 10vh);
  min-width: 100%;
  width: 100vw;
}

.AssetCarousels-overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, .7);
  z-index: 16;
}

.AssetCarousel {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
/* alters display of carousel for the special access application mode */
.AssetCarousel.special-access {
  display: flex;
  flex-direction: column;
  height: 100%;
}


.AssetCarousel .AssetCarousel-swipeableitem {
  display: flex;
  flex-shrink: 0;
  width: 82%;
  margin: 0 auto;
  left: 0;
  right: 0;
  position: absolute;
  will-change: transform;
}

.AssetCarousel .AssetCarousel-swipeableitem > div {
  width: 100%;
  position: relative;
  will-change: transform;
}

.AssetCarousel .react-swipe-container,
.AssetCarousel .react-swipe-container > div {
  height: 100%;
}

.AssetCarousel-arrow-next, .AssetCarousel-arrow-prev {
  background-color: rgba(255,255,255,0);
  -webkit-tap-highlight-color: rgba(255,255,255,0);
  border: none;
  position: absolute;
  top: 35vh;
  padding: 1px 6px;
  z-index: 10;
  cursor: pointer;
}
.AssetCarousel-arrow-next {
  right: 0;
}
.AssetCarousel-arrow-prev {
  left: 0;
}

@media only screen and (max-width:750px) {
  .AssetCarousels {
    width: calc(100% - 6.5vw);
    height: 90%;
    position: fixed;
    top: 10vh;
  }
  .AssetCarousel {
    border-left: none;
  }
}

@media only screen and (max-width:600px) {
  .AssetCarousels, .AssetCarousels.AssetCarousels.special-access {
    width: 100%;
    height: 93%;
    top: 7%;
  }

  .AssetCarousels.AssetCarousels.special-access {
    min-height: 93%;
  }

  .AssetCarousel-arrow-next, .AssetCarousel-arrow-prev {
    display: none;
  }
  .AssetCarousels.hideformobile {
    display: none;
  }
  .AssetCarousel {
    border-left: none;
  }
}

@media only screen and (max-height:499px) {
  .AssetCarousels.special-access {
    min-height: calc(100vh - 50px);
  }
}

/* --- Carousel slides animation --- */

.AssetCarousels .AssetCarousel-slide {
  animation: fadeOutAsset .4s ease-in-out 0s forwards;
}

.AssetCarousels .AssetCarousel-slide-active {
  animation: fadeInAsset .4s ease-in-out .1s backwards;
}

@keyframes fadeInAsset {
  0% {
    opacity: .05;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOutAsset {
  0% {
    opacity: 1;
  }
  100% {
    opacity: .05;
  }
}
