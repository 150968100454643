
@import 'variables';

.Legal {
	@extend %asset-page-content;
  position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	overflow: hidden;
}
.Legal.special-access {
	height: 100%;
	width: 100%;
}

.Legal .Legal-contents{
	width: 100%;
	height: calc(100% - 9vmin);
	margin-top: 9vmin;
	background-color: #aaa;
}

.Legal .Legal-contents iframe{
	margin: 0px;
	padding: 0px;
	overflow-y: scroll;
	border: 0;
}

@media only screen and (max-width:600px) {
	.Legal .Legal-contents{
		height: calc(100% - 18vmin);
		margin-top: 18vmin;
	}
}
