@import 'variables';

.TradingInfoPopup {
	.Popup-contents {
		max-height: 84%;
	}
	.TradingInfoPopup-body{
		.TradingInfoPopup-emphasis{
			color: $rally-primary;
		}
		.TradingInfoPopup-title{
			font-size: 2.2vmin;
			margin: 0;
			font-weight: 900;
		}
		.TradingInfoPopup-img{
			width: 90%;
			max-height: 20vh;
		}
		.TradingInfoPopup-footer{
			display: block;
			padding-top: 1.6vmin;
			font-size: 1.6vmin;
			color: $grey;
		}
		.SecondaryButton{
			padding: 1.8vmin 9vmin;
		}
		p {
			margin-top: 0;
		}
		b {
			font-weight: 900;
		}
	}
}

@media only screen and (max-width:600px) {
	.TradingInfoPopup {
		.TradingInfoPopup-body{
			.TradingInfoPopup-title{
				font-size: 4.4vmin;
				margin-bottom: 1vmin;
			}
			.TradingInfoPopup-footer{
				font-size: 2.8vmin;
			}
			p {
				font-size: 3.5vmin;
				line-height: 5.4vmin;
				margin: 2vmin 0;
			}
		}
	}
}