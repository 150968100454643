.AssetDetailsLink {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 8.8vh;
	font-size: 1.2vh;
	color: #868686;
	font-weight: 800;
	cursor: pointer;
}
.AssetDetailsLink a{
	color: #868686;
}

.AssetDetailsLink img {
	height: 2.8vh;
	width: 2.8vh;
	margin-bottom: 0.2vh;
}

@media only screen and (max-width:600px) {
	.AssetDetailsLink {
		width: 19vmin;
		font-size: 3.2vmin;
		color: rgba(255,255,255,0.8);
	}
	.AssetDetailsLink a{
		color: rgba(255,255,255,0.8);
	}
	.AssetDetailsLink img {
		height: 6vmin;
		width: 6vmin;
		margin-bottom: 0.4vmin;
	}
}