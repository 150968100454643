.TickerTitle {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.TickerTitle .TickerTitle-ticker {
	font-size: 2.5vh;
	color: white;
	font-weight: 900;
	letter-spacing: -0.2vh;
	text-transform: uppercase;
	font-family: 'Muli';
}
.TickerTitle .TickerTitle-divider {
	width: 1px;
	height: 3vh;
	background: #c5c5c5;
	margin: 0 1vh;
}
.TickerTitle .TickerTitle-title {
	font-family: 'Cardo', serif;
	font-style: italic;
	color: white;
	font-size: 3vh;
}

