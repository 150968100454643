.ErrorPage{
  width: 80%;
  margin: 0 auto;
  display: block;
  position: relative;
  text-align: center;

  .ErrorPage-title{
    font-weight: 700;
    font-size: 5.5vmin;
    margin-bottom: 6vmin;
    line-height: 1.2;
  }

  .ErrorPage-message{
    margin: 0 4vw;
  }

  .ErrorPage-logo{
    width: 100%;
    height: 5vh;
    margin: 2vh 0 16vh 0;
    padding-top: 3vh;
    text-align: center;
  }

  .ErrorPage-body{
    width: 100%;
  }
}

