
.AccreditationStatus {
	margin-bottom: 4vh;
}

.AccreditationStatus input[type="radio"] {
	display: none;
}

.AccreditationStatus input[type="radio"] + label {
	cursor: pointer;
}
.AccreditationStatus input[type="radio"]:checked + label {
	color: white;
	background-color: black;
}

.AccreditationStatus .AccreditationStatus-title {
	white-space: nowrap;
}

.AccreditationStatus .AccreditationStatus-label {
	display: block;
	border: 0.1vh solid #aaa;
	border-radius: 1vh;
	margin: 1.4vh 0;
	padding: 3vmin;
}
.AccreditationStatus .AccreditationStatus-label:hover {
	background-color: #ddd;
}

.AccreditationStatus .AccreditationStatus-label b {
	font-size: 2.2vh;
	display: block;
	margin-bottom: 0.6vh;
	font-weight: 800;
}

.AccreditationStatus .AccreditationStatus-label p {
	font-size: 1.8vh;
	line-height: 2.6vh;
	margin: 0;
}

@media only screen and (max-width:600px) {
	.AccreditationStatus {
		margin-bottom: 4vmin;
	}
}