.MicroDepositVerification {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 50;
	background-color: rgba(0,0,0,0.8);
	display: flex;
	align-items: center;
	justify-content: center;
}

.MicroDepositVerification .MicroDepositVerification-content {
	height: 72vh;
	width: 54vw;
	margin: 0 12%;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}
.MicroDepositVerification .MicroDepositVerification-message {
	margin: 0 3vw 6vh 3vw;
}

.MicroDepositVerification .MicroDepositVerification-input {
	margin: 4vh 0;
}

.MicroDepositVerification .MicroDepositVerification-input-title {
	text-transform: uppercase;
	font-weight: 700;
	color: #aaa;
	margin-right: 1vw;
}
.MicroDepositVerification .MicroDepositVerification-input-zero {
	font-size: 4vh;
	font-weight: 700;
	margin-right: 0;
	padding-right: 0;
}

.MicroDepositVerification .MicroDepositVerification-input .Input {
	width: 8vw;
	display: inline-block;
}
.MicroDepositVerification .MicroDepositVerification-input .Input input {
	font-size: 4vh;
	font-weight: 700;
	text-align: left;
	margin-left: -0.6vw;
	padding-bottom: 0.2vh;
	padding-left: 0.1vw;
}
.MicroDepositVerification .MicroDepositVerification-input .Input input::placeholder {
	color: #ccc;
	font-weight: 400;
}

.MicroDepositVerification .MicroDepositVerification-help a:not(:hover) {
	color: black;
}

/* below is transfer page */

.MicroDepositVerification .MicroDepositVerification-infoContent-content {
	height: 50vh;
}
.MicroDepositVerification .MicroDepositVerification-infoContent-title {
	margin: 3vmin;
	font-size: 4.2vmin;
	line-height: 4.4vmin;
	font-weight: 700;
	color: #53cd7f;
}
.MicroDepositVerification .MicroDepositVerification-infoContent-message {
	font-size: 2.8vmin;
	line-height: 4vmin;
	margin: 12vh 6vw;
}

@media only screen and (max-width:600px) {
	.MicroDepositVerification {
		width: 100%;
    height: 100%;
    position: fixed;
    top: 0%;
	}

	.MicroDepositVerification .Popup-contents.MicroDepositVerification-content {
		height: 100%;
    width: 100vw;
    margin: 0;
    max-height: 100vh;
    max-width: 100vw;
    overflow: scroll;
	}
	.MicroDepositVerification .MicroDepositVerification-input-title {
		margin-right: 2vw;
	}
	.MicroDepositVerification .MicroDepositVerification-input .Input {
		width: 14vw;
	}

	/* below is transfer page */
	.MicroDepositVerification .MicroDepositVerification-infoContent-content {
		height: 60vh;
	}
	.MicroDepositVerification .MicroDepositVerification-infoContent-title {
		font-size: 7.2vmin;
		line-height: 8.4vmin;
	}
	.MicroDepositVerification .MicroDepositVerification-infoContent-message {
		font-size: 6.2vmin;
		line-height: 8vmin;
		margin: 16vh 6vw;
	}
}
