@import 'variables';

.AssetDetailsMain {
  width: 100%;
  height: 100%;
  text-align: center;

  &::before,
  &::after {
    position: absolute;
    content: '';
    width: 100%;
    left: 0;
  }
  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    background: -moz-linear-gradient(
      top,
      rgba(0, 0, 0, 0.9) 0%,
      rgba(0, 0, 0, 0.55) 22%,
      rgba(0, 0, 0, 0.1) 32%,
      rgba(0, 0, 0, 0) 35%,
      rgba(0, 0, 0, 0.02) 45%,
      rgba(0, 0, 0, 0.1) 48%,
      rgba(0, 0, 0, 0.55) 60%,
      rgba(0, 0, 0, 0.82) 70%,
      rgba(0, 0, 0, 0.9) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0.9) 0%,
      rgba(0, 0, 0, 0.55) 22%,
      rgba(0, 0, 0, 0.1) 32%,
      rgba(0, 0, 0, 0) 35%,
      rgba(0, 0, 0, 0.02) 45%,
      rgba(0, 0, 0, 0.1) 48%,
      rgba(0, 0, 0, 0.55) 60%,
      rgba(0, 0, 0, 0.82) 70%,
      rgba(0, 0, 0, 0.9) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.9) 0%,
      rgba(0, 0, 0, 0.55) 22%,
      rgba(0, 0, 0, 0.1) 32%,
      rgba(0, 0, 0, 0) 35%,
      rgba(0, 0, 0, 0.02) 45%,
      rgba(0, 0, 0, 0.1) 48%,
      rgba(0, 0, 0, 0.55) 60%,
      rgba(0, 0, 0, 0.82) 70%,
      rgba(0, 0, 0, 0.9) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }

  .AssetDetailsMain-background {
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
  }

  .AssetDetailsMain-name,
  .AssetDetailsMain-goal {
    font-family: 'Bebasneue', sans-serif;
    letter-spacing: -0.1vh;
  }
  .AssetDetailsMain-ticker {
    font-size: 4vh;
    margin-bottom: 5px;
    font-weight: 900;
    letter-spacing: -0.2vh;
    text-transform: uppercase;
    font-family: 'Muli';
  }
  .AssetDetailsMain-name {
    font-size: 6.2vh;
    line-height: 5.2vh;
    color: white;
    padding: 0 3vmin;
  }
  .AssetDetailsMain-enddate {
    color: white;
    font-size: 1.6vh;
    font-weight: 600;
    margin: 5px 0;
  }

  .AssetDetailsMain-image {
    position: relative;
    overflow: hidden;
    height: 22vh;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 80%;
    display: flex;
    flex-shrink: 0;
    margin: 5px 0;
  }

  .AssetDetailsMain-goal {
    font-size: 6.4vh;
    line-height: 5.8vh;
    .Price {
      color: white;
    }
  }
  &.coming-soon .AssetDetailsMain-goal {
    font-size: 5vh;
    line-height: 4vh;
    margin-top: 1vh;
  }
  .AssetDetailsMain-progress {
    width: 100%;
    padding: 0 70px;
    box-sizing: border-box;
  }
  .AssetDetailsMain-progress-desktop {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 6px;
    .ProgressBar {
      min-width: 100%;
      max-width: 600px;
    }
  }
  .AssetDetailsMain-progress-mobile {
    display: none;
  }

  .ProgressBar .ProgressBar-text {
    font-weight: 900;
  }

  .AssetDetailsMain-investmentinfo {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    max-width: 52vh;
    margin: 0 auto;
    align-items: center;
    font-family: 'Teko', sans-serif;
    font-size: 2.2vh;
    padding: 0;
    color: rgba(255, 255, 255, 0.5);
    font-weight: 500;
    transform: scaleY(1.1);
  }
  .AssetDetailsMain-investmentinfo-days,
  .AssetDetailsMain-investmentinfo-investors {
    color: white;
  }
  .AssetDetailsMain-investmentinfo-investments {
    color: #42c984;
    font-size: 2.6vh;
  }
  .AssetDetailsMain-investmentinfo-investments.remaining {
    color: #B4554C;
  }
  .AssetDetailsMain-investmentinfo-divider {
    height: 2.4vh;
    width: 0.1vh;
    margin: 0 1vh;
    background: #666;
  }

  .AssetDetailsMain-comingsoon {
    display: none;
  }

  &.coming-soon .AssetDetailsMain-comingsoon {
    display: block;
    color: rgba(200, 200, 200, 0.5);
    font-size: 1.8vh;
    margin-top: 1.4vh;
    width: 40vh;
  }

  // .AssetDetailsMain-tradinginfo{
  // 	display: none;
  // }

  .AssetDetailsMain-links {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 45%;
    margin: 2.4vh 0 0 0;
    font-family: Muli;
    font-weight: 900;
  }

  .AssetDetailsMain-tradinginfo {
    display: block;
    width: 100%;

    .AssetDetailsMain-tradinginfo-titleinfo {
      background: #737373;
      color: $rally-primary;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 1.4vmin;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 2vmin;
      padding: 0.4vmin 1vmin;
      .AssetDetailsMain-tradinginfo-title {
        margin-right: 0.6vmin;
        font-family: Muli;
        font-weight: 900;

        &.white {
          color: white;
        }
      }
      .AssetDetailsMain-tradinginfo-infobutton {
        cursor: help;
        .Info {
          color: $rally-light-grey;
          svg {
            width: 1.5vmin;
            height: 1.5vmin;
          }
        }
      }
    }
    &.AssetDetailsMain-tradingclosed {
      .AssetDetailsMain-tradinginfo-divider {
        height: 1px;
        background: #fff;
        opacity: .2;
        width: 220px;
        max-width: 70%;
        margin: 0 auto;
      }
      .AssetDetailsMain-tradinginfo-titleinfo {
        background: none;
        font-size: 1.6vmin;
        padding: 0;
      }
      .AssetDetailsMain-tradinginfo-clearingprice {
        display: block;
        line-height: 1;
        .AssetDetailsMain-tradinginfo-clearingprice-price {
          margin-bottom: 0;
        }
      }
      .AssetDetailsMain-tradinginfo-current-asset-price {
        font-family: Muli;
        display: block;
        font-weight: 700;
        font-size: 1.2vmin;
        color: hsla(0,0%,100%,.8);
        text-transform: uppercase;
        margin-bottom: .4vmin;
      }
      .AssetDetailsMain-tradinginfo-priceshift {
        font-size: 1.4vmin;
        text-transform: uppercase;
      }
    }
    .AssetDetailsMain-tradinginfo-clearingprice {
      display: flex;
      padding: 0 4vmin;
      align-items: center;
      justify-content: center;
      position: relative;
      margin: 0 auto;
      .DotsLoader {
        height: 9vmin;
        justify-content: center;
        display: flex;
        align-items: center;
      }
      .AssetDetailsMain-tradinginfo-clearingprice-wrapper {
        padding: 0 4vmin;
        position: relative;
      }
      .AssetDetailsMain-tradinginfo-clearingprice-price {
        font-family: 'Bebasneue', sans-serif;
        color: white;
        font-size: 8vmin;
        line-height: 8vmin;
        margin-top: 0.2vmin;
        margin-bottom: -0.8vmin;
        display: inline-block;
      }
      .AssetDetailsMain-tradinginfo-clearingprice-arrow {
        img {
          margin: 0.2vmin 0.2vmin 0.6vmin 0.2vmin;
          height: 5vmin;
        }
      }
    }
    .AssetDetailsMain-tradinginfo-currentvalue {
      color: $rally-light-grey;
      font-size: 1.4vmin;
      .AssetDetailsMain-tradinginfo-currentvalue-change {
        color: $rally-primary;
        &.down {
          color: $rally-red;
        }
        b {
          font-weight: 800;
        }
      }
    }
  }

  .AssetDetailsMain-trading {
    // .AssetDetailsMain-enddate,
    // .AssetDetailsMain-goal,
    // .AssetDetailsMain-progress {
    // 	display: none;
    // }
    .AssetDetailsMain-links {
      margin-bottom: 1vh;
    }
    .AssetDetailsMain-links .AssetDetailsLink .AssetDetailsLink-text {
      display: none;
    }
  }

  .AssetDetailsMain-last-trading {
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.6);
    .AssetDetailsMain-trading-closed-date {
      display: inline-block;
      margin: 4px 0;
      padding: 6px 16px;
      color: white;
      border-radius: 1.6vh;
      background-color: rgba(0, 0, 0, 0.6);
    }
    span {
      display: block;
    }
  }

  .AssetDetailsMain-last-close {
    color: white;
    text-transform: uppercase;

    &.has-bg {
      margin-left: 10px;
      display: inline-block;
      margin: 4px 0;
      padding: 0.3em 16px;
      color: white;
      border-radius: 1.6vh;
      background-color: rgba(0, 0, 0, 0.6);
    }

    & + .AssetDetailsMain-last-close {
      margin-left: 0.4em;
    }

    .AssetDetailsMain-tradinginfo-currentvalue-price {
      color: $rally-primary;
      font-family: 'Gotham Medium';
      margin-right: .3em;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .AssetDetailsMain {
    .AssetDetailsMain-investmentinfo {
      max-width: 44vh;
    }
  }
}

@media only screen and (max-width: 920px) {
  .AssetDetailsMain {
    .AssetDetailsMain-progress {
      padding: 0 9vmin;
    }
    .AssetDetailsMain-progress-desktop {
      margin-bottom: 0;
      .ProgressBar {
        min-width: 34vh;
      }
    }
    .AssetDetailsMain-links {
      width: 80%;
    }
  }
}

@media only screen and (max-width: 600px) {
  .AssetDetailsMain {
    .AssetDetailsMain-progress-mobile {
      display: block;
      margin-bottom: 6px;

      .ProgressBar {
        margin: 0 auto;
      }
    }
    .AssetDetailsMain-progress-desktop {
      display: none;
    }
    .AssetDetailsMain-ticker {
      font-size: 6.5vmin;
    }
    .AssetDetailsMain-name {
      font-size: 11vmin;
    }
    .AssetDetailsMain-enddate {
      font-size: 3vmin;
    }
    .AssetDetailsMain-goal {
      font-size: 12vmin;
      line-height: 11vmin;
      margin-bottom: 2vmin;
    }
    &.coming-soon .AssetDetailsMain-goal {
      font-size: 10vmin;
      line-height: 8vmin;
      margin-top: 2vmin;
    }
    .AssetDetailsMain-investmentinfo {
      font-size: 4.4vmin;
      max-width: 100%;
    }
    .AssetDetailsMain-investmentinfo-investments {
      font-size: 4.8vmin;
    }
    .AssetDetailsMain-investmentinfo-divider {
      margin: 0 2vmin;
    }
    .AssetDetailsMain-tradinginfo {
      .AssetDetailsMain-tradinginfo-titleinfo {
        font-size: 2.8vmin;
        border-radius: 4vmin;
        padding: 0.8vmin 2vmin;
        .AssetDetailsMain-tradinginfo-title {
          margin-right: 1.2vmin;
        }
        .AssetDetailsMain-tradinginfo-infobutton {
          .Info {
            svg {
              width: 3vmin;
              height: 3vmin;
            }
          }
        }
      }
      .AssetDetailsMain-tradinginfo-clearingprice {
        width: 90%;
        margin: 0 auto;
        .DotsLoader {
          height: 16vmin;
        }

        .AssetDetailsMain-tradinginfo-clearingprice-price {
          font-size: 15vmin;
          line-height: 15vmin;
          margin-top: 0.4vmin;
          margin-bottom: -1.6vmin;
        }
        .AssetDetailsMain-tradinginfo-clearingprice-arrow {
          img {
            margin: 0.4vmin 0.4vmin 1.2vmin 0.4vmin;
            height: 10vmin;
          }
        }
      }
      .AssetDetailsMain-tradinginfo-currentvalue {
        font-size: 2.8vmin;
      }
      &.AssetDetailsMain-tradingclosed {
        .AssetDetailsMain-tradinginfo-titleinfo {
          font-size: 3.2vmin;
        }
        .AssetDetailsMain-tradinginfo-current-asset-price {
          font-size: 2.4vmin;
        }
        .AssetDetailsMain-tradinginfo-priceshift {
          font-size: 2.8vmin;
          line-height: 2vmin;
          margin: 0.4vmin;
        }
        .AssetDetailsMain-tradinginfo-clearingprice {
          .AssetDetailsMain-tradinginfo-clearingprice-price {
            margin-top: -0.2vmin;
            margin-bottom: -2.4vmin;
          }
        }
      }
    }
  }
}

@media only screen and (max-height: 570px) {
  .AssetDetailsPage-assetMainPage {
    .AssetDetailsMain-image {
      flex-shrink: unset;
    }
  }
}
.ProgressBar-container {
  min-width: 100%;
  justify-content: flex-start;
  align-items: center;
  p {
    margin: 0;
    padding: 0;
    font-size: 12px;
    white-space: nowrap;
    margin-left: 4px;
    line-height: 14px;
  }
}

@media only screen and (min-width: 750px) {
  .ProgressBar-container {
    flex-direction: row;
    p {
      line-height: 0;
      margin-left: 8px;
    }
  }
}

