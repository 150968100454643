@import 'variables';

.employment-status {
	margin-bottom: 4vh;
  max-width: 100%;
  @media only screen and (min-width: $breakpoint-sm) {
    width: 400px;
  }

  &__title {
    white-space: nowrap;
    margin: 4vmin 0 10vmin;
    font-size: 2.8vh;
    line-height: 5.4vh;
    font-weight: normal;
    @media only screen and (min-width: $breakpoint-sm) {
      margin-bottom: 6vmin;
    }
  }
}
