.InvestmentsList {
	margin-top: 4vh;

  & > .DotsLoader {
    margin: 2vh;
  }
}

.InvestmentsList-header {
  padding: 2.6vmin 2.6vmin;
  font-weight: 700;
  text-transform: uppercase;
  color: #4b4b4b;
  border-bottom: 1px solid #eaeaea;
  border-top: 1px solid #eaeaea;
}

.InvestmentsList-placeholder {
  font-weight: bold;
  padding: 5vmin 2.6vmin 0;
  text-transform: uppercase;
  border-top: 1px solid #eaeaea;
}

.InvestmentsList-placeholder-title {
  margin-bottom: 12px;
}

.InvestmentsList-placeholder-image {
  display: inline-block;
  position: relative;
  margin-bottom: 2vmin;
  img {
    max-width: 100%;
  }
  &:after {
    display: inline-block;
    position: absolute;
    max-width: 120px;
    width: 100%;
    height: 1px;
    top: -6px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ccc;
    content: '';
  }
}

@media only screen and (max-width:600px) {
  .InvestmentsList-header {
    text-align: center;
  } 
}