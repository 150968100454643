.AssetDetailsPage {
	width: 100%;
	height: calc(100% - 10%);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;

	&.special-access {
		height: 75%;
	}

	&.trading {
		height: 80%;

		.AssetDetailsPage-contentswrapper {
			align-items: flex-start;
		}

		.AssetDetailsPage-contents {
			height: calc(100% - 80px);
			margin-top: 7vmin;
			@media only screen and (max-width:600px) {
				height: calc(100% - 40px);
			}
		}

		.AssetDetailsMain-image {
			flex-grow: 1;
			height: auto;
		}
	}

	&.special-access .BuySharesButton .Button {
		@media only screen and (max-width:600px) {
			padding-left: 8vmin;
			padding-right: 8vmin;
			font-size: 3.8vmin;
		}
	}

	.AssetDetailsPage-contentswrapper {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
	}

	.AssetDetailsPage-contents {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	&:not(.trading).AssetDetailsPage-assetMainPage {
		height: calc(100% - 15%);
	}

	&.AssetDetailsPage-assetVisuals {
		.AssetDetailsPage-contents {
			height: 75%;
			justify-content: flex-end;
		}
	}

	&.AssetDetailsPage-assetStory {
		justify-content: flex-start;
		.AssetDetailsPage-contentswrapper {
			height: 80%;
			align-items: flex-start;
		}
		.AssetDetailsPage-contents {
			height: 100%;
		}
	}

	&.AssetDetailsPage-assetTimeline {
		.AssetDetailsPage-contents {
			height: 100%;
		}
	}

}


@media only screen and (max-height:570px) {

	.AssetDetailsPage-assetMainPage {
		.AssetDetailsPage-contentswrapper {
			max-height: 65%;
		}
		.AssetDetailsPage-contents {
			height: 100%;
		}
	}

}
