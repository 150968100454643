@import 'variables';

.Error {
  color: $rally-red;
  font-weight: 600;
  padding: 5px;
  &-capitalized {
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 2px;
  }
}