.EmailConfirmation {
  margin: 0 auto;
  text-align: center;
  color: #28352e;
}

.EmailConfirmation-box {
  border: 0.05vmin solid #d9d9d9;
  padding: 2.5vmin 5.5vmin;
}

.EmailConfirmation .EmailConfirmation-title {
  font-weight: 700;
  font-size: 5vmin;
  margin-bottom: 5vmin;
  line-height: 5vmin;
}

.EmailConfirmation .EmailConfirmation-message {
  animation: fade-in 0.5s linear;
}

.EmailConfirmation .EmailConfirmation-header {
  position: relative;
  height: 8vh;
  margin-bottom: 2vh;
  text-align: center;
}
.EmailConfirmation .EmailConfirmation-header svg {
  height: 12vh;
}

.EmailConfirmation .EmailConfirmation-body {
  width: 40vmin;
}

.EmailConfirmation .Closebutton {
  width: 4.5vmin;
  height: 5.5vmin;
}

.EmailConfirmation .DotsLoader {
  margin: 4vh 0;
}

.EmailConfirmation .EmailConfirmation-message a {
  font-weight: 900;
  color: #28352e;
  text-decoration: underline;
}

@media only screen and (max-width: 600px) {
  .EmailConfirmation .Closebutton {
    width: 10vmin;
    height: 10vmin;
  }

  .EmailConfirmation-box {
    border: none;
  }

  .EmailConfirmation .EmailConfirmation-body {
    width: 85vmin;
  }
}
