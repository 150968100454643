.AssetQuotes {
	width: 100%;
	height: 100%;
	background-size: cover;
}

.AssetQuotes-quoteContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 5vh;
}

.AssetQuotes .AssetQuotes-quote {
	font-family: 'Cardo', serif;
	color: white;
	font-size: 4vmin;
	font-style: italic;
	line-height: 5.6vh;
	margin: 0 8vmin 5vh;
	display: flex;
	align-items: center;
  justify-content: center;
	text-align: center;
}
.AssetQuotes .AssetQuotes-author-image {
	width: 6.6vh;
	height: 6.6vh;
	margin-bottom: 0.6vh;
}
.AssetQuotes .AssetQuotes-author {
	font-family: Muli;
	font-weight: 900;
	font-size: 2.4vh;
	line-height: 3.2vh;
	color: white;
	text-transform: uppercase;
	max-width: 85vw;
	text-align: center;
}
.AssetQuotes .AssetQuotes-author-description {
	font-family: Muli;
	font-weight: 500;
	font-size: 2vh;
	color: #888;
	max-width: 85vw;
	word-break: break-word;
	text-align: center;
}
.AssetQuotes .TextButton {
	font-family: Muli;
	font-weight: 900;
}
.AssetQuotes-placeholder {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	margin: 0;
	text-align: center;
	text-transform: uppercase;
}

@media only screen and (max-width:600px) {
	.AssetQuotes .AssetQuotes-quote {
		font-size: 6.5vmin;
		line-height: 9vmin;
	}
}

