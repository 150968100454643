.Onboarding .Onboarding-wrapper {
	/*margin: 0 34vw;*/
	margin: 0 20px;
	max-width: 540px;
}
.Onboarding .OnboardingForm-container-contents{
	min-height: 30vh;
}

.Onboarding .StateAvailabilityError {
	padding: 20px 0;
}

.Onboarding p {
	font-size: 2.4vh;
	line-height: 4.6vh;
}
.Onboarding p.light {
	font-size: 1.8vh;
	line-height: 3vh;
	color: #666;
}

.Onboarding h2 {
	font-size: 2.6vh;
}

.Onboarding .Input,
.Onboarding .USStatesSelect{
	width: 100%;
	margin-bottom: 4vh;
}

.Onboarding .Input input, 
.Onboarding .USStatesSelect .USStatesSelect-select,
.Onboarding .USStatesSelect .USStatesSelect-value{
	font-size: 3vh;
}

.Onboarding .BackNextButtons .TextButton-right-arrow {
	padding-left: 1.6vh;
}
.Onboarding .BackNextButtons .TextButton-left-arrow {
	padding-right: 1.6vh;
}


@media only screen and (max-width: 1000px) {
	.Onboarding .Onboarding-wrapper {
		margin: 0 26vw;
	}
}

@media only screen and (max-width:750px) {
	.Onboarding .Onboarding-wrapper {
		margin: 0 14vw;
	}
}

@media only screen and (max-width:600px) {
	.Onboarding .Onboarding-wrapper {
		margin: 0 8vw;
	}
	.Onboarding p {
		font-size: 2.8vh;
		line-height: 5.4vh;
	}
	.Onboarding p.light {
		font-size: 2.2vh;
		line-height: 3.4vh;
	}
	.Onboarding h2 {
		font-size: 3.2vh;
	}
	.Onboarding .Input input,
	.Onboarding .USStatesSelect .USStatesSelect-select,
	.Onboarding .USStatesSelect .USStatesSelect-value{
		font-size: 4vh;
	}
}