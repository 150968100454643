.assets-early-access-banner {
  background-color: #53cd7f;
  color: white;
  display: flex;
  font-family: 'Muli', sans-serif;
  font-size: 2.25vmin;
  justify-content: center;
  padding: 1rem 0;
  position: relative;
  width: 100%;
  z-index: 1;

  @media only screen and (max-width:600px) {
    font-size: 2.5vmin;
  }
}