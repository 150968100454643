.FormCloseModal {
  background-color: rgba(0,0,0, .85);
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 11;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .FormCloseModal-content {
    max-width: 80%;
  }

  .FormCloseModal-text {
    color: #fff;
    line-height: 1.4;
    margin-bottom: 10vmin;
  }
  .FormCloseModal-finishlaterbutton{
    font-size: 2vmin;
    color: #9e9e9e;
    font-weight: 500;
    @media only screen and (max-width:600px) {
      font-size: 3.6vmin;
    }
  }
}

