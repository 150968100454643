
.FundAccount {

}
.FundAccount .FundAccount-wrapper {
	max-width: 420px;
}
.FundAccount .FundAccountForm-container-contents{
	min-height: 30vh;
}

.FundAccount p {
	font-size: 2.4vh;
	line-height: 4.6vh;
}
.FundAccount p.light {
	font-size: 1.8vh;
	line-height: 3vh;
	color: #666;
}

.FundAccount h2 {
	font-size: 2.6vh;
}

.FundAccount .Input,
.FundAccount .USStatesSelect{
	width: 100%;
	margin-bottom: 4vh;
}

.FundAccount .Input input, 
.FundAccount .USStatesSelect .USStatesSelect-select,
.FundAccount .USStatesSelect .USStatesSelect-value{
	font-size: 3vh;
}

.FundAccount .BackNextButtons .TextButton-right-arrow {
	padding-left: 1.6vh;
}
.FundAccount .BackNextButtons .TextButton-left-arrow {
	padding-right: 1.6vh;
}


@media only screen and (max-width: 1000px) {
	.FundAccount .FundAccount-wrapper {
		margin: 0 26vw;
	}
}

@media only screen and (max-width:750px) {
	.FundAccount .FundAccount-wrapper {
		margin: 0 14vw;
	}
}

@media only screen and (max-width:600px) {
	.FundAccount .FundAccount-wrapper {
		margin: 0 8vw;
	}
	.FundAccount p {
		font-size: 2.8vh;
		line-height: 5.4vh;
	}
	.FundAccount p.light {
		font-size: 2.2vh;
		line-height: 3.4vh;
	}
	.FundAccount h2 {
		font-size: 3.2vh;
	}
	.FundAccount .Input input, 
	.FundAccount .USStatesSelect .USStatesSelect-select,
	.FundAccount .USStatesSelect .USStatesSelect-value{
		font-size: 4vh;
	}
}