.SharePurchaseInfo .checkered-flag-img {
  margin-bottom: 1vmin;
	width: 8vmin;
	max-width: 100%;
	height: auto;
}

.SharePurchaseInfo .Button {
	margin-top: 4vh;
	width: 80%;
}