.ChangePasswordPopup {
  border-top: 1px solid #ddd;
  // position: fixed;
  // top: 10vh;
  // bottom: 0;
  
  h2 {
    margin-top: 0;
    font-weight: 900;
  }

  // .Popup-contents {
  //   padding: 0;
  // }

  .ChangePasswordPopup-inputs {
    margin: 2vh 0;
  }

  .ChangePasswordPopup-input {
    padding-bottom: 3.2vh;
    .Input-description {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      color: #dd2200;
    }
  }

  .ChangePasswordPopup-footer {
    max-width: 500px;
    color: #777;
    font-size: 1.6vh;
    line-height: 2.4vh;
    margin-top: 3vh;
  }
}
