@import 'variables';

.DepositWithdrawal {
	position: fixed;

	.DepositWithdrawal-review {
		position: absolute;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		font-size: 2.2vh;
		text-align: center;
		z-index: 1;
		background-color: #fff;
		h2 {
			margin-top: auto;
			font-size: 3.2vh;
			font-weight: 900;
		}
		p {
			max-width: 350px;
			line-height: 1.8;
			text-transform: none;
			padding: 0 20px;
		}
		footer {
			width: 100%;
			border-top: 2px solid #ccc;
			margin-top: auto;
			button {
				width: 50%;
				font-size: 2.2vh;
				padding: 2.2vh;
				background-color: #fff;
				text-transform: uppercase;
				outline: none;
				border: none;
				cursor: pointer;
				&:nth-child(1) { border-right: 1px solid #ccc; }
				&:nth-child(2) { border-left: 1px solid #ccc; }
				&[type="submit"] { font-weight: 900; }
				&:hover {
					background-color: #000;
					border-color: #000;
					color: #fff;
				}
				transition: .2s ease-in;
			}
		} 
	}

	.DepositWithdrawal-loader {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		opacity: .2;
		background-image: repeating-linear-gradient(
      -45deg, 
      transparent, 
      transparent 1rem,
      #ccc 1rem,
      #ccc 2rem
    );
		background-color: #fff;
    background-size: 200% 200%;
    animation: loaderAnimation 20s linear infinite;		
	}

	.Popup-contents { min-height: inherit; }

	.DepositWithdrawal-content {
		padding: 0;
		margin: 0 auto;
		width: 90%;
		max-width: 560px;
		background: white;
		text-align: left;
		text-transform: uppercase;
		font-size: 16px;
		line-height: 18px;
		text-transform: uppercase;

		& > span {
			display: inline-block;
			width: 100%;
		}

		&.customizedErrorContent {
			background-color: transparent;
		}
	}

	.DepositWithdrawal-top, .DepositWithdrawal-bottom {
		min-height: 30vh;
		position: relative;
		display: block;
	}
	
	.DepositWithdrawal-top {
		color: #aaa;
		background-color: white;
	}

	.DepositWithdrawal-accountinfo, .DepositWithdrawal-transferform {
		display: flex;
		flex-direction: column;
		min-height: 30vh;
		box-sizing: border-box;
		padding: 22px 26px 0;
	}

	.DepositWithdrawal-title {
		margin: 3.2vh 0 4.4vh 0;
		text-transform: uppercase;
		text-align: center;
		font-size: 20px;
		font-weight: 600;
		color: #555;
	}

	.DepositWithdrawal-header {
		font-weight: 800;
		line-height: 26px;
		color: #444;
		letter-spacing: 1px;
	}

	.DepositWithdrawal-deletesource .TextButton {
		padding: 0;
		margin: 14px 0;
		font-size: 14px;
		letter-spacing: 1px;
		font-weight: 800;
		color: #aaa;
		padding: 0;
		text-transform: uppercase;
	}

	.DepositWithdrawal-balancesummary {
		display: flex;
		margin-top: auto;
		padding-bottom: 10px;
	}
	
	.DepositWithdrawal-balance-group {
		flex-grow: 1;
		padding-right: 6px;
		&:first-child .DepositWithdrawal-balance-value {
			color: #41c84b;
			font-weight: 700;
		}
		&:last-child {
			padding-right: 0;
		}
	}

	.DepositWithdrawal-balance-header {
		padding-bottom: 4px;
		font-size: 16px;
		font-weight: 800;
	}
	
	.DepositWithdrawal-balance-value {
		font-size: 24px;
		line-height: 26px;
		color: #333;
	}

	.Closebutton {
		width: 38px;
		height: 38px;
	}

	/* below - dark half: */

	.DepositWithdrawal-bottom {
		color: #aaa;
		background-color: black;
	}

	.DepositWithdrawal-transfertofrom {
		width: 100%;
		display: inline-flex;
		justify-content: space-between;
	}

	.DepositWithdrawal-transfertofrom input[type="radio"] {
		display: none;
	}
	
	.DepositWithdrawal-transfertofrom input[type="radio"] + label {
		font-size: 16px;
		font-weight: 600;
		cursor: pointer;
	}

	.DepositWithdrawal-transfertofrom input[type="radio"]:checked + label{
		font-weight: 900;
		color: white;
		border-bottom: 4px solid green;
	}

	.DepositWithdrawal-moneyinput{
		position: relative;
		border-bottom: 1px solid #ddd;
		margin-top: 5vh;
		display: inline-flex;
		align-items: flex-end;
		justify-content: space-between;

		.Input {
			width: 50%;
		}
		.Input input {
			text-align: left;
			font-size: 52px;
			font-weight: 400;
			color: #eee;
			letter-spacing: 0vh;
			caret-color: #ddd;
			padding: 0 0.4vw 0.4vh 0;
			border-bottom: none;
			margin-bottom: 0;
		}
		.Input input::placeholder {
			color: #666;
		}
	}

	.DepositWithdrawal-moneyinput-error{
		position: absolute;
		left: 0;
		top: -20px;
		font-size: 14px;
		font-weight: bold;
		color: #c54e48;
	}

	.DepositWithdrawal-transferform .Input input::placeholder {
		color: #666;
	}
	
	.DepositWithdrawal-transferform {
		.Button, .SecondaryButton {
			text-transform: uppercase;
			margin: 0;
			margin-bottom: 1.4vh;
			font-size: 14px;
			font-weight: 900;
			padding: 12px 16px;
			border-width: 0.2vh;
		}
		 .Button:disabled, .Button[disabled] {
			background-color: #53cd7f;
		}
	}
	
	.DepositWithdrawal-transferaccount {
		margin: 12px 0;
	}

	.DepositWithdrawal-transferaccount-direction {
		color: #eee;
		padding-bottom: 4px;
	}

	/* below is transfer page */

	.DepositWithdrawal-transferpage {
		margin: auto;
		text-transform: none;
		text-align: center;
		.Error {
			margin-bottom: 2vh;
		}
		.Button, .SecondaryButton {
			margin: 5.4vh auto;
		}
	}
	.DepositWithdrawal-transferpage-title {
		padding-top: 6vmin;
		margin: 3vmin;
		font-size: 4.2vmin;
		line-height: 1.2;
		font-weight: 800;
		color: #53cd7f;
		&.error { color: $rally-red; }
	}
	.DepositWithdrawal-transferpage-message {
		font-size: 2.2vmin;
		line-height: 1.8;
		margin: 3vh 7vw;
	}

	/* error screen */

	.DepositWithdrawal-errors {
		margin: auto;
		text-transform: none;
		text-align: center;
		background-color: white;
	}
	.DepositWithdrawal-errors-title {
		padding-top: 3vmin;
		margin: 3vmin;
		font-size: 3vmin;
		line-height: 1.2;
		font-weight: 800;
		color: #000;
	}
	.DepositWithdrawal-errors-message {
		font-size: 2.2vmin;
    line-height: 1.5;
    margin: 3vh 2vw;
	}

	.DepositWithdrawal-contactsupport-button {
		font-family: 'Muli', sans-serif;
    border: none;
    font-size: 2.6vmin;
    font-weight: 800;
    color: white;
    background-color: #53cd7f;
    transition: background-color 0.2s;
    text-transform: uppercase;
    letter-spacing: normal;
    margin: 0;
    padding: 2.6vmin 10vmin;
    position: relative;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    width: 100%;
	}

	.DepositWithdrawal-closeAndContinue-button {
		font-family: 'Muli', sans-serif;
    border: none;
    font-size: 2.6vmin;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.85);
    background-color: transparent;
    transition: background-color 0.2s;
    text-transform: normal;
    letter-spacing: normal;
    margin: 2.5vmin 0 0 0;
    padding: 2.6vmin 10vmin;
    position: relative;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    width: 100%;
	}
}

@media only screen and (max-width:600px) {
	.DepositWithdrawal {
		.DepositWithdrawal-accountinfo, .DepositWithdrawal-transferform {
			padding: 20px 10px 0;
		}
		.Popup-contents.DepositWithdrawal-content:not(.customizedErrorContent) {
			position: fixed;
			width: 100%;
			height: 100%;
			max-height: inherit;
			min-height: inherit;
			font-size: 14px;
			overflow-y: auto;
			form {
				display: flex;
				flex-wrap: wrap;
				height: 100%;
			}
			.Closebutton {
				position: fixed;
				top: 1vmin;
				right: 1vmin;
			}
			.DepositWithdrawal-top, .DepositWithdrawal-bottom {
				width: 100%;
			}
			.DepositWithdrawal-top {
				display: flex;
			}
			.DepositWithdrawal-accountinfo {
				width: 100%;
				justify-content: space-around;
				padding: 6vh 10px 1vh;
			}
			.DepositWithdrawal-header {
				margin-top: auto;
			}
		}
		.DepositWithdrawal-deletesource .TextButton {
			font-size: 13px;
		}
		.DepositWithdrawal-title {
			font-size: 15px;
			margin: 10px 0 16px;
		}
		.DepositWithdrawal-balance-header {
			font-size: 14px;
		}
		.DepositWithdrawal-balance-value {
			font-size: 18px;
			line-height: 22px;
		}
		.DepositWithdrawal-transfertofrom input[type="radio"] + label {
			font-size: 14px;
		}
		.DepositWithdrawal-moneyinput .Input input {
			font-size: 30px;
		}
		
		.DepositWithdrawal-transferform {
			.Button, .SecondaryButton {
				font-size: 12px;
				padding: 8px 12px;
				margin-bottom: 6px;
			}
			.DepositWithdrawal-moneyinput-error {
				font-size: 12px;
			}
		}

		.Closebutton {
			margin: 4px;
		}
		
		.DepositWithdrawal-transferpage {			
			.Button, .SecondaryButton {
				margin: 0 0 4.2vh;
			}
		}
		.DepositWithdrawal-transferpage-title {
			font-size: 6vmin;
		}
		.DepositWithdrawal-transferpage-message {
			font-size: 4vmin;
		}
		.DepositWithdrawal-review footer button {
			font-size: 2.6vh;
		}

		.DepositWithdrawal-errors-title {
			font-size: 5vmin;
			margin: 4vmin;
		}

		.DepositWithdrawal-errors-message {
			font-size: 4.5vmin;
			line-height: 1.5;
			margin: 2vh 5vw;
		}

		.DepositWithdrawal-contactsupport-button {
			font-size: 5vmin;
			padding: 5vmin 10vmin;
		}

		.DepositWithdrawal-closeAndContinue-button {
			font-size: 5vmin;
			padding: 5vmin 10vmin;
		}
	}
}

@media only screen and (max-width:450px) {
	.DepositWithdrawal {
		.DepositWithdrawal-balance-header {
			font-size: 11px;
			font-weight: 600;
		}
		.DepositWithdrawal-transfertofrom input[type="radio"] + label {
			font-size: 13px;
		}
	}
}

@media only screen and (max-width:400px) {
	.DepositWithdrawal {
		.Closebutton {
			width: 34px;
			height: 34px;
		}
		.DepositWithdrawal-transfertofrom input[type="radio"] + label {
			font-size: 11px;
		}
	}
}

@media only screen and (max-width:350px) {
	.DepositWithdrawal {
		.DepositWithdrawal-balance-header {
			font-size: 10px;
		}
		.DepositWithdrawal-balance-value {
			font-size: 16px;
			line-height: 20px;
		}
		.DepositWithdrawal-transfertofrom input[type="radio"] + label {
			font-size: 10px;
		}
		.DepositWithdrawal-transferform .DepositWithdrawal-moneyinput-error {
			font-size: 11px;
		}
		.DepositWithdrawal-transferform {
			.Button, .SecondaryButton {
				font-size: 11px;
			}
		}
	}
}

@keyframes loaderAnimation {
  100% { background-position: 100% 100%; }
}