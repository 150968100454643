@import 'variables';

.Success h1 .CheckmarkIcon{
	color: #42c984;
	margin-right: 1vmin;
}

.Success h1 .CheckmarkIcon svg{
	height: 3.4vmin;
	width: 3.4vmin;
}

.Success .Success-description{
	margin-bottom: 8vh;
	font-size: 2.4vh;
}

.Success .Success-description b {
	font-weight: 900;
}

.Success .Success-description-price{
	color: #41c84b;
	font-weight: 600;
}

.Success-promo-info {
  display: block;
  font-size: 0.8em;
	font-style: italic;
  color: #424242;
}

.Success .Button{
	padding: 2.6vh 6vh;
}

.Success .Success-tos{
	margin: 4vh;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.Success .Success-tos label{
	color: #444;
	width: calc(100% - 6vh);
	text-align: left;
	margin-left: 2vh;
}

.Success-loading {
	h1 { color: $rally-green  }
	.Success-loader { margin-top: -50px; }
}

.Success-error {
	h1 {
		margin-bottom: 2.6vh;
	}
	.Error {
		color: inherit;
		line-height: 1.6;
		margin-bottom: 2.2vh;
	}
}

@media only screen and (max-width:600px) {
	.Success .CheckmarkIcon svg{
		height: 7vmin;
		width: 8vmin;
	}
}