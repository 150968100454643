@font-face {
	font-family: Bebasneue;
	src: url('./fonts/bebasneue.otf');
}
@font-face {
	font-family: Headlinenews;
	src: url('./fonts/headlinenews.ttf');
}
@font-face {
	font-family: Truenoexbd;
	src: url('./fonts/truenoexbd.otf');
}

@font-face {
	font-family: 'Gotham Medium';
	src: url('./fonts/Gotham_Medium_Regular.ttf');
}

@font-face {
	font-family: 'Gotham Bold';
	src: url('./fonts/Gotham_Bold.otf');
}

@font-face {
	font-family: 'Gotham Book';
	src: url('./fonts/Gotham_Book.otf');
}

@font-face {
	font-family: 'Gotham Bold';
	src: url('./fonts/Gotham_Bold.otf');
}

@font-face {
	font-family: 'Gotham Black';
	src: url('./fonts/Gotham_Black_Regular.ttf');
}

@font-face {
	font-family: 'IBM Plex';
	src: url('./fonts/IBMPlexSerif-RegularItalic.ttf');
}

@import url('https://fonts.googleapis.com/css?family=Muli:300,400,600,700,800,900&display=swap&subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Raleway:100,400,600,800,900&subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Teko:300,400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Cardo:400,400i&subset=latin-ext');

html {
  height: 100%;
}
body, #root, div[data-reactroot] {
	height: 100%;
	overscroll-behavior: none
}

body, button, input, select{
	font-family: 'Muli', sans-serif;
}

body, p, span{
	padding: 0;
	margin: 0;
}

body{
	background-color: white;
	width: 100%;
	font-family: 'Muli', sans-serif;
	font-size: 2vmin;
	color:#181818;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
h1{
	font-weight: 900;
	font-size: 4.3vmin;
	line-height: 4.3vmin;
	letter-spacing: 0;
	color: #282828;
	margin: 0.8vh auto;
}
p{
	line-height: 3vmin;
	margin: 2vmin 0;
}
a{
	color: #53cd7f;
	text-decoration: none;
}

.primary{
	color: #53cd7f;
}

.primary-bkg{
	background-color: #53cd7f;
}

/* Clearfix: http://cssmojo.com/the-very-latest-clearfix-reloaded */
.clearfix::after {
	display:block;
	clear:both;
	content:" ";
}

@media only screen and (max-width:600px) {
	body{
		font-size: 4vmin;
	}
	h1{
		font-size: 8.6vmin;
		line-height: 8.6vmin;
		margin: 1.6vh auto;
	}
	p{
		line-height: 6vmin;
		margin: 4vmin 0;
	}
}

