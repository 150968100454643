
@import 'variables';
@import 'keyframes';
@import 'placeholders';

.MakeInvestment {
	@extend %flex-center;
	@extend %asset-page-content;
  	position: relative;
	text-align: center;
}
.MakeInvestment.special-access {
	flex-direction: column;
	height: 100%;
	width: 100%;
}

.MakeInvestment .MakeInvestment-wrapper {
	margin: 0 10vmin;
	height: 100%;
	display: flex;
	align-items: center;

	&--100 {
		width: 100%;
		height: 100%;
	}
}
.MakeInvestment .MakeInvestmentForm-container-contents{
	min-height: 30vh;
}

.MakeInvestment p {
	font-size: 2.2vh;
	line-height: 4.4vh;
}
.MakeInvestment small {
	display: inline-block;
	line-height: 3.4vh;
}
.MakeInvestment p.light {
	font-size: 1.8vh;
	line-height: 3.2vh;
	color: #666;
}
.MakeInvestment h2 {
	font-size: 2.6vh;
	font-weight: 900;
}

.MakeInvestment .Input,
.MakeInvestment .USStatesSelect{
	width: 100%;
	margin-bottom: 4vh;
}

.MakeInvestment .Input input,
.MakeInvestment .USStatesSelect .USStatesSelect-select,
.MakeInvestment .USStatesSelect .USStatesSelect-value{
	font-size: 3vh;
}

.MakeInvestment .BackNextButtons .TextButton-right-arrow {
	padding-left: 1.6vh;
}
.MakeInvestment .BackNextButtons .TextButton-left-arrow {
	padding-right: 1.6vh;
}

.MakeInvestment-gift-banner {
	position: absolute;
	display: flex;
	align-items: center;
	left: 0;
	top: 0;
	margin: 2.6vmin;
	font-size: 24px;
	font-weight: bold;
	color: #fff;
	animation: fade-in .2s linear;
	span {
		padding: 3px 26px 3px 20px;
		border-radius: 4px;
		background-color: $rally-primary;
	}
	img {
		margin-left: -18px;
		width: 44px;
		height: 44px;
	}
}

@media only screen and (max-width:600px) {
	.MakeInvestment {
		height: 100%;
	}
	.MakeInvestment .MakeInvestment-wrapper {
		margin: 0 8vmin;
	}
	.MakeInvestment p {
		font-size: 2.8vh;
		line-height: 5.4vh;
	}
	.MakeInvestment p.light {
		font-size: 2.2vh;
		line-height: 3.4vh;
	}
	.MakeInvestment h2 {
		font-size: 3.2vh;
	}
	.MakeInvestment .Input input,
	.MakeInvestment .USStatesSelect .USStatesSelect-select,
	.MakeInvestment .USStatesSelect .USStatesSelect-value{
		font-size: 4vh;
		padding: 1.4vmin 2vmin;
	}
	.MakeInvestment-gift-banner {
		margin: 5vmin 0;
		font-size: 18px;
		span {
			padding: 4px 24px 4px 16px;
			border-radius: 0;
		}
		img {
			width: 36px;
			height: 36px;
		}
	}
}
