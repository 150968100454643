
.UpCaret{
	display: flex;
	align-items: center;
	justify-content: center;
}
.UpCaret svg{
	fill: currentColor;
	width: 3vmax;
	height: 3vmax;
	margin-bottom: 0.5vmax;
}