.AdminButton {
	font-family: 'Muli', sans-serif;
	border-radius: 100vmin;
	border: 0.26vmin solid #313131;
	font-size: 1.5vmin;
	font-weight: 900;
	color: #313131;
	background-color: rgba(0,0,0,0);
	transition: background-color 0.2s;
	text-transform: uppercase;
	letter-spacing: 0.05vmin;
	margin: 0.6vmin 1.6vmin;
	padding: 0.6vmin 4vmin;
	position: relative;
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	justify-content: center;

	@media only screen and (max-width:600px) {
		font-size: 3vmin;
		margin: 1.2vmin 3.2vmin;
		padding: 1.2vmin 8vmin;
		border-width: 0.5vmin;
	}
	
	/* ----- AdminButton states ----- */	

	&:focus {
		outline: none;
	}
	&:disabled, &[disabled] {
		background-color: lightgrey;
		border-color: #666;
	  color: white;
	  cursor: default;
	}
	&:hover:not([disabled]) {
		background-color: #313131;
		color: white;
	}

	/* ----- AdminButton themes ----- */	
	
	&.light {
		/*color: darkgrey;*/
		background: none;
		transition: all 0.2s;
	}
	&.light:hover:not([disabled]) {
		color: #53cd7f;
		border-color: #53cd7f;
		background: none;
	}
	&.light:disabled, &.light[disabled] {
		color: lightgrey;
	  background: none;
	}

	/* ----- AdminButton arrows ----- */

	&.AdminButton-right-arrow.AdminButton-light:not([disabled]){
		color: #53cd7f;
	}
	&.AdminButton-left-arrow, &.AdminButton-right-arrow{
		padding: 2.1vmin 8vmin;
		letter-spacing: 0.2vmin;
		font-size: 2.3vmin;

		@media only screen and (max-width:600px) {
			padding: 4vmin 16vmin;
			letter-spacing: 0.3vmin;
			font-size: 5.6vmin;
		}
	}
	&.AdminButton-right-arrow b{
		font-weight: 800;
	}
	&.AdminButton-right-arrow::after, &.AdminButton-left-arrow::before{
		font-size: 1.7vmin;
		margin-top: -0.36vmin;
		font-weight: 600;
		position: absolute;
		transform: scaleY(1.6) scaleX(0.8);

		@media only screen and (max-width:600px) {
			font-size: 4.4vmin;
			margin-top: -1vmin;
		}
	}
	&.AdminButton-right-arrow::after{
		content: '>';
		right: 5.4vmin;

		@media only screen and (max-width:600px) {
			right: 6vmin;
		}
	}
	&.AdminButton-left-arrow::before{
		content: '<';
		left: 5.4vmin;

		@media only screen and (max-width:600px) {
			left: 6vmin;
		}
	}

	/* ----- AdminButton content ----- */	

	b {
		font-weight: 700;
	}
	span {
		display: inline;
	}
}
