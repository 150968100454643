.SocialSecurityNumber {
	padding: 0!important;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	max-height: 100%;
}

.SocialSecurityNumber .topWrapper {
	flex-grow: 1;
	flex-shrink: 1;
	overflow: auto;
}

.SocialSecurityNumber .bottomWrapper {
	flex-shrink: 0;
}

.SocialSecurityNumber p.light{
	margin-bottom: 6vh;
	line-height: 1.4;
}

.SocialSecurityNumber .Input{
	margin-bottom: 8vh;
}

.SocialSecurityNumber .SocialSecurityNumber-TOS{
	display: inline-flex;
	justify-content: space-between;
	margin-bottom: 6vh;
}

.SocialSecurityNumber .SocialSecurityNumber-TOS.hidden{
	display: none;
}

.SocialSecurityNumber .SocialSecurityNumber-TOS label{
	width: 85%;
}

.SocialSecurityNumber .Checkbox .Checkbox-input{
	margin-top: 0.2vh;
}

.SocialSecurityNumber .SocialSecurityNumber-TOS-label{
	color: #444;
	font-size: 1.5vh;
	line-height: 2.2vh;
	text-align: left;
	margin: 0;
}

.SocialSecurityNumber .footerText {
	font-size: .65em;
	color: #929591;
	line-height: 1.4;
	margin-top: 60px;
	margin-bottom: 30px;
}

