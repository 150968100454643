.Fullpage {
	position: fixed;
	left: 0;
	right: 0;
	top: 10vh;
	bottom: 0;
	height: auto;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	background-color: #fff;
	overflow: auto;
	z-index: 1;

  .profile-update & {
    height: calc(100% - 10vh);
  }

	.Fullpage-contents {
		max-height: 100%;
	}
}

@media only screen and (max-width:600px) {
	.Fullpage {
		top: 7%;
    
    .profile-update & {
      height: calc(100% - 7%);
    }
	}
}