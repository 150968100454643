.Congrats .Congrats-title{
	font-weight: 900;
	font-size: 3.7vh;
}

.Congrats .Congrats-title .Congrats-title-emphasis{
	color: #41c84b;
}

.Congrats .Congrats-description{
	margin-bottom: 8vh;
	font-size: 2.6vh;
}

.Congrats .SecondaryButton{
	letter-spacing: 0.8vh;
	padding: 2vh 12vh;
}

@media only screen and (max-width:600px) {
	.Congrats .Congrats-title .Congrats-title-emphasis{
		display: block;
	}
}