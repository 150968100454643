@import 'variables';

.MakeInvestment .SharesSelect p {
	margin-top: 5.4vh;
	font-size: 2.6vh;
	line-height: 3.4vh;
}

.MakeInvestment .SharesSelect h2 {
	margin-bottom: 0;
}

.SharesSelect .NumberInput {
  max-width: 12vmin;
  @media only screen and (max-width:600px) {
    max-width: 21vmin;
  }
}

.SharesSelect .Price {
  font-weight: 900;
}

.SharesSelect-gift-btn {
  display: inline-flex;
  align-items: center;
  margin-top: 2vh;
  font-size: 2vh;
  font-weight: 800;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: .15s linear;
  img {
    width: 32px;
    height: 32px;
    margin-right: 10px;
    transition: .15s linear;
  }
  &:hover {
    color: $rally-primary;
    img {
      transform: scale(1.1);
    }
  }
}

@media only screen and (max-height:500px) {
  .MakeInvestment .SharesSelect p {
    font-size: 3.3vh;
}
}