.Menu{
	margin: 0 auto;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}


@media only screen and (max-width:600px) {
	.Menu{
		font-size: 3.4vh;
	}
}


.Menu {
	*, :after, :before {
		box-sizing: inherit;
	}
	.hamburger {
		transform: scale(0.8);
		font: inherit;
		display: inline-block;
		overflow: visible;
		margin: 0;
		// padding: 1.5vh;
		cursor: pointer;
		transition-timing-function: linear;
		transition-duration: .15s;
		transition-property: opacity,filter;
		text-transform: none;
		color: inherit;
		border: 0;
		background-color: transparent;
		.hamburger-box {
			position: relative;
			display: inline-block;
			width: 4.0vh;
			height: 2.4vh;
			.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
				position: absolute;
				width: 4.0vh;
				height: 0.4vh;
				transition-timing-function: ease;
				transition-duration: .15s;
				transition-property: transform;
				border-radius: 0.4vh;
				background-color: #333;
			}
		}
	}

	.hamburger-inner {
		top: 50%;
		display: block;
		margin-top: -0.2vh;
	}
	.hamburger-inner:before {
		top: -1.0vh;
	}
	.hamburger-inner:after {
		bottom: -1.0vh;
	}
	.hamburger-inner:after, .hamburger-inner:before {
		display: block;
		content: "";
	}

	.hamburger--slider .hamburger-inner {
		top: 0.2vh;
	}
	.hamburger--slider .hamburger-inner:before {
		top: 1.0vh;
		transition-timing-function: ease;
		transition-duration: .15s;
		transition-property: transform,opacity;
	}
	.hamburger--slider .hamburger-inner:after {
		top: 2.0vh;
	}
	.hamburger--slider.is-active .hamburger-inner {
		transform: translate3d(0,1.0vh,0) rotate(45deg);
	}
	.hamburger--slider.is-active .hamburger-inner:before {
		transform: rotate(-45deg) translate3d(-0.571429vh,-0.6vh,0);
		opacity: 0;
	}
	.hamburger--slider.is-active .hamburger-inner:after {
		transform: translate3d(0,-2.0vh,0) rotate(-90deg);
	}
}




