.AddToCalendarButton {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
}

.AddToCalendarButton-btn {
  width: 4.4vmin;
  height: 4.4vmin;
  padding: 0;
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  svg {
    width: 100%;
    height: auto;
    transition: .3s;
  }

  &:hover svg * {
    fill: #5f5e5e;
  }
}

.AddToCalendarButton-options {
  position: absolute;
  width: 28vmin;
  box-sizing: border-box;
  top: -8vmin;
  left: -18.4vmin;
  background: #fff;
  box-shadow: 0 1px 12px 2px rgba(0,0,0, .2);
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  animation: options-fade-in .2s linear;
  font-size: 1.8vmin;
  z-index: 10;
}

.AddToCalendarButton-options-title {
  padding: 2vmin 0 1vmin;
  font-weight: bold;
  color: #4c4c4c;
  text-transform: uppercase;
  text-align: center;
}

.AddToCalendarButton-option {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 1.3vmin 2vmin;
  color: #181818;
  cursor: pointer;
  &:hover {
    background-color: #e0e0e0;
  }

  img {
    display: inline-block;
    width: 3vmin;
    height: auto;
    margin-right: 1vmin;
  }

  small {
    padding-top: 3px;
    padding-left: 5px;
    font-size: 1.2vmin;
  }
}

@media only screen and (max-width:600px) {
  .AddToCalendarButton-btn {
    width: 6.4vmin;
    height: 6.4vmin;
  }
  .AddToCalendarButton-options {
    width: 46vmin;
    top: -14vmin;
    left: -32.4vmin;
    font-size: 3.2vmin;
  }

  .AddToCalendarButton-options-title {
    text-align: left;
    padding: 4vmin 2vmin 2vmin 4vmin;
  }

  .AddToCalendarButton-option {
    padding: 3vmin 4vmin;
    img {
      width: 5vmin;
      padding-right: 1.4vmin;
    }
    small {
      font-size: 2.6vmin;
      padding-left: 3px;
    }
  }
}

@keyframes options-fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
