.AssetDetails {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.AssetDetails .slider,
.AssetDetails .slider-list,
.AssetDetails .slider-slide,
.AssetDetails div.slider-frame {
  height: 100% !important;
  touch-action: pan-y;
}
.AssetDetails .slider.special-access,
.AssetDetails .slider-list.special-access,
.AssetDetails .slider-slide.special-access,
.AssetDetails div.slider-frame.special-access {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.AssetDetails .slider-slide {
  transition: none !important;
  transform: none !important;
}
.AssetDetails .slider.special-access,
.AssetDetails .slider-list.special-access,
.AssetDetails .slider-slide.special-access,
.AssetDetails div.slider-frame.special-access {
  display: flex;
  flex: 1;
  flex-direction: column;
}


.AssetDetails .AssetDetails-dots {
  position: absolute;
  right: 0;
  z-index: 10;
}
.AssetDetails .AssetDetails-carousel,
.AssetDetails .AssetDetails-flat {
  width: 100%;
  height: 100%;
}
.AssetDetails .AssetDetails-carousel {
  display: block;
}
.AssetDetails .AssetDetails-flat {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  position: relative;
}


.AssetDetails .AssetDetailsCarousel-swipeableitem {
  display: flex;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  left: 0;
  right: 0;
  position: absolute;
  will-change: transform;
}

.AssetDetails .AssetDetailsCarousel-swipeableitem > div {
  width: 100%;
  position: relative;
  will-change: transform;
}

.AssetDetails .AssetDetailsCarousel-swipeableitem > div.AssetTimeline,
.AssetDetails .AssetDetailsCarousel-swipeableitem > div.AssetStory {
  background: #fff;
}

.AssetDetails .AssetDetailsCarousel-swipeableitem > div.AssetQuotes {
  background-size: cover;
  background-position: center;
}

.AssetDetails .AssetDetailsCarousel-swipeableitem > div.AssetQuotes:before {
  content: '';
  position: absolute;
  background: rgba(26, 26, 26, 0.85);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.AssetDetails.trading .AssetDetails-gutter {
  position: absolute;
  height: auto;
}
    
.AssetDetails .AssetDetails-assetActionbutton{
  position: relative;
  display: flex;
  width: calc(100% - 23vmin);
  margin: 0 10vmin 2vh 10vmin;
  height: 100%;
  justify-content: center;
}

.AssetDetails.trading .AssetDetails-assetActionbutton{
  margin: 0;
  width: 100%;
}

.AssetDetails-nextslide {
  position: absolute;
  bottom: 0;
  font-size: 2.8vh;
}

.AssetDetails-nextslide  .TextButton{
  color: rgba(255, 255, 255, 0.5);
  padding: 2vh;
  font-weight: 400;
  transform: scaleX(2);
}

.AssetDetails-lastslide {
  font-family: 'Cardo', serif;
  font-style: italic;
  position: absolute;
  bottom: 0;
  background: #2d2f30;
  color: #686e6f;
  width: 100%;
  padding: 0.8vh;
  text-align: center;
  font-size: 1.8vh;
  font-weight: bold;
}

.AssetDetails-lastslide a,
.AssetDetails-lastslide a:hover {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.AssetDetails.trading .AssetDetails-nextslide, .AssetDetails.trading .AssetDetails-lastslide{
  display: none;
}

.AssetDetails-gutter {
  width: 100%;
  height: 15vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
}

.AssetDetails-gutter.trading {
  height: 21vh;
}

.AssetDetails-gutter.dark{
  background-color: orange;
  position: absolute;
  bottom: 0;
  height: 20%;
  z-index: 15;
}

.AssetDetails-gutter.dark .AssetDetails-assetActionbutton{
  margin: 0;
}
.AssetDetails-gutter.dark .AssetDetails-nextslide,
.AssetDetails-gutter.dark .AssetDetails-lastslide{
  display: none;
}

.AssetDetails-carousel.index-3 .AssetDetails-nextslide .TextButton{
	color: #444;
}

.AssetDetails-assetActionbutton-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.AssetDetails-gutter.trading .AssetDetails-assetActionbutton-container {
  align-items: center;
}

.AssetDetails-assetActionbutton-container .NotifyMeButton {
  width: 70vmin;
  height: 8vmin;
  margin-right: 3vmin;
  font-size: 1.3em;
}

.AssetDetails-assetActionbutton-container.hidden-calendar .NotifyMeButton {
  margin-right: 0;
}

.AssetDetails-assetActionbutton-container .NotifyMeButton svg {
  width: 2vmax;
  height: auto;
}

.AssetDetails-assetActionbutton-container .SecondaryButton {
  font-family: Muli;
  font-weight: 900;
  padding-left: 0;
  padding-right: 0;
  margin-right: 0;
  margin-left: 0;
  width: 100%;
  max-width: 100%;
}

.AssetDetails-assetCalendar-link {
  border: 0.4vmin solid #53cd7f;
  border-radius: 50%;
  width: 8vmin;
  height: 8vmin;
  transition: .2s;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.AssetDetails-assetCalendar-link svg {
  width: 65%;
  height: auto;
  color: #53cd7f;
}
.AssetDetails-assetCalendar-link svg * { 
  transition: .2s; 
}

.AssetDetails-assetCalendar-link:hover {
  background-color: #53cd7f;
}

.AssetDetails-assetCalendar-link:hover svg * { 
  fill: #fff; 
}


@media only screen and (max-width:600px) {
  .AssetDetails .AssetDetails-nextslide {
    display: none;
  }
  .AssetDetails .AssetDetails-lastslide{
    /*position: relative;*/
    /*margin-top: -3.4vh;*/
    bottom: 0;
    position: absolute;
  }

  .AssetDetails.special-access .AssetDetails-gutter {
    height: 16vh;
  }

  .AssetDetails-assetActionbutton-container .NotifyMeButton {
    width: 70vmin;
    height: 6.5vmax;
    height: 14vmin;
    max-width: 500px;
    margin-right: 3vmin;
    border-width: 0.6vmin;
    font-size: 1em;
  }

  .AssetDetails-assetActionbutton-container.hidden-calendar .NotifyMeButton {
    margin-right: 0;
  }

  .AssetDetails-assetCalendar-link {
    border-width: 0.6vmin;
    width: 6.5vmax;
    height: 6.5vmax;
    width: 14vmin;
    height: 14vmin;
  }
}
