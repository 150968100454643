$bid-button-font-size: 2.3vmin;
$bid-button-vertical-padding: $bid-button-font-size / 2;
$bid-button-horizontal-padding: $bid-button-font-size * 6 / 5;
$secondary-button-border-width: 0.4vmin;
.BidAskButtons{
	align-items: flex-start;
	background-color: #333;
	position: relative;
	bottom: 0;
	height: 100%;
	width: 100%;
	z-index: 15;
	padding: 0;
	.BidAskButtons-contents {
		display: flex;
		align-items: center;
		flex-direction: column;
		height: 100%;
		.BidAskButtons-disclaimer{
			color: rgba(255, 255, 255, 0.8);
			margin: 2% 8% 1%;
			font-size: 1.6vmin;
			text-align: center;
		}
		.BidAskButtons-buttons{
			display: flex;
			padding-top: 15px;
			.Button, .SecondaryButton {
				font-size: $bid-button-font-size;
				.Button-contents {
					white-space: nowrap;
				}
			}
			.BidAskButtons-bid {
				.Button {
					padding: ($bid-button-vertical-padding + $secondary-button-border-width) ($bid-button-horizontal-padding + $secondary-button-border-width);
				}
			}
			.BidAskButtons-ask {
				.SecondaryButton {
					padding: $bid-button-vertical-padding $bid-button-horizontal-padding;
					color: white;
					&:not([disabled]) {
						border-color: #c04c47;
					}
					&:disabled, &[disabled] {
						color: #666;
					}
					&:hover:not([disabled]) {
						background-color: #c04c47;
					}
				}
			}
		}
		.BidAskButtons-bidlist{
			padding-bottom: 15px;

			.TextButton{
				color: white;
				font-size: 1.6vmin;
				padding: 0 1vmin 1vmin 1vmin;
				font-weight: 600;
				.UpCaret{
					svg{
						margin: 0 0.3vmin;
						width: 2.6vmin;
						height: 2.6vmin;
					}
				}
				.BidAskButtons-green {
					color: #53cd7f;
				}
				.BidAskButtons-rotate{
					.UpCaret{
						svg{
							transform: rotate(180deg);
							margin-top: 0.2vmin;
							margin-bottom: -0.2vmin;
						}
					}
				}
			}
		}
		.BidAskButtons-footer {
			width: 100%;
			text-align: center;
			padding: 5px;
			margin-top: -5px;
			background-color: #474B4A;
			flex-grow: 1;
			box-sizing: border-box;
			.BidAskButtons-footertext {
				.TextButton{
					color: #9BA0A5;
					font-size: 1.6vmin;
					padding: 0 1vmin 1vmin 1vmin;
					font-weight: 600;
					.BidAskButtons-green {
						color: #53cd7f;
						font-weight: 800;
						margin-left: .4em;
					}
				}
			}
		}
	}
}

.trading .BidAskButtons {
	background-color: #575D5E;
}

@media only screen and (max-width:750px) {
	$bid-button-font-size: 4vmin;
	$bid-button-vertical-padding: $bid-button-font-size / 2;
	$bid-button-horizontal-padding: $bid-button-font-size * 6 / 5;
	$secondary-button-border-width: 0.4vmin;
	.BidAskButtons {
		.BidAskButtons-contents {
			.BidAskButtons-disclaimer {
				font-size: 2.2vmin;
			}
			.BidAskButtons-buttons{
				.Button {
					font-size: $bid-button-font-size;
				}
				.BidAskButtons-bid {
					.Button {
						padding: ($bid-button-vertical-padding + $secondary-button-border-width) ($bid-button-horizontal-padding + $secondary-button-border-width);
					}
				}
				.BidAskButtons-ask {
					.SecondaryButton {
						padding: $bid-button-vertical-padding $bid-button-horizontal-padding;
						font-size: $bid-button-font-size;
					}
				}
			}
			.BidAskButtons-bidlist{
				.TextButton{
					font-size: 2.4vmin;
				}
			}
		}
	}
}

@media only screen and (max-width:600px) {
	$bid-button-font-size: 4.6vmin;
	$bid-button-vertical-padding: $bid-button-font-size / 2;
	$bid-button-horizontal-padding: $bid-button-font-size * 6 / 5;
	$secondary-button-border-width: 0.8vmin;
	.BidAskButtons {
		.BidAskButtons-contents {
			.BidAskButtons-disclaimer {
				font-size: 2.8vmin;
				margin-bottom: 1%;
			}
			.BidAskButtons-buttons{
				.Button {
					font-size: $bid-button-font-size;
				}
				.BidAskButtons-bid {
					.Button {
						padding: ($bid-button-vertical-padding + $secondary-button-border-width) ($bid-button-horizontal-padding + $secondary-button-border-width);
					}
				}
				.BidAskButtons-ask {
					.SecondaryButton {
						padding: $bid-button-vertical-padding $bid-button-horizontal-padding;
						font-size: $bid-button-font-size;
					}
				}
			}
			.BidAskButtons-bidlist{
				.TextButton{
					font-size: 3vmin;
					margin-top: 0;
				}
			}
		}
	}
}
