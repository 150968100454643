.NewPassword {
  text-align: center;
  font-size: 1.6vmin;
}

.NewPassword-box {
  border: 0.05vmin solid #d9d9d9;
  padding: 2.5vmin 5.5vmin;
}

.NewPassword .NewPassword-logo {
  height: 8vh;
  margin-bottom: 2vh;
  text-align: center;
}

.NewPassword .NewPassword-logo svg {
  height: 12vh;
}

.NewPassword .NewPassword-form,
.NewPassword .NewPassword-success {
  height: 100%;
  margin: 0 auto;
  text-align: center;
  font-size: 2.5vmin;
  font-weight: 400;
  color: #333;
}

.NewPassword .NewPassword-form .NewPassword-form-title {
  margin-bottom: 3.2vh;
  font-size: 1.8vmin;
}

.NewPassword .NewPassword-inputs {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.NewPassword .NewPassword-form .Input {
  display: flex;
  width: 100%;
}

.NewPassword .NewPassword-form .Input input {
  width: 33vmin;
  display: block;
  border: 0.02vmin solid #28352e;
  margin-bottom: 2vmin;
  font-size: 2vmin;
  text-align: left;
  background: #fff;
  padding: 1.3vmin;
}

.NewPassword .NewPassword-form .Input:nth-child(3) {
  margin-bottom: 0;
}

.NewPassword .NewPassword-error {
  font-size: 1.6vmin;
  color: #dd2200;
  margin: 1.4vh;
}

.NewPassword .NewPassword-restrictions {
  color: #777;
  font-size: 1.6vh;
  line-height: 2.4vh;
  width: 33vmin;
}

.NewPassword .AuthenticationPages-actionButton {
  border: none;
  background-color: rgb(226, 201, 141);
}

@media only screen and (max-width: 600px) {
  .NewPassword .NewPassword-box {
    border: none;
  }

  .NewPassword .NewPassword-form {
    border: 0;
    padding: 0;
    margin-bottom: 5vmin;
  }

  .NewPassword .NewPassword-form .Input input {
    width: 100%;
    margin-bottom: 5vmin;
    padding: 3.5vmin 2.5vmin;
    font-size: 4.2vmin;
    box-sizing: border-box;
    text-align: center;
    caret-color: white;
  }
  .NewPassword .NewPassword-form .NewPassword-form-title {
    font-size: 6vmin;
    font-weight: 500;
  }
  .NewPassword .NewPassword-error {
    font-size: 5vmin;
    margin: 4vmin 0 0;
  }
  .NewPassword .NewPassword-restrictions {
    font-size: 4vmin;
    line-height: 5.6vmin;
    width: 100%;
  }
  .NewPassword .AuthenticationPages-actionButton {
    font-size: 4.5vmin;
    padding: 4vmin 4vmin;
    width: 100%;
  }
}
