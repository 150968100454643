
.UserLogin{
	width: auto;
	height: 5vh;
	display: flex;
	flex-direction: row;
	align-items: center;
}
.UserLogin .UserLogin-email{
	margin: 0 2vh;
	color: #222;
	font-size: 1.8vh;
	line-height: 5vh;
	font-weight: 800;
}
.UserLogin .UserLogin-button{
	width: 16vh;
	height: 5vh;
	font-size: 1.8vh;
	letter-spacing: 0.2vh;
	line-height: 5vh;
	align-self: center;
	background-color: white;
	border: 1px solid #CCC;
	font-weight: 600;
	color: #AAA;
	padding: 0;
	text-align: center;
}

.UserLogin .UserLogin-button:hover, .UserLogin .LinkButton.light a:hover{
	cursor: pointer;
	color: #666;
}

.UserLogin .UserLogin-link a.active{
	color: #CCC;
}
.UserLogin .UserLogin-link a {
	color: #53c97a;
}
.UserLogin .UserLogin-link.UserLogin-login a{
	color: #b9b9b9;
}
.UserLogin .UserLogin-link.UserLogin-login:hover a{
	color: #a9a9a9;
}

.UserLogin .UserLogin-divider{
	height: 2.4vh;
	width: 0.1vh;
	margin: 0 1vh;
	background: #999;
}

@media only screen and (max-height:500px) {
	.UserLogin{
		height: 25px;
	}
	.UserLogin .UserLogin-email, .UserLogin .UserLogin-button{
		font-size: 9px;
		line-height: 25px;
	}
	.UserLogin .UserLogin-button{
		width: 80px;
		height: 25px;
	}
	.UserLogin .UserLogin-button.login{
		font-size: 7px;
		letter-spacing: 1.25px;
	}
}