.StateAvailabilityError {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  justify-content: center;
  text-align: center;

  h2 {
    font-size: 26px;
    margin-bottom: 3vmin;
  }

  .StateAvailabilityError-desc {
    max-width: 600px;
    font-size: 22px;
    line-height: 1.8;
    margin-bottom: 3.2vh;
    text-align: center;
  }

  .StateAvailabilityError-cancel {
    font-size: 23px;
    font-weight: 500;
    color: #9e9e9e;
  }

  .StateAvailabilityError-success {
    font-size: 24px;
    line-height: 1.6;
    font-weight: 700;
    color: #53cd7f;
    margin: 0 0 3.2vh;
  }
}

@media only screen and (max-width:600px) {
  .StateAvailabilityError {
    h2 {
      font-size: 16px;
    }
    .StateAvailabilityError-desc {
      font-size: 16px;
      line-height: 1.8;
    }
    .StateAvailabilityError-cancel {
      font-size: 16px;
    }
    .StateAvailabilityError-success {
      font-size: 18px;
    }
  }
}