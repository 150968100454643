@import 'variables';

.AssetPreview{
	display: flex;
	margin-top: 5.6vh;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	height: auto;
	align-items: center;
	flex-direction: column;
	text-align: center;
	justify-content: center;
	vertical-align: middle;

	&:focus {
		outline: none;
	}
	
	.AssetPreview-ticker{
		margin-bottom: 4px;
    font-family: 'Gotham Black', sans-serif;
		font-weight: 900;
		font-size: 4.4vh;
		line-height: 4vh;
		letter-spacing: -0.3vh;
    color: #323232;
	}
	
	.AssetPreview-price{
		padding-bottom: 2.2vh;
    font-family: 'Gotham Black', sans-serif;
		font-size: 9vh;
		line-height: 7vh;
		letter-spacing: -0.2vh;
		color: $new-green;
		position: relative;
	}
	
	.AssetPreview-price-shift {
    display: block;
		line-height: 5vh;
		font-family: 'Gotham Medium', sans-serif;
		font-weight: 900;
		font-size: 2.5vh;
		white-space: nowrap;
		text-transform: uppercase;
		&.AssetPreview-price-shift-negative {
			color: $rally-red;
		}
		img {
			max-height: 3.2vh;
			margin-right: 6px;
		}
	}

	.AssetPreview-price .AssetPreview-price-symbol{
		font-size: 5vh;
		line-height: 5vh;
		vertical-align: top;
	}

	.AssetPreview-imagewrapper{
		min-height: 36vmin;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
    margin-bottom: 2vh;
	}

	.AssetPreview-image {
		display: flex;
		justify-content: center;
		align-items: center;
		max-height: 28vh;
		position: relative;
	}

	.AssetPreview-image img{
		max-height: 36vh;
		margin: 0 auto;
	}

	.AssetPreview-sharesOwned {
    position: absolute;
    top: -6vmin;
    right: 3vmin;
    border: 4px solid $new-green;
    border-radius: 25px;
		padding: 4px 6px;
		font-size: 0.9em;
    background: #fff;
	}

	.AssetPreview-sharesOwned span {
		color: $new-green;
    font-family: Bebasneue;
	}
	
	.AssetPreview-name{
		font-size: 3.2vh;
		line-height: 3.8vh;
		text-align: center;
		font-weight: 900;
		text-transform: uppercase;
    color: #323232;
		padding: 0 10px;
	}

	.AssetPreview-cta{
		display: flex;
		align-items: center;
		justify-content: center;
		.Button, .SecondaryButton {
			min-width: 75%;
			margin: 2.2vh auto 1.2vh auto;
		}
		.SecondaryButton {
			display: flex;
			align-items: center;
			justify-content: center;
			border: 0.4vmin solid $new-green;
			font-size: 2.6vmin;
			font-weight: 800;
			color: $new-green;
			&:hover {
				color: white;
			}
		}
		.Button {
			font-family: Muli;
			font-weight: 900;
			font-size: 3.4vh;
			padding-left: 6vh;
			padding-right: 6vh;
			white-space: nowrap;
      background-color: $new-green;
		}
	}

	.AssetPreview-status{
		font-family: 'Gotham book';
    font-weight: 200;
		font-size: 1.8vh;
		text-align: center;
    color: #323232;
	}

  .AssetPreview-red-text {
    color: $rally-red;
    font-family: 'Gotham bold';
  }
  .AssetPreview-complete {
    color: #737373;
  }

	.AssetPreview-funded{
		color: #41c84b;
		font-weight: 600;
	}
}

@media only screen and (max-width:600px) {
	.AssetPreview{
		margin-top: 5vh;
		&.Trading {
			.AssetPreview-imagewrapper {
				min-height: 29vh;
			}
			.AssetPreview-price {
				padding-bottom: 3.8vh;
			}
		}
		.AssetPreview-ticker{
			font-size: 7vmin;
			line-height: 7vmin;
		}
		.AssetPreview-price{
			font-size: 15vmin;
			line-height: 14vmin;
		}
		.AssetPreview-price .AssetPreview-price-symbol{
			font-size: 3.1vh;
			margin-right: 1vmin;
		}
		.AssetPreview-price .AssetPreview-price-shift{
			font-size: 2.4vh;
		}
		.AssetPreview-imagewrapper{
			min-height: 32vh;
		}
		.AssetPreview-image{
			max-height: 32vh;
			img{
				width: auto;
				max-width: 100%;
				max-height: 32vh;
			}
		}
		.AssetPreview-name{
			font-size: 4.5vmin;
			line-height: 4.1vmin;
			height: auto;
			padding-bottom: 2vh;
			overflow: hidden;
		}
		.AssetPreview-cta{
			width: 100%;
			.Button{
				font-size: 4vmin;
				padding-top: 5.8vmin;
				padding-bottom: 5.8vmin;
				margin-top: 1.6vh;
				min-width: 100%;
			}
			.SecondaryButton{
				font-size: 4vmin;
				border-width: 0.5vmin;
				padding-top: 5.8vmin;
				padding-bottom: 5.8vmin;
				min-width: 100%;
			}
		}
		.AssetPreview-status {
			font-size: 1.4vh;
		}
	}
}

@media only screen and (max-height:560px) {
	.AssetPreview.Trading {
		.AssetPreview-imagewrapper {
			min-height: 28vh;
		}
		.AssetPreview-image {
			max-height: 26vh;
		}
		.AssetPreview-price {
			font-size: 12vmin;
			padding-bottom: 3vh;
		}
	}
}

@media only screen and (max-width:750px) {
	.AssetPreview {
		.AssetPreview-image {
			margin: 1vh 0 1vh 0;
		}
	}
}
