

.BankAccountType h2{
	margin-bottom: 16vh;
}

.BankAccountType .YesNoSelect{
	margin-bottom: 16vh;
}

.BankAccountType .SelectButton{
	width: 21vh;
	margin: 1vh 1.2vh;
	font-size: 2.3vh;
	letter-spacing: 0.4vh;
	color: #333;
}

.BankAccountType .BackNextButtons {
	margin-bottom: 20vh;
}