@import 'variables';

.ReviewBid {
  margin-top: 2vh;
	.ReviewBid-explanation{
    font-family: 'Gotham Book', sans-serif;
		text-align: left;
		font-size: 2.4vh;
		line-height: 1.4;
		margin-bottom: 2vh;
	}
	.ReviewBid-description{
		text-align: left;
		color: $grey;
		font-size: 1.8vh;
		line-height: 2.4vh;
		margin-bottom: 2vh;
    a {
      color: inherit;
      text-decoration: underline;
      transition: color .3s;
      &:hover {
        color: #fff,
      }
    }
	}
  .ReviewBid-tw-description{
		text-align: left;
		font-size: 2.4vh;
		margin-bottom: 3vh;
  }
	.ReviewBid-info{
		border-top: 1px solid #C4C4C4;
		border-bottom: 1px solid #C4C4C4;
		margin: 1.8vmin 0;
		padding: 1.4vmin 0;
		text-transform: uppercase;
		font-weight: 800;
		.ReviewBid-info-title{
			color: $new-green;
      font-family: 'Gotham Bold', sans-serif;
			font-size: 1.6vmin;
      margin-bottom: 1vh;
			.ReviewBid-red{
				color: $rally-red;
			}
		}
	}
	.PlaceBid-fields{
		margin: 2.6vmin 0;
		.PlaceBid-field{
			height: 5vmin;
			width: 20vmin;
      .PlaceBid-field-label{
        font-size: 1.7vh;
      }
      .PlaceBid-field-content {
        font-family: 'Gotham Book', sans-serif;
        font-weight: 400;
      }
			&.ReviewBid-ordercost{
				.PlaceBid-field-content{
					color: white;
					font-weight: 800;
					.Price{
						color: $rally-orange;
					}
				}
			}
		}
	}
	.ReviewBid-estimatedcost{
		margin: 6vmin 0 4vmin 0;
	}

	.Button {
		overflow: hidden;
		.DotsLoader {
			position: absolute;
			align-items: center;
			justify-content: center;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			background-color: $rally-green;
			z-index: 1;
		}
	}

	.TextButton{
		color: $grey;
		font-weight: 600;
		font-size: 2.2vmin;
	}
}

@media only screen and (max-width:600px) {
	.ReviewBid {
		.ReviewBid-explanation{
			font-size: 1.8vh;
			line-height: 1.25;
			margin-bottom: 3.4vh;
		}
		.ReviewBid-description{
			font-size: 1.4vh;
			line-height: 1.2;
      margin-bottom: 2.7vh;
		}
		.ReviewBid-tw-description{
      font-size: 1.8vh;
      margin-bottom: 2vh;
    }
		.ReviewBid-info{
			margin: 2.5vh 0;
			padding: 1.6vh 0;
			.ReviewBid-info-title{
				font-size: 1.5vh;
			}
		}
		.PlaceBid-fields{
			margin: 4.2vmin 0;
			.PlaceBid-field{
				height: 9vmin;
				width: 36vmin;
        .PlaceBid-field-label{
          font-size: 1.4vh;
        }
			}
		}
		.ReviewBid-estimatedcost{
			margin: 11vmin 0 7vmin 0;
		}
		.TextButton{
			font-size: 3.9vmin;
		}
	}
}



