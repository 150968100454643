.NotifyMeButton{
	text-align: center;
	font-family: 'Raleway', sans-serif;
	border-radius: 100vmin;
	border: 0.4vmin solid #53cd7f;
	font-size: 1.2em;
	font-weight: 700;
	color: #53cd7f;
	background-color: rgba(0,0,0,0);
	transition: background-color 0.2s;
	text-transform: uppercase;
	letter-spacing: normal;
	position: relative;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}

.NotifyMeButton:focus {
	outline: none;
}

.NotifyMeButton:disabled, .NotifyMeButton[disabled] {
  background-color: rgba(100,100,100,0.8);
	color: white;
	border-color: #666;
	cursor: default;
}

.NotifyMeButton:hover:not([disabled]):not([selected]) {
	background-color: #53cd7f;
	color: white;
}

.NotifyMeButton .NotifyMeButton-container {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2vmin;
}

.NotifyMeButton .CheckmarkIcon {
	display: none;
	margin-left: 1.2vh;
}

.NotifyMeButton .CheckmarkIcon svg {
	color: #42c984;
}

.NotifyMeButton.selected .CheckmarkIcon {
	display: flex;
}

.NotifyMeButton span{
	color: white;
	font-weight: bold;
}

.NotifyMeButton.selected{
	background-color: rgba(100,100,100,0.5);
	border-color: #666;
}

.AssetDetails-carousel.index-3 .NotifyMeButton span, .AssetDetails-carousel.index-5 .NotifyMeButton span {
	color: #53cd7f;
}

.AssetDetails-carousel.index-3 .NotifyMeButton:hover span, .AssetDetails-carousel.index-5 .NotifyMeButton:hover span {
	color: white;
}

.AssetDetails-carousel.index-3 .NotifyMeButton.selected span, .AssetDetails-carousel.index-5 .NotifyMeButton.selected span {
	color: white;
}

@media only screen and (max-width:600px) {
	.NotifyMeButton:hover:not([disabled]).selected {
		background-color: rgba(100,100,100,0.5);
		color: white;
	}

	.NotifyMeButton:hover:not([disabled]).unselected {
		border: 0.4vmin solid #53cd7f;
	}
}