@import 'variables';

.GiftPopup {
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  img {
    width: 80px;
    height: 80px;
  }
  h2 {
    font-size: 2.4vh !important;
  }
  p {
    margin: 1.4vh 0 2.6vh !important;
    font-size: 2vh;
  }
  .GiftPopup-input-container {
    position: relative;
    width: 100%;
    padding-bottom: 2.8vh;
  }
  .GiftPopup-input {
    padding: 1vh;
    background-color: #e9e9e9;
    border: 1px solid #d3d3d3;
    outline: none;
    font-size: 2vh;
    text-transform: uppercase;
    &.error {
      border-color: $rally-red;
    }
    & ~ .Error {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      font-size: 1.4vh;
    }
  }
  .Button {
    padding: 2vh 6vh;
    font-size: 2vh;
    font-weight: 900;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  .DotsLoader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .TextButton {
    color: #989898;
    font-weight: normal;
    font-size: 1.8vh;
  }
}

.GiftPopup-success {
  margin: auto;
  h2 {
    margin-bottom: 2.2vh !important;
    color: $rally-primary;
  }
}