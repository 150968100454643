.SubscriptionAgreement {
  &__wrapper {
    width: 100%;
    height: 90%;
    padding-top: 40px;
    padding-bottom: 0px;
  }

  &__ctas {
    &-container {
      position: relative;
    }

    &-icon-span {
      padding-right: 10px;
    }

    &-scroller {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -62px;
      width: 100%;
      background: white;
      font-weight: bold;
      border-top: 1px dotted gray;
      border-bottom: 1px dotted gray;
    }

    &-img {
      height: 35px;
      margin-right: 10px;
    }

    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }
  }

  &__btn {
    margin-left: 40px;
  }
}

@media only screen and (max-width:600px) {
  .SubscriptionAgreement {
    &__wrapper {
      height: 97%;
    }

    &__ctas {
      &-scroller {
        top: -72px;
      }
    }

    &__loading {
      &-text {
        width: 80%;
      }
    }
  }
}