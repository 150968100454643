.MonetaryData .MonetaryData-section{
	margin: 3vh 2vh;
}

.MonetaryData .MonetaryData-section-header{
	display: inline-block;
	min-width: 22vmin;
	font-weight: 900;
	font-size: 1.7vh;
	padding-bottom: 2px;
	border-bottom: 1px solid #d5d5d5;
}

.MonetaryData .MonetaryData-section-content{
	margin: 1vh 0;
	font-size: 2vh;
}

.MonetaryData .MonetaryData-total{
	color: #41c84b;
	font-weight: 800;
	font-size: 5vh;
	line-height: 5vh;
	font-family: Truenoexbd, sans-serif;
}

.MonetaryData .MonetaryData-buttons .AdminButton{
	margin-left: 0;
}

.MonetaryData .MonetaryData-invested,
.MonetaryData .MonetaryData-cash,
.MonetaryData .MonetaryData-pending{
	font-family: Truenoexbd, sans-serif;
	font-weight: 600;
}

.MonetaryData .MonetaryData-invested,
.MonetaryData .MonetaryData-cash{
	color: #41c84b;
}


@media only screen and (max-width:600px) {
	.MonetaryData .MonetaryData-group{
		display: block;
		margin-top: 4vmin;
	}
}
