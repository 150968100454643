.SelectButton {
	height: 6.4vh;
	width: 18vmin;
	font-size: 2.8vh;
	line-height: 3vh;
	color: #888;
	border: 0.2vmin solid #BBB;
	text-transform: none;
	font-weight: 800;
	margin: 3vh 2vmin;
	padding: 0;
	border-radius: 0.4vmin;
}

.SelectButton:hover{
	color: #333;
	background-color: #EEE;
}

.SelectButton-selected{
	color: #333;
	border: 0.3vmin solid #333;
}

.SelectButton-selected.SelectButton-wanted{
	background-color: #41c84b;
}

.SelectButton-selected.SelectButton-unwanted{
	background-color: #dd2200;
}

.SelectButton-selected.SelectButton-wanted.dark,
.SelectButton-selected.SelectButton-unwanted.dark {
	color: white;
	border-color: black;
	background-color: black;
}

@media only screen and (max-width:600px) {
	.SelectButton {
		height: 7.2vh;
		width: 34vmin;
		font-size: 3.3vh;
		border-width: 0.4vmin;
		margin: 2vh 3vmin;
		border-radius: 0.8vmin;
	}

	.SelectButton-selected{
		border-width: 0.6vmin;
	}
}