
.AssetBanner {
	background-color: #44D39C;
	height: 9vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	position: absolute;
	width: 100%;
	bottom: 0;
	transition: height 0.5s;
}

.AssetBanner .AssetBanner-clearingprice,
.AssetBanner .AssetBanner-callout{
	text-transform: uppercase;
	color: white;
	font-weight: 900;
}

.AssetBanner .AssetBanner-clearingprice{
	margin-top: 0.8vh;
}

.AssetBanner .AssetBanner-callout{
	font-size: 2.6vmin;
}

.AssetBanner .AssetBanner-remaining{
	color: rgba(0, 0, 0, 0.8);
}

.AssetBanner.hidden{
	height: 0;
	overflow: hidden;
}

@media only screen and (max-width:750px) {
	.AssetBanner{
		height: 11vh;
	}
	.AssetBanner .AssetBanner-clearingprice{
		margin-top: 1.8vh;
	}
}

@media only screen and (max-width:600px) {
	.AssetBanner{
		height: 9vh;
		bottom: 8%;
	}
	.AssetBanner .AssetBanner-clearingprice {
		margin-top: 2px;
		font-size: 1.8vh;
	}
	.AssetBanner .AssetBanner-callout{
		font-size: 2vh;
	}
	.AssetBanner-remaining {
		font-size: 2vh;
	}
}